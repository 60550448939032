import styled, { css, keyframes } from 'styled-components';
import { FaQuestion, FaUndoAlt } from "react-icons/fa";
import { Heart } from 'lucide-react';
import { FaRankingStar } from "react-icons/fa6";
import { LuCross } from "react-icons/lu";
import { FaGrinStars } from "react-icons/fa";
import theme from '../../theme';
import { Clock, BookOpen, Briefcase, Globe, Calendar, Target } from 'lucide-react';

export const categoryColors = {
  'Charge de travail': theme.colors.violet,
  'Environnement académique': theme.colors.vert_fonce,
  'Immersion professionnelle': theme.colors.gris_clair,
  'International': theme.colors.orange,
  'Organisation': theme.colors.bleu_fonce,
  'Projets futurs': theme.colors.jaune_clair
};



// Updated category icons mapping
export const categoryIcons = {
  'Charge de travail': Clock,
  'Environnement académique': BookOpen,
  'Immersion professionnelle': Briefcase,
  'International': Globe,
  'Organisation': Calendar,
  'Projets futurs': Target
};

export const CategoryContainer = styled.div`
  position: absolute;
  top: ${theme.spacing.xxsmall};
  left: 50%;
  transform: translateX(-50%);
  /* Affichage inline-flex pour prendre la largeur de son contenu */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.small};

  /* Empêche le conteneur de provoquer des retours à la ligne */
  white-space: nowrap;
`;

export const CategoryText = styled.span`
  background-color: ${props => props.theme.colors.background};
  color: ${props => categoryColors[props.category] || props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.bold};
  padding: ${props => props.theme.spacing.xsmall} ${props => props.theme.spacing.small};
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid ${props => categoryColors[props.category] || props.theme.colors.primary};

  /* Force le texte à rester sur une seule ligne */
  white-space: nowrap;

  /* On peut également utiliser inline-block si désiré */
  display: inline-block;
`;


export const CategoryIcon = styled.div`
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.spacing.small};

  svg {
    width: 32px;
    height: 32px;
  }
`;


// Update QuestionCard to use the category color
export const QuestionCard = styled.div`
  background-color: ${props => categoryColors[props.category] || props.theme.colors.primary};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  width: 70%;
  height: calc(var(--vh, 1vh) * 45);
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
  transform-origin: center center;
  will-change: transform;
  margin-top: 15%;
  margin-left: 15%;

  ${props => !props.isMobile && `
    width: 50%;
    height: calc(var(--vh, 1vh) * 60);
    margin-top: 0;
    transform: none;
  `}
`;

// Update QuestionText to be white
export const QuestionText = styled.p`
  font-size: ${theme.fontSizes.xlarge};
  color: ${theme.colors.background};
  text-align: center;
  font-weight: ${theme.fontWeights.semiBold};
  z-index: 2;
  flex-grow: 1;
  max-height: 100%;
  margin: 0;
  max-width: 98%;
  margin-left: 0%;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
`;

export const QuizContainer = styled.div`
      display: flex;
      top: 5%;
      flex-direction: column;
      align-items: center;
      min-height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      padding: ${props => props.theme.spacing.large};
      padding-bottom: 30%;
      position: relative;
      overflow: hidden;
      z-index: 1600;

      ${props => !props.isMobile &&`
      `}
`;

export const BackgroundBall = styled.div`
  position: absolute;
  width: 150vw;
  height: calc(var(--vh, 1vh) * 200);
  border-radius: 35%;
  background-color: ${props => props.theme.colors.primary};
  top: calc(25% + (40% / 2));
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  ${props => !props.isMobile &&`
    position: absolute;
    width: 150vw;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    bottom: -40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  `}
`;


export const CardWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: calc(var(--vh, 1vh) * 20);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 1000px;
  height: calc(var(--vh, 1vh) * 60);
  z-index:11;
`;


export const QuizMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.large};
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 0 auto;
  position: absolute;
  top: calc(var(--vh, 1vh) * 20);
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.primary};
`;

const highlightPulse = keyframes`
  0% {
    background-color: #00c851; /* Vert clair */
    transform: scale(1.0);
  }
  50% {
    background-color: #007E33; /* Vert plus foncé */
    transform: scale(1.04);
  }
  100% {
    background-color: #00c851;
    transform: scale(1.0);
  }
`;

const finalSwipeAnimation = keyframes`
  0% {
    transform: scale(1);
    background-color: #00c851;
  }
  50% {
    transform: scale(1.2);
    background-color: #007E33;
  }
  100% {
    transform: scale(1);
    background-color: #00c851;
  }
`;

// Dans votre styled-component AnswerButton :
export const AnswerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.background};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 100%;
    height: 100%;
  }


     /* animation finale quand on relâche le doigt */
  ${props => props.finalSwiped && css`
    animation: ${finalSwipeAnimation} 0.6s ease-in-out;
  `}
  ${props => props.highlight && css`
    animation: ${highlightPulse} 1s ease-in-out infinite;
    background-color: #00c851;
    border: 2px solid #00c851;
  `}
`;

const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin-left: 0%;
  margin-top: 0%; /* Add space above the logo */

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add floating animation */
  animation: ${float} 6s ease-in-out infinite;

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
`;

export const QuizCompletionMessage = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: ${theme.spacing.large};
  border-radius: ${theme.spacing.medium};
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.large};
  font-weight: ${theme.fontWeights.semiBold};
  text-align: center;
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  margin-top: 30%; /* Reduced from 35% to create better spacing */
  margin-bottom: 10%; /* Add bottom margin to create space between bubble and logo */

  & span {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;

export const AnswerOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  top: calc(var(--vh, 1vh) * 77);
  z-index: 3;
  position: fixed;
  gap: 6%;

  ${props => !props.isMobile &&`
    position: absolute;
    margin-bottom: 5%;
    gap: 3%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  ${props => props.fill && `
    filter: drop-shadow(0 0 0 ${props.fill});
  `}
`;


const IconStyle = `
`;

export const StyledThumbsUp = styled(Heart)`
  ${IconStyle}
`;

export const StyledThumbsDown = styled(LuCross)`
  ${IconStyle}
  transform: rotate(45deg);
`;

export const StyledHelpCircle = styled(FaQuestion)`
  ${IconStyle}
`;

export const StyledHeart = styled(FaGrinStars)`
  ${IconStyle}
`;

export const ResultButton = styled(AnswerButton)`
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.primary};
  margin-top: calc(var(--vh, 1vh) * 10);

  &:hover {
    background-color: ${props => props.theme.colors.accent};
  }

  svg {
    margin-right: ${props => props.theme.spacing.small};
    color: ${props => props.theme.colors.background};
  }
`;

export const StyledRanking = styled(FaRankingStar)`
  color: ${props => props.theme.colors.background};
`;

export const QuizTypeIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8%;
  top: calc(var(--vh, 1vh) * 8);
  background-color: none;
  box-shadow: none;
  z-index: 1000;
`;

export const ProgressContainer = styled.div`
  max-width: 75%;
  margin: 0 auto;
  position: relative;
  top: calc(var(--vh, 1vh) * 5);
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 24px;
  background-color: rgba(230, 230, 230, 0.9);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

export const Progress = styled.div`
  height: 100%;
  border-radius: 12px;
  background-color: ${props => props.$isQuiz1 ? '#4CAF50' : '#2196F3'};
  width: ${props => props.$progress}%;
  transition: width 0.3s ease;
`;

export const QuizNumber = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-weight: bold;
  z-index: 2;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

export const ContinueModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const ModalTitle = styled.h2`
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

export const ModalText = styled.p`
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const ModalButton = styled.button`
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;

  &.continue {
    background-color: #4CAF50;
    color: white;

    &:hover {
      background-color: #45a049;
    }
  }

  &.results {
    background-color: #2196F3;
    color: white;

    &:hover {
      background-color: #1e88e5;
    }
  }
`;

export const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 55);
  z-index: 10;
`;

export const BrandImage = styled.img`
  width: 20%;
  height: auto;
  object-fit: contain;
`;


export const BrandSubtitle = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.primary};
  margin: 0;
  font-family: "Sour Gummy", sans-serif;
  margin-bottom: calc(var(--vh, 1vh) * 27);
  text-align: center;
`;

export const BrandTitle = styled.h1`
  font-size: ${props => props.fontSize || "32px"};
  color: ${props => props.fontColor || "#FF2768"};
  font-family: "Londrina Solid", sans-serif;
  font-weight: ${props => props.fontWeight || "400"};
  letter-spacing: ${props => props.letterSpacing || "normal"};
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  text-shadow: ${props => props.textShadow || "none"};
`;

// First add these new styled components to your styles.js
export const TutorialCardWrapper = styled(CardWrapper)`
  ${props => props.$isTutorialActive && css`
    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      border-radius: 30px;
      background: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        transparent 70%
      );
      z-index: -1;
    }
  `}

  ${props => props.$isSwipeStep && css`
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.1) 100%
      );
      animation: swipeHint 2s infinite;
    }

    @keyframes swipeHint {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
  `}
`;

export const TutorialQuestionCard = styled(QuestionCard)`
  ${props => props.$isTutorialActive && css`
    border: 2px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0 20px rgba(255, 39, 104, 0.2);
  `}
`;

export const TutorialAnswerButton = styled(AnswerButton)`
  ${props => props.$isHighlighted && css`
    opacity: 1;
    transform: scale(1.1);
    transition: all 0.3s ease;
    animation: pulse 1.5s infinite;

    @keyframes pulse {
      0% { transform: scale(1.1); }
      50% { transform: scale(1.2); }
      100% { transform: scale(1.1); }
    }
  `}

  ${props => !props.$isHighlighted && css`
    opacity: 0.5;
  `}
`;

export const TutorialText = styled(QuestionText)`
  font-size: ${props => props.theme.fontSizes.large};
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const SwipeInstruction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.text};
  margin: 5px 0;
`;

export const DirectionIcon = styled.span`
  font-size: 24px;
  color: ${theme.colors.white};
`;


export const DirectionArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  font-size: 2rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  animation: ${props => props.pulse} 2s infinite;
  pointer-events: none;
  z-index: 10;
`;

export const pulseUp = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(-5px) translateX(-50%); opacity: 1; }
`;

export const pulseDown = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(5px) translateX(-50%); opacity: 1; }
`;

export const pulseLeft = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(-5px); opacity: 1; }
`;

export const pulseRight = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(5px); opacity: 1; }
`;

export const ArrowUp = styled(DirectionArrow)`
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseUp} 2s infinite;
`;

export const ArrowDown = styled(DirectionArrow)`
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseDown} 2s infinite;
  color: ${props => props.theme.colors.white};
`;

export const ArrowLeft = styled(DirectionArrow)`
  left: -20%;
  top: 40%;
  transform: translateY(-50%);
  animation: ${pulseLeft} 2s infinite;
`;

export const ArrowRight = styled(DirectionArrow)`
  right: -20%;
  top: 40%;
  transform: translateY(-50%);
  animation: ${pulseRight} 2s infinite;
`;

export const BackButton = styled.button`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.large};
  left: ${({ theme }) => theme.spacing.large};
  background: ${({ theme }) => theme.colors.bleu_fonce};
  border: none;
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing.xxxlarge};
  height: ${({ theme }) => theme.spacing.xxxlarge};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(56, 182, 255, 0.25);
  font-family: ${({ theme }) => theme.fonts.main};

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(255, 39, 104, 0.35);
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0;
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
    outline-offset: 2px;
  }
`;