import React from 'react';
import styled from 'styled-components';
import { MdOutlineShare } from 'react-icons/md';


// If you used domainIconMap in a separate file, import from there:
import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import agroalimentaire_icon from '../../assets/agroalimentaire_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/nsi_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';

import headerImage from '../../assets/meo_compass.png';
import coeurBlanc from '../../assets/coeur_blanc.png';
import durationIcon from '../../assets/duree.png';
import priceIcon from '../../assets/prix.png';
import statutIcon from '../../assets/statut.png';
import plusIcon from '../../assets/plus_icon.png';
import checkIcon from '../../assets/true_icon.png';
import exclamationIcon from '../../assets/attention_icon.png';

import appIcon from '../../assets/app_icone.png';
import playStoreIcon from '../../assets/play_store_icon.png';
import appleStoreIcon from '../../assets/apple_store_icon.png';
import storeIcon from '../../assets/apple_and_play_store_icons.png';

// Map each domain label to its icon
const domainIconMap = {
  'Arts du Spectacle': arts_spectacle_icon,
  'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
  'Communication Visuelle - Dessin - Animation 3D': dessin_icon,
  'Design': design_specific_icon,
  "Marché de l'Art - Culture - Patrimoine": culture_patimoine_icon,
  'Mode - Textile': mode_textile_icon,
  'Luxe': luxe_specific_icon,
  'Artisanat': artisanat_specific_icon,

  'Sport - Management Sportif': sport_management_icon,
  'Enseignement': teaching_specific_icon,
  'Santé (Médical-Paramédical)': health_medical_icon,
  'Travailler avec des Enfants': working_with_children_icon,
  'ESport - Gaming': esport_specific_icon,
  'Tourisme - Loisirs': tourisme_specific_icon,

  'Droit - Justice - Sciences Politiques': droit_justice_icon,
  'Fonction Publique': fonction_publique_specific_icon,
  'Humanitaire': humanitaire_specific_icon,
  'Journalisme': journalisme_specific_icon,
  'Communication': communication_specific_icon,
  'Social': social_specific_icon,
  'Sécurité - Armée - Défense': security_army_icon,
  "Entrepreneuriat - Création d'Entreprise": entrepreneurship_icon,

  "Édition - Métiers du Livre": book_industry_icon,
  'Psychologie - Sociologie': psychology_sociology_icon,
  'Langues Étrangères - Traduction': langues_translation_icon,
  'Commerce - Distribution - Management': commerce_management_icon,
  'Hôtellerie - Restauration': hospitality_icon,
  'Transport - Logistique': transport_icon,

  'Mathématiques - Sciences Physiques': math_physics_icon,
  'Finance': finance_specific_icon,
  'Banque - Assurance': banque_assurance_icon,
  'Comptabilité - Gestion - Audit': accounting_audit_icon,
  'Informatique - Numérique': informatics_icon,
  'Cybersécurité': cybersecurity_specific_icon,
  'Le Nucléaire': nuclear_specific_icon,
  'Internet - Web - Multimédia': internet_web_icon,
  'Jeu Vidéo': gaming_specific_icon,
  'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,

  'Chimie - Biologie': chemistry_biology_icon,
  'Énergie': energy_specific_icon,
  'Industrie - Qualité': industry_quality_icon,
  'Environnement - Développement Durable': environment_icon,
  'Métiers Animaliers': animals_specific_icon,
  'Agriculture': agriculture_specific_icon,
  'Agroalimentaire': agroalimentaire_icon,
  'Métiers de la Mer': marine_specific_icon,
  'Métiers du Vin': wine_industry_icon,

  'Architecture - Urbanisme - Paysagisme': architecture_icon,
  'Automobile - Moto': automobile_icon,
  'Bâtiment et Travaux Publics (BTP)': btp_icon,
  'Électronique': electronics_icon,
  'Aéronautique et Aérospatial': aeronautics_icon,

  'Ressources Humaines': resources_human_icon,
  'Publicité - Marketing': advertising_icon,
  'Immobilier': real_estate_icon,
  'Métiers de Bouche': culinary_specific_icon,
};
// --------------- A simple styled button (white with minimal style) --------------
export const ShareButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: 5%;
  margin-right: 10%;
  
  /* Make the icon white and sized appropriately */
  svg {
    color: #ffffff;
    width: 24px;
    height: 24px;
  }
`;



// -------- Utility for price formatting (same as your original function) ---------
const formatPrice = (prixInterval) => {
  if (!prixInterval || typeof prixInterval !== 'string') {
    return 'Prix inconnu';
  }
  const parts = prixInterval.split('-');

  // Single price
  if (parts.length === 1) {
    const price = parseFloat(parts[0]);
    if (isNaN(price)) return 'Prix inconnu';
    return `${price.toLocaleString('fr-FR')}€`;
  }

  // Interval
  if (parts.length === 2) {
    const [lower, upper] = parts;
    const lowerNum = parseFloat(lower);
    const upperNum = parseFloat(upper);
    if (isNaN(lowerNum) || isNaN(upperNum)) {
      return 'Prix inconnu';
    }
    return `${lowerNum.toLocaleString('fr-FR')}€ - ${upperNum.toLocaleString('fr-FR')}€`;
  }

  // Invalid
  return 'Prix inconnu';
};

// ----------- Helper to load images for the share canvas (same as your original) --
const loadImageAsync = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = src;
  });
};

// ----------- Helper to truncate text for the canvas if needed -------------------
const truncateText = (text, maxWidth, ctx, font) => {
  ctx.font = font;
  let width = ctx.measureText(text).width;
  if (width <= maxWidth) return text;

  while (width > maxWidth && text.length > 0) {
    text = text.slice(0, -1);
    width = ctx.measureText(text + '...').width;
  }
  return text + '...';
};

// -------------- The main function to generate the share image (canvas) -----------
// -------------- The main function to generate the share image (canvas) -----------

// -------------- The main function to generate the share image (canvas) -----------
// -------------- The main function to generate the share image (canvas) -----------
// -------------------------------------------
// generateShareImage (entire function)
// -------------------------------------------
const generateShareImage = async (formationData) => {
    const baseWidth = 500;
    const baseHeight = 1000;
  
    // Scale factor
    const scaleFactor = 1;
  
    // Final canvas size
    const canvasWidth = baseWidth * scaleFactor;
    const canvasHeight = baseHeight * scaleFactor;
  
    // Crispness on high-density screens
    const devicePixelRatio = window.devicePixelRatio || 1;
    const totalWidth = canvasWidth * devicePixelRatio;
    const totalHeight = canvasHeight * devicePixelRatio;
  
    // Create & scale the canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = totalWidth;
    canvas.height = totalHeight;
    ctx.scale(devicePixelRatio * scaleFactor, devicePixelRatio * scaleFactor);
  
    // Fill background white
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, baseWidth, baseHeight);
  
    // Helper for roundRect
    function roundRect(ctx, x, y, w, h, r) {
      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.lineTo(x + w - r, y);
      ctx.quadraticCurveTo(x + w, y, x + w, y + r);
      ctx.lineTo(x + w, y + h - r);
      ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
      ctx.lineTo(x + r, y + h);
      ctx.quadraticCurveTo(x, y + h, x, y + h - r);
      ctx.lineTo(x, y + r);
      ctx.quadraticCurveTo(x, y, x + r, y);
      ctx.closePath();
    }
  
    // ------------------------------------------------------------
    // 1) More space above the card & higher compass
    // ------------------------------------------------------------
    // We'll place text lines a bit lower, and shift the compass up more
    ctx.fillStyle = '#000';
    ctx.font = '400 20px Gabarito';
    const textLine1 = 'Meo te guide gratuitement dans la jungle';
    const textLine2 = 'de l’orientation post-bac !';
  
    // Slightly lower text
    ctx.fillText(textLine1, 20, 30);
    ctx.fillText(textLine2, 20, 55);
  
    try {
      const compassImg = await loadImageAsync(headerImage);
      // Shift compass even higher: x ~ (baseWidth - 140), y = -30, bigger ~ 130×130
      ctx.drawImage(compassImg, baseWidth - 140, -10, 130, 130);
    } catch (error) {
      console.error('Error loading compass image:', error);
    }
  
    // Start below the header
    let currentY = 100; // More space from the top
  
    // ------------------------------------------------------------
    // 2) Gradient container for "Type de formation"
    // ------------------------------------------------------------
    const containerMargin = 0.025 * baseWidth; 
    const containerWidth = 0.95 * baseWidth;
    const cornerRadius = 15;
    const containerX = containerMargin;
    const containerY = currentY;
    const containerHeight = 120;
  
    // Create gradient
    const gradient = ctx.createLinearGradient(containerX, 0, containerX + containerWidth, 0);
    gradient.addColorStop(0, '#FC7D16');
    gradient.addColorStop(1, '#FF2768');
  
    // Draw the rounded rectangle
    roundRect(ctx, containerX, containerY, containerWidth, containerHeight, cornerRadius);
    ctx.fillStyle = gradient;
    ctx.fill();
  
    // "Type de formation" smaller
    ctx.fillStyle = '#fff';
    ctx.font = '500 20px Gabarito';
    ctx.fillText('Type de formation', containerX + 20, containerY + 40);
  
    // Heart icon
    try {
      const coeurImg = await loadImageAsync(coeurBlanc);
      ctx.drawImage(coeurImg, containerX + containerWidth - 50, containerY + 15, 40, 40);
    } catch (error) {
      console.error('Error loading heart image:', error);
    }
  
    // Formation name bigger
    const formationName = formationData?.formation_info?.unique_type || 'Nom inconnu';
    ctx.font = '500 24px Gabarito';
    ctx.fillText(
      truncateText(formationName, containerWidth - 60, ctx, '500 24px Gabarito'),
      containerX + 20,
      containerY + 70
    );
  
    // Score
    const score = formationData?.total_score
      ? `${Math.round(formationData.total_score)}%`
      : '0%';
    ctx.font = '500 28px Gabarito';
    ctx.fillText('Match à', containerX + containerWidth - 160, containerY + 40);
  
    ctx.font = '400 50px Gabarito';
    ctx.fillText(score, containerX + containerWidth - 145, containerY + 85);
  
    currentY += containerHeight + 30;
  
    // ------------------------------------------------------------
    // 3) Info row (durée / prix / statut)
    // ------------------------------------------------------------
    const drawInfoRow = async () => {
      const icons = [durationIcon, priceIcon, statutIcon];
      const labels = [
        `${formationData?.formation_info?.duration || 'N/A'} ans`,
        formatPrice(formationData?.formation_info?.pricing?.normal || 'N/A'),
        formationData?.formation_info?.status || 'N/A',
      ];
  
      let xPos = 60;
      for (let i = 0; i < 3; i++) {
        try {
          const img = await loadImageAsync(icons[i]);
          ctx.drawImage(img, xPos, currentY, 30, 30);
  
          // Center text
          ctx.save();
          ctx.textAlign = 'center';
          ctx.font = '400 16px Gabarito';
          ctx.fillStyle = '#000';
          ctx.fillText(labels[i], xPos + 15, currentY + 45);
          ctx.restore();
  
          xPos += 140;
        } catch (error) {
          console.error(`Error loading info icon:`, error);
        }
      }
    };
    await drawInfoRow();
    currentY += 70;
  
    // ------------------------------------------------------------
    // 4) Domain Container: more spacing before domain items & "Poids..." further right
    // ------------------------------------------------------------
    const containerBgColor = '#F7F3F0';
  
    // Get the domains we want to show
    const domains = formationData?.matched_domains?.main || [];
    const displayedDomains = domains.slice(0, 3);
  
    // We'll create more vertical spacing between the title and the domain items
    // e.g. domainTitleHeight = 80 (was 60)
    const domainTitleHeight = 80; 
    const domainItemHeight = 40;
    const domainBottomPadding = 20;
    const domainContainerHeight =
      domainTitleHeight + displayedDomains.length * domainItemHeight + domainBottomPadding;
  
    const domainContainerX = containerMargin;
    const domainContainerY = currentY;
    const domainContainerWidth = containerWidth;
  
    roundRect(
      ctx,
      domainContainerX,
      domainContainerY,
      domainContainerWidth,
      domainContainerHeight,
      25
    );
    ctx.fillStyle = containerBgColor;
    ctx.fill();
  
    // Titles
    ctx.textAlign = 'left';
    ctx.fillStyle = '#000';
    ctx.font = '500 18px Gabarito';
    const leftTitle = 'Tes domaines qui matchent';
    const domainTitleY = domainContainerY + 30;
    ctx.fillText(leftTitle, domainContainerX + 20, domainTitleY);
  
    // "Poids dans la..." two lines, placed ~80% across
    const rightTextX = domainContainerX + domainContainerWidth * 0.80;
    ctx.textAlign = 'center';
    ctx.fillText('Poids dans la', rightTextX, domainTitleY);
    ctx.fillText('formation',    rightTextX, domainTitleY + 25);
  
    // Reset align
    ctx.textAlign = 'left';
  
    // Domain items start after title height (80)
    let domainItemY = domainContainerY + domainTitleHeight; 
    for (const domain of displayedDomains) {
      try {
        if (domain && domainIconMap[domain]) {
          const domImg = await loadImageAsync(domainIconMap[domain]);
          ctx.drawImage(domImg, domainContainerX + 20, domainItemY - 10, 35, 35);
        }
      } catch (err) {
        console.error(`Error loading icon for domain ${domain}`, err);
      }
      ctx.fillStyle = '#000';
      ctx.font = '400 16px Gabarito';
      ctx.fillText(domain, domainContainerX + 65, domainItemY + 12);
  
      // 2 plus icons near the "Poids..." text
      try {
        const plusImg = await loadImageAsync(plusIcon);
        ctx.drawImage(plusImg, rightTextX - 10 - 8, domainItemY - 5, 16, 16);
        ctx.drawImage(plusImg, rightTextX + 10,    domainItemY - 5, 16, 16);
      } catch (error) {
        console.error('Error loading plus icon:', error);
      }
  
      domainItemY += domainItemHeight;
    }
  
    currentY += domainContainerHeight + 20;
  
    // ------------------------------------------------------------
    // 5) "Pourquoi cette formation TE correspond" container
    // ------------------------------------------------------------
    {
      const positives = formationData?.positive_questions?.slice(0, 3) || [];
      const negatives = formationData?.negative_questions?.slice(0, 3) || [];
  
      const bulletSpacing = 30;
      const groupSpacing = positives.length > 0 && negatives.length > 0 ? 20 : 0;
      const totalBulletHeight =
        positives.length * bulletSpacing + groupSpacing + negatives.length * bulletSpacing;
  
      // 60 top (title) + 20 bottom
      const dynamicWhyHeight = 60 + totalBulletHeight + 20;
  
      const whyContainerX = containerMargin;
      const whyContainerWidth = containerWidth;
      const whyContainerY = currentY;
  
      roundRect(ctx, whyContainerX, whyContainerY, whyContainerWidth, dynamicWhyHeight, 25);
      ctx.fillStyle = containerBgColor;
      ctx.fill();
  
      // Title
      ctx.fillStyle = '#000';
      ctx.font = '500 18px Gabarito';
      ctx.fillText(
        'Pourquoi cette formation TE correspond',
        whyContainerX + 20,
        whyContainerY + 30
      );
  
      let bulletY = whyContainerY + 60;
  
      const drawBullets = async (arr, icon, startY, color) => {
        let yPos = startY;
        for (const item of arr) {
          try {
            const bulletImg = await loadImageAsync(icon);
            ctx.drawImage(bulletImg, whyContainerX + 20, yPos, 16, 16);
          } catch (error) {
            console.error('Error loading bullet icon:', error);
          }
          ctx.fillStyle = color;
          ctx.font = '400 14px Gabarito';
          ctx.fillText(
            truncateText(item.mot, whyContainerWidth - 60, ctx, '400 14px Gabarito'),
            whyContainerX + 50,
            yPos + 14
          );
          yPos += 30;
        }
        return yPos;
      };
  
      // Positives
      bulletY = await drawBullets(positives, checkIcon, bulletY, '#000');
      if (positives.length > 0 && negatives.length > 0) {
        bulletY += 20;
      }
      // Negatives
      bulletY = await drawBullets(negatives, exclamationIcon, bulletY, '#000');
  
      currentY += dynamicWhyHeight + 15;
    }
  
    // ------------------------------------------------------------
    // 6) "Télécharge Meoria !" + bigger icons (25% bigger => 200x200)
    // ------------------------------------------------------------
    ctx.fillStyle = '#000';
    ctx.font = '700 36px Gabarito';
    ctx.textAlign = 'center';
    ctx.fillText('Télécharge Meoria !', baseWidth / 2, currentY + 30);
  
    // Reset alignment
    ctx.textAlign = 'left';
  
    const iconY = currentY + 50; 
    // We keep left & right center
    const leftCenterX = 0.25 * baseWidth;  // 125
    const rightCenterX = 0.75 * baseWidth; // 375
  
    // Both icons are now 200×200
    const iconSize = 180;
  
    // 1) App icon on the left
    const appIconX = leftCenterX - iconSize / 2; 
    try {
      const appImg = await loadImageAsync(appIcon);
      ctx.drawImage(appImg, appIconX, iconY, iconSize, iconSize);
    } catch (error) {
      console.error('Error loading app icon:', error);
    }
  
    // 2) Single store icon on the right, same size
    try {
      const storeImg = await loadImageAsync(storeIcon); // combined store icon
      const storeIconX = rightCenterX - iconSize / 2;
      ctx.drawImage(storeImg, storeIconX, iconY, iconSize, iconSize);
    } catch (error) {
      console.error('Error loading combined store icon:', error);
    }
  
    currentY += iconSize; // move down after them
  
    // ------------------------------------------------------------
    // 7) Return final dataURL
    // ------------------------------------------------------------
    ctx.textAlign = 'left';
    return canvas.toDataURL('image/png');
  };

  
  

// -------------- The actual component: only a white Share button ---------------
const ShareButtonTypeFormation = ({ formationData }) => {
  const handleShare = async () => {
    try {
      // Generate the share image
      const dataUrl = await generateShareImage(formationData);

      // Convert data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const fileToShare = new File([blob], 'formation-match.png', { type: 'image/png' });

      // Attempt native share if supported
      if (
        navigator.share &&
        navigator.canShare &&
        navigator.canShare({ files: [fileToShare] })
      ) {
        await navigator.share({
          title: 'Mon Match de Formation',
          text: `Découvrez ma formation idéale : ${formationData?.formation_info?.unique_type}
Trouve la tienne sur meoria.com`,
          files: [fileToShare],
        });
        return;
      }

      // Fallback: text-only share
      const textContent = `🎯 Mon Match de Formation : ${formationData?.formation_info?.unique_type}
➡️ Match à ${Math.round(formationData?.total_score || 0)}%
Retrouve d'autres infos sur meoria.com`;

      if (navigator.share) {
        // Some browsers only support text sharing, not files
        await navigator.share({
          title: 'Mon Match de Formation',
          text: textContent,
        });
      } else {
        // Final fallback: automatically download the image
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'formation-match.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Sharing error:', error);
      // If everything fails, fallback to copying text
      try {
        await navigator.clipboard.writeText(
          `Découvrez ma formation idéale : ${formationData?.formation_info?.unique_type}\n` +
          `Match à ${Math.round(formationData?.total_score || 0)}%\n` +
          `Plus d'infos sur meoria.com`
        );
        alert('Le partage a échoué - Lien copié dans le presse-papiers !');
      } catch (clipboardError) {
        alert('Échec du partage. Veuillez réessayer plus tard.');
      }
    }
  };

  // Render only a white share button (with a share icon)
  return (
    <ShareButton onClick={handleShare}>
      <MdOutlineShare size={20} />
    </ShareButton>
  );
};

export default ShareButtonTypeFormation;
