import styled, { keyframes, css } from 'styled-components';
import theme from '../../theme'; 

// --------------------------------------
// Container with scrollable content
// --------------------------------------
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 1603;

  /* Push content below the fixed header (168px) */
  padding-top: 168px;

  background-color: ${({ theme }) => theme.colors.background || '#f5f5f5'};
  font-family: 'Gabarito', sans-serif;
`;

/* ----------------- FIXED HEADER (GRADIENT SVG) ----------------- */
export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: ${theme.colors.background};

  /* (1) Increase z-index to 1603 (based on your request). */
  z-index: 1603;
`;

export const HeaderSvg = styled.svg`
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 10rem;
`;

export const HeaderContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 379px;
  height: 168px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem; /* reduced a bit for less space */
`;

/* ----------------- HEADER ROWS ----------------- */

/** Top row: Return button (left) + icons (right) */
export const HeaderTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/** Bottom row: formation name (left) + percentage (right) */
export const HeaderBottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

/* ----------------- RETURN BUTTON ----------------- */
export const ReturnButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  /* Keep or adjust if you want the button above the header. */
  z-index: 1604;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);
    svg {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

/* ----------------- ICONS CONTAINER (TOP RIGHT) ----------------- */
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row; /* side by side */
  gap: 0.2rem;
  margin-right: -1%;
`;

export const ShareIcon = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;


/* ----------------- NAME & PERCENTAGE ----------------- */
export const FormationName = styled.h1`
  margin: 0;
  color: #fff;
  font-size: ${theme.fontSizes.xlarge};
  font-weight: 500;
  font-family: 'Gabarito', sans-serif;
  text-align: left;
  width: 60%;
  margin-left: 5%;
  margin-top: -10%;

  display: -webkit-box;
  -webkit-line-clamp: 4;      /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;



export const ScoreContainer = styled.div`
  width: 86px;
  height: 74px;
  flex-shrink: 0;
  border-radius: 35px;
  border: 0px solid #000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
  margin-top: -10%;
`;

/*
  "Match à" text inside the circle
*/
export const ScoreTopText = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.large};
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-bottom: -5px;
`;

/*
  Percentage text inside the circle, bigger and bolder
*/
export const ScoreValue = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.xxxlarge};
  font-weight: 800;
  line-height: 1;
`;



/* ----------------- INFO WRAPPER ----------------- */
export const InfoWrapper = styled.div`
  width: 100%;
  max-width: 379px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.5rem 0;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
`;

export const InfoIcon = styled.div`
  width: 31px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const InfoText = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: 400;
`;

/* ----------------- DOMAINS MATCHING ----------------- */
export const DomainContainer = styled.div`
  width: 95%;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  border-radius: 25px;
  background: #F7F3F0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
`;


export const DomainItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DomainIcon = styled.div`
  width: 35px;
  height: 35px;
  background: none;
  border-radius: 4px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DomainText = styled.div`
  font-size: ${theme.fontSizes.small};
  color: #000;
  font-weight: 400;
`;

/* ----------------- CONTENT SECTIONS ----------------- */
export const Section = styled.div`
  width: 95%;
  max-width: 95%;
  margin: 0.5rem auto;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  text-align: left;
`;

export const SectionTitle = styled.h2`
  color: #000;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
  margin: 0.5rem 0;
  text-align: left;
`;

export const SectionText = styled.p`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  font-family: 'Gabarito', sans-serif;
  margin: 0.25rem 0 0.5rem;
  text-align: left;
`;

export const SubsectionTitle = styled.h3`
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-top: 0.3rem;
  margin-bottom: 0.25rem;
  text-align: left;
  font-family: 'Gabarito', sans-serif;s
`;

export const PlusMinusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  min-height: 40px; // Ensure container has some height even when empty
`;

// In styles.js
export const VoirPlusLink = styled.div`
  width: 261px;
  height: 25px;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'Geologica', sans-serif;
  font-size: font-size: ${theme.fontSizes.small};
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
  align-self: flex-end;

`;

export const PlusMinusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const PlusMinusIcon = styled.div`
  color: ${({ positive }) => (positive ? 'green' : 'red')};
  font-size: 20px;
  flex-shrink: 0;
`;

export const PlusMinusText = styled.span`
  font-size: 14px;
  color: #000;
  font-family: 'Gabarito', sans-serif;
`;

// Add these to your styles.js file
export const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ImportanceSubtitle = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
  margin: 0.5rem 0;
  text-align: center;
  width: 30%;
`;

export const DomainItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
`;

export const LeftDomainContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  max-width: 70%;
`;

export const RightIconsContainer = styled.div`
  display: flex;
  justify-content: center;  // Center horizontally
  align-items: center;      // Center vertically
  gap: 0.25rem;
  width: 30%;               // Match the remaining width
  height: 100%;             // Take full height of parent
`;

export const Subtitle = styled.span`
  font-family: 'Gabarito', sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-left: -10%;
`;


export const ImportanceIcons = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${theme.colors.primary};
`;

export const SubSubsectionTitle = styled.h4`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin: 1rem 0 0.25rem;
  text-align: left;
  font-family: 'Gabarito', sans-serif;
`;

/* A list of links for “proche de chez toi” and “profil scolaire” */
export const LinksList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0.25rem 0 0.5rem;

  li {
    color: #ff6b98;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;


export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin: 0.5rem auto 0;
  padding: 0 1rem;
`;

export const TabButton = styled.button`
  flex: 1;
  position: relative;
  padding: 8px 0;
  max-width: 200px;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.small};
  font-weight: 400;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: ${({ active }) => (active ? '#FFF' : '#000')};
  transition: color 0.3s ease;
  text-align: center;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(${({ active }) => (active ? 1 : 0)});
    width: 80%;
    height: 80%;
    background: #000;
    border-radius: 25px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
                opacity 0.2s ease;
    opacity: ${({ active }) => (active ? 1 : 0)};
    z-index: -1;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const GrayBandWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;


const bounce = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
`;

const pop = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3) rotate(15deg); }
  100% { transform: scale(1) rotate(0); }
`;


const floatHeartAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -200px) scale(0);
    opacity: 0;
  }
`;

export const FloatingHeart = styled.div`
  position: absolute;
  pointer-events: none;
  animation: ${floatHeartAnimation} 2s ease-out forwards;
  color: #fff; // Force white color
  font-size: 24px;
  z-index: 2000;
`;

export const LikeIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 4%;
  margin-right: 5%;
  color: ${props => props.$isFavorite ? '#FFF' : 'rgba(255,255,255, 1)'};
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    color: #FFF;
  }

  animation: ${props => props.$animate 
    ? css`${bounce} 0.5s ease` 
    : 'none'};
`;

export const GrayBand = styled.div`
  width: 160%;
  height: 23px;
  border-radius: 25px;
  background: #f2f2f2;
  margin: 1rem 0;
  position: relative;
  left: -30%;
  box-sizing: border-box;

  /* Prevent horizontal scroll */
  @media (max-width: 768px) {
    width: 200%;
    left: -50%;
  }
`;