import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import { PrimaryButton } from '../SecundPage/styles';
import {
  GradeContainer,
  SliderContainer,
  SliderLabel,
  CustomSlider,
  LoadingOverlay,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  GradeValue
} from './styles';

import compassImage from '../../assets/meo_write.png';
import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';

const GeneralMoyenne = () => {
  const navigate = useNavigate();
  const [grades, setGrades] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const [typeBac, setTypeBac] = useState('');
  const { isMobile } = useDeviceType();
  const [specialities, setSpecialities] = useState({ selected: [], doubleClicked: [] });

  const getMessage = (type) => {
    switch (type) {
      case 'terminale':
      case 'premiere':
        return "Indique tes moyennes pour que je comprenne au mieux ton profil !";
      case 'seconde':
        return "Indique ta moyenne générale pour que je comprenne au mieux ton profil !";
      default:
        return "";
    }
  };

  useEffect(() => {
    const storedTypeBac = localStorage.getItem('type_utilisateur');
    const storedSpecialities = JSON.parse(localStorage.getItem('specialites') || '{"selected":[],"doubleClicked":[]}');
    
    setTypeBac(storedTypeBac);
    setSpecialities(storedSpecialities);
    
    const fullText = getMessage(storedTypeBac);
    let currentIndex = 0;
    
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handleSliderChange = (subject, value) => {
    setGrades(prev => ({
      ...prev,
      [subject]: value
    }));
  };

  const isSubmitEnabled = () => {
    if (typeBac === 'seconde') {
      return grades.general !== undefined;
    } else if (typeBac === 'premiere') {
      return specialities.selected.every(subject => grades[subject] !== undefined);
    } else if (typeBac === 'terminale') {
      const uniqueSubject = specialities.selected.find(s => !specialities.doubleClicked.includes(s));
      return specialities.doubleClicked.every(subject => grades[subject] !== undefined) &&
             grades[uniqueSubject] !== undefined;
    }
    return false;
  };

  const specialityMap = {
  'arts': 'Arts',
  'eppcs': 'Éducation physique, pratiques et cultures sportives',
  'hggsp': 'Histoire-géographie, géopolitique et sciences politiques',
  'hlp': 'Humanités, littérature et philosophie',
  'llcer': 'Langues, littératures et cultures étrangères et régionales',
  'lca': "Littérature, langues et cultures de l'antiquité",
  'math': 'Mathématiques',
  'nsi': 'Numérique et sciences informatiques',
  'physchim': 'Physique-Chimie',
  'svt': 'Sciences de la vie et de la Terre',
  'si': "Sciences de l'ingénieur",
  'ses': 'Sciences économiques et sociales',
  'bioeco': 'Biologie-Ecologie'
};

  const handleSubmit = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setIsLoading(true);
    try {
      localStorage.setItem('grades', JSON.stringify(grades));
      navigate('/subdomaine-formation'); // Replace with your next route
    } catch (error) {
      console.error('Error saving grades to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const sliders = document.querySelectorAll('input[type="range"]');
    sliders.forEach(slider => {
      slider.style.setProperty('--progress', `${(slider.value / slider.max) * 100}%`);
      
      slider.addEventListener('input', (e) => {
        e.target.style.setProperty('--progress', `${(e.target.value / e.target.max) * 100}%`);
      });
    });
  }, [grades]);

  const renderSliders = () => {
    const sliders = [];
    
    // Add general average slider for all types
    sliders.push(
      <SliderContainer key="general">
        <SliderLabel>Moyenne générale</SliderLabel>
        <CustomSlider
          type="range"
          min={0}
          max={20}
          step={0.5}
          value={grades.general || 0}
          onChange={(e) => handleSliderChange('general', parseFloat(e.target.value))}
        />
        <GradeValue>{grades.general || 0}</GradeValue>
      </SliderContainer>
    );
  
    // Add subject-specific sliders based on type
    if (typeBac === 'premiere') {
      specialities.selected.forEach((subject) => {
        sliders.push(
          <SliderContainer key={subject}>
            <SliderLabel>{specialityMap[subject]}</SliderLabel>
            <CustomSlider
              type="range"
              min={0}
              max={20}
              step={0.5}
              value={grades[subject] || 0}
              onChange={(e) => handleSliderChange(subject, parseFloat(e.target.value))}
            />
            <GradeValue>{grades[subject] || 0}</GradeValue>
          </SliderContainer>
        );
      });
    } else if (typeBac === 'terminale') {
      const uniqueSubject = specialities.selected.find(s => !specialities.doubleClicked.includes(s));
      sliders.push(
        <SliderContainer key={uniqueSubject}>
          <SliderLabel>{specialityMap[uniqueSubject]}</SliderLabel>
          <CustomSlider
            type="range"
            min={0}
            max={20}
            step={0.5}
            value={grades[uniqueSubject] || 0}
            onChange={(e) => handleSliderChange(uniqueSubject, parseFloat(e.target.value))}
          />
          <GradeValue>{grades[uniqueSubject] || 0}</GradeValue>
        </SliderContainer>
      );
      
      specialities.doubleClicked.forEach((subject) => {
        sliders.push(
          <SliderContainer key={subject}>
            <SliderLabel>{specialityMap[subject]}</SliderLabel>
            <CustomSlider
              type="range"
              min={0}
              max={20}
              step={0.5}
              value={grades[subject] || 0}
              onChange={(e) => handleSliderChange(subject, parseFloat(e.target.value))}
            />
            <GradeValue>{grades[subject] || 0}</GradeValue>
          </SliderContainer>
        );
      });
    }
  
    return sliders;
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      <ProgressBar startPercent={40} endPercent={55} />
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <GradeContainer style={{
          opacity: isTyping ? 0 : 1,
          transition: 'opacity 0.3s ease',
          pointerEvents: isTyping ? 'none' : 'auto'
        }}>
          {renderSliders()}
        </GradeContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={!isSubmitEnabled() || isTyping}
          type="button"
          style={{
            opacity: (!isSubmitEnabled() || isTyping) ? 0.5 : 1,
            cursor: (!isSubmitEnabled() || isTyping) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default GeneralMoyenne;