import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import compassImage from '../../assets/meo_aventurier.png';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  PrimaryButton,
  ChatBubbleContainer,
  ChatIcon,
  ChatText
} from './styles';
import { ReturnButton } from '../AccountCreation/styles';

const NoUserXP = () => {
  // Initialize state for user type from localStorage
  const [userType, setUserType] = useState(() => {
    return localStorage.getItem('type_utilisateur') || '';
  });

  // Function to capitalize first letter
  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  };

  // Format user type for display
  const formattedUserType = capitalizeFirstLetter(userType);

  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = `Le parcours utilisateur est personnalisé pour les lycéens. La version ${formattedUserType} arrive très prochainement !`;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Effect to listen for changes in localStorage
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'type_utilisateur') {
        setUserType(e.newValue || '');
      }
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Text animation effect
  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, [fullText]); // Depends on fullText which includes userType

  const handleBack = () => {
    navigate('/user-type-selection');
  };

  const handleSubmit = () => {
    if (isTyping) return;
    setIsLoading(true);
    try {
      // Navigate to first-login
      navigate('/source-connaissance');
    } catch (error) {
      console.error('Error during navigation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Customize button text based on user type
  const getButtonText = () => {
    switch (userType) {
      case 'etudiant':
        return 'Entrer dans la jungle !';
      case 'parent':
        return 'Entrer dans la jungle !';
      case 'autre':
        return 'Entrer dans la jungle !';
      default:
        return 'Entrer dans la jungle !';
    }
  };

  // If no user type is found in localStorage, redirect to selection page
  useEffect(() => {
    if (!userType) {
      navigate('/user-type-selection');
    }
  }, [userType, navigate]);

  return (
    <Container>
      <ContentWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>

        <ChatBubbleContainer>
          <ChatText isVisible={text.length > 0}>
            {text}
          </ChatText>
        </ChatBubbleContainer>

        <LogoImage src={compassImage} alt="Compass Logo" />
        
        <PrimaryButton
          onClick={handleSubmit}
          disabled={isTyping || isLoading}
          style={{
            opacity: (isTyping || isLoading) ? 0.5 : 1,
            cursor: (isTyping || isLoading) ? 'not-allowed' : 'pointer'
          }}
        >
          {getButtonText()}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default NoUserXP;