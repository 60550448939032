import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import compassImage from '../../assets/meo_normal.png';
import { HiOutlineChatBubbleBottomCenter } from "react-icons/hi2";
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  PrimaryButton,
  ChatBubbleContainer,
  ChatIcon,
  ChatText
} from './styles';
import { ReturnButton } from '../AccountCreation/styles';

const ThirdPage = () => {
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Juste quelques questions avant de prendre en main ton orientation !";
  const navigate = useNavigate();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15); // Adjust typing speed here (milliseconds)

    return () => clearInterval(typingInterval);
  }, []);

  const handleBack = () => {
    navigate('/');
  };

  const handleCreateAccount = () => {
    navigate('/user-type-selection');
  };

  return (
    <Container>
      <ContentWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>

        <ChatBubbleContainer>
          <ChatText isVisible={text.length > 0}>
            {text}
          </ChatText>
        </ChatBubbleContainer>

        <LogoImage src={compassImage} alt="Compass Logo" />
        
        <PrimaryButton 
          onClick={handleCreateAccount} 
          disabled={isTyping}
          style={{
            opacity: isTyping ? 0.5 : 1,
            cursor: isTyping ? 'not-allowed' : 'pointer'
          }}
        >
          Continuer
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default ThirdPage;