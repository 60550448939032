// Updated Profile/styles.js
import styled from 'styled-components';
import theme from '../../theme';
import { IoSettingsOutline } from "react-icons/io5";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.medium};
  position: relative;
  background: ${({ theme }) => theme.colors.background};
  height: 94vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background_fonce};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.secondary};
    }
  }
`;


export const SwipeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.medium};
  border-radius: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;


export const SwipeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.lightText};
  position: relative; // Added to position the badge absolutely within this container

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.background};
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background_fonce};
  height: 15vh; // Set a fixed height for the header
`;

export const LogoImage = styled.img`
  height: 150px;
  width: auto;
  margin-bottom: 0;
  margin-top: -10%;
  object-fit: contain;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.noir};
  text-align: center;
  margin: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  padding: ${({ theme }) => theme.spacing.medium};
  flex: 1;
`;

export const ProfileCard = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: ${({ theme, variant }) => {
    switch (variant) {
      case 'info':
        return theme.colors.primary;
      case 'formation':
        return theme.colors.vert;
      case 'bac':
        return theme.colors.bleu_fonce;
      case 'user':
        return theme.colors.gris_clair;
      case 'domain':
            return theme.colors.violet;
      default:
        return theme.colors.primary;
    }
  }};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

export const CardTitle = styled.h2`
  color: ${({ theme }) => theme.colors.lightText};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: ${({ theme }) => theme.spacing.small};
  text-align: center;
  margin-top: 5%;
`;

export const FormationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  gap: ${theme.spacing.large};

`;

export const IconsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing.medium};

`;

export const CardContent = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
  min-height: 80px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
`;
export const CardIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const UserInfoLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.lightText};
  opacity: 0.9;
`;

// Add this new styled component for the notification
export const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${theme.colors.rouge};
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  pointer-events: none; // Ensures the badge doesn't interfere with click events
`;

export const IconLabelPair = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 60px);
  gap: ${({ theme }) => theme.spacing.large};
  width: 100%;
  flex: 1;
  min-width: 0;
`;

export const UserInfoValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.lightText};
  flex: 1;
  min-width: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(90%);

`;



export const SwipeText = styled(UserInfoValue)`
  padding-left: 0;
  margin: 0;
`;

export const ModifyButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.lightText};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.medium};
  transition: transform 0.2s ease, background-color 0.2s ease;

  svg {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  &:hover {
    transform: rotate(15deg);
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const MessageStatus = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  border-radius: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background: ${({ theme, error }) => error ? theme.colors.primary : theme.colors.background_fonce};
  color: ${({ theme, error }) => error ? theme.colors.lightText : theme.colors.noir};
`;


// Button Components
const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
  border: none;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: pointer;
  transition: all 0.2s ease;
  gap: ${({ theme }) => theme.spacing.small};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  svg {
    font-size: 1.2em;
  }
`;


export const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.large};
  border-radius: 12px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.noir};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  p {
    color: ${({ theme }) => theme.colors.gris};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    line-height: 0.2;
  }
`;

export const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.large};
`;

export const ConfirmButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.lightText};
  padding: ${({ theme }) => theme.spacing.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CancelButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.gris_clair};
  color: ${({ theme }) => theme.colors.noir};
  padding: ${({ theme }) => theme.spacing.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gris_clair_clair};
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.colors.gris_clair};
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.noir};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;



// Settings button positioning
export const SettingsButton = styled.button`
  position: fixed;
  top: ${theme.spacing.medium};
  right: ${props => props.isMobile ? '10px' : '20px'};
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  z-index: 11;
`;

export const SettingsIcon = styled(IoSettingsOutline)`
  font-size: ${props => props.isMobile ? '20px' : '24px'};
  color: ${props => props.theme.colors.primary};
`;
