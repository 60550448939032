// ShareTopSchools.js
import React from 'react';
import { MdIosShare } from "react-icons/md";
import * as styles from './Share_styles';
import headerImage from '../../assets/meo_trophy.png';
import theme from '../../theme';

// ShareButton Component
const ShareButton = ({ onClick }) => (
  <button 
    onClick={onClick} 
    style={{ 
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <MdIosShare size={24} color={theme.colors.primary} />
  </button>
);

// Utility to load images with CORS support
const loadImageAsync = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = src;
  });
};

// Helper function for text truncation
const truncateText = (text, maxWidth, ctx, font) => {
  ctx.font = font;
  let width = ctx.measureText(text).width;
  if (width <= maxWidth) return text;

  while (width > maxWidth && text.length > 0) {
    text = text.slice(0, -1);
    width = ctx.measureText(text + '...').width;
  }
  return text + '...';
};

// Helper function for rounded rectangles
function roundRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
  ctx.fill();
}

const generateImage = async (rankings) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas size with higher resolution
  const canvasWidth = 1200;
  const canvasHeight = 1500;
  const scale = window.devicePixelRatio || 1;
  canvas.width = canvasWidth * scale;
  canvas.height = canvasHeight * scale;
  ctx.scale(scale, scale);

  // Add gradient background
  const gradient = ctx.createLinearGradient(0, 0, 0, canvasHeight);
  gradient.addColorStop(0, '#ffffff');
  gradient.addColorStop(1, '#f8f9fa');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // Load and draw header image
  try {
    const headerImg = await loadImageAsync(headerImage);
    const headerWidth = 300;
    const headerHeight = (headerImg.height / headerImg.width) * headerWidth;
    const headerX = (canvasWidth - headerWidth) / 2;
    const headerY = 50;
    ctx.drawImage(headerImg, headerX, headerY, headerWidth, headerHeight);
  } catch (error) {
    console.error('Error loading header image:', error);
    // Add decorative header line as fallback
    const headerLineY = 200;
    ctx.strokeStyle = '#e9ecef';
    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.moveTo(100, headerLineY);
    ctx.lineTo(canvasWidth - 100, headerLineY);
    ctx.stroke();
  }

  // Add Title with more spacing
  ctx.fillStyle = '#1a1a1a';
  ctx.font = 'bold 64px Arial';
  ctx.textAlign = 'center';
  ctx.fillText('Mes 3 Meilleures Écoles', canvasWidth / 2, 400);

  // Draw each school ranking
  const topSchools = Object.entries(rankings).slice(0, 3);
  topSchools.forEach(([key, school], index) => {
    const rank = parseInt(key.replace('top', ''), 10);
    const yPosition = 500 + index * 320;

    // Draw container
    const rectX = 100;
    const rectY = yPosition;
    const rectWidth = canvasWidth - 200;
    const rectHeight = 280;
    const radius = 16;

    // Container shadow
    ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
    ctx.shadowBlur = 10;
    ctx.shadowOffsetY = 4;

    // Container background
    ctx.fillStyle = '#ffffff';
    roundRect(ctx, rectX, rectY, rectWidth, rectHeight, radius);

    // Reset shadow
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetY = 0;

    // Rank circle
    const rankColors = ['#FFD700', '#C0C0C0', '#CD7F32'];
    ctx.fillStyle = rankColors[index];
    ctx.beginPath();
    ctx.arc(rectX + 60, rectY + 60, 30, 0, Math.PI * 2);
    ctx.fill();

    // Rank number
    ctx.fillStyle = '#ffffff';
    ctx.font = 'bold 36px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(rank.toString(), rectX + 60, rectY + 72);

    // School Name with larger font
    ctx.fillStyle = '#1a1a1a';
    ctx.font = 'bold 36px Arial';
    ctx.textAlign = 'left';
    const nameX = rectX + 120;
    const nameY = rectY + 70;
    const nameMaxWidth = rectWidth - 150;
    const truncatedName = truncateText(school.name, nameMaxWidth, ctx, 'bold 36px Arial');
    ctx.fillText(truncatedName, nameX, nameY);

    // Formation
    ctx.fillStyle = '#4a4a4a';
    ctx.font = '24px Arial';
    const formationY = rectY + 130;
    const formationMaxWidth = rectWidth - 150;
    const truncatedFormation = truncateText(
      school.formation,
      formationMaxWidth,
      ctx,
      '24px Arial'
    );
    ctx.fillText(truncatedFormation, nameX, formationY);

    // City with subtle styling
    ctx.fillStyle = '#6c757d';
    ctx.font = '24px Arial';
    ctx.textAlign = 'right';
    const cityX = rectX + rectWidth - 40;
    const cityY = rectY + 130;
    ctx.fillText(school.city, cityX, cityY);
  });

  // Add Footer
  ctx.fillStyle = '#6c757d';
  ctx.font = '28px Arial';
  ctx.textAlign = 'center';
  ctx.fillText('Trouvez votre école idéale sur meoria.com', canvasWidth / 2, canvasHeight - 100);

  return canvas.toDataURL('image/png');
};

const ShareTopSchools = ({ rankings }) => {
  const handleShare = async () => {
    try {
      // Generate the image
      const dataUrl = await generateImage(rankings);

      // Convert data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Try to share the image
      if (
        navigator.share &&
        navigator.canShare &&
        navigator.canShare({
          files: [new File([blob], 'top-schools.png', { type: 'image/png' })],
        })
      ) {
        try {
          await navigator.share({
            title: 'Mes 3 Meilleures Écoles',
            text: "Découvrez mes meilleures correspondances d'écoles !",
            files: [new File([blob], 'top-schools.png', { type: 'image/png' })],
          });
          return;
        } catch (shareError) {
          console.log('Le partage de fichiers a échoué, passage au partage de texte.');
        }
      }

      // Fallback to text sharing
      const textContent = `Mes 3 Meilleures Écoles:\n\n${Object.entries(rankings)
        .slice(0, 3)
        .map(
          ([key, school]) =>
            `${parseInt(key.replace('top', ''))}. ${school.name} - ${school.formation} (${school.city})`
        )
        .join('\n')}\n\nTrouvez votre école idéale sur meoria.com`;

      if (navigator.share) {
        await navigator.share({
          title: 'Mes 3 Meilleures Écoles',
          text: textContent,
        });
      } else {
        // Final fallback: download the image
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'top-schools.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Erreur lors du partage:', error);
      // Final fallback: copy to clipboard
      try {
        const textContent = `Mes 3 Meilleures Écoles:\n\n${Object.entries(rankings)
          .slice(0, 3)
          .map(
            ([key, school]) =>
              `${parseInt(key.replace('top', ''))}. ${school.name} - ${school.formation} (${school.city})`
          )
          .join('\n')}`;
        await navigator.clipboard.writeText(textContent);
        alert(
          "Le partage de l'image a échoué. Le texte a été copié dans le presse-papiers à la place !"
        );
      } catch (clipboardError) {
        alert('Impossible de partager. Veuillez réessayer plus tard.');
      }
    }
  };

  return <ShareButton onClick={handleShare} />;
};

export default ShareTopSchools;