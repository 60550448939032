import styled, { keyframes, css } from 'styled-components'; // Add css import
import theme from '../../theme';


const bounce = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
`;

const pop = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3) rotate(15deg); }
  100% { transform: scale(1) rotate(0); }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 168px; // Hauteur fixe
  display: flex;
  justify-content: center;
  background: ${theme.colors.background};
  z-index: 1603;
  overflow: hidden; // Empêche le débordement
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
  text-align: left;
  flex-grow: 1; // Add this to take available space
  display: flex;
  flex-direction: column;
  justify-content: flex-end; // Push content to bottom
  padding-bottom: 1rem; // Add bottom padding
`;
// styles.js
// In your styles.js (or wherever your styled components are defined)
export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0px; // Space between icon and text
  padding: 0px 3px;
  background: white;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid #ddd;

  &:hover {
    background: #f5f5f5;
    transform: translateY(-1px);
  }
`;

export const ButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;  // Fixed size for icon container
  height: 24px;
`;

export const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
export const Subtitle = styled.span`
  font-family: 'Gabarito', sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-left: -10%;
`;

export const ButtonText = styled.span`
  font-family: 'Gabarito', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;

  ${ActionButton}:hover & {
    color: #000;
  }
`;

export const SchoolName = styled.span`
  font-size: 0.7rem;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  display: block;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.7rem;
  margin-left: 0;
  margin-bottom: 0rem;
  margin-right: 35px;
`;

export const CityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-left: 3rem;
`;

export const CityText = styled.span`
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
`;

export const FormationName = styled.h1`
  color: white;
  font-size: 1.2rem;
  font-family: 'Gabarito', sans-serif;
  font-weight: 500;
  margin: 0.2rem 0 0.5rem 0;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: -1rem;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
`;


export const HeaderSvg = styled.svg`
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 10rem;
`;

export const HeaderContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 379px;
  height: 168px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 0rem; // Add bottom padding
`;

/* ----------------- HEADER ROWS ----------------- */

/** Top row: Return button (left) + icons (right) */
export const HeaderTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

/** Bottom row: formation name (left) + percentage (right) */
export const HeaderBottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;


const floatHeartAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -200px) scale(0);
    opacity: 0;
  }
`;

export const FloatingHeart = styled.div`
  position: absolute;
  pointer-events: none;
  animation: ${floatHeartAnimation} 2s ease-out forwards;
  color: #fff; // Force white color
  font-size: 24px;
  z-index: 2000;
`;

export const LikeIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 4%;
  margin-right: 5%;
  color: ${props => props.$isFavorite ? '#FFF' : 'rgba(255,255,255, 1)'};
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    color: #FFF;
  }

  animation: ${props => props.$animate 
    ? css`${bounce} 0.5s ease` 
    : 'none'};
`;



export const ShareIcon = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  animation: ${props => props.$animate
    ? css`${pop} 0.3s ease`
    : 'none'};
`;

export const SectionText = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.4;
  margin: 0.25rem 0;
  text-align: left;
  width: 100%;
  word-break: break-word;
  padding-left: 1em;
  position: relative;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #666;
  }
`;


export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 1603;

  /* Push content below the fixed header (168px) */
  padding-top: 168px;

  background-color: ${({ theme }) => theme.colors.background || '#f5f5f5'};
  font-family: 'Gabarito', sans-serif;
`;

export const ReturnButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  /* Keep or adjust if you want the button above the header. */
  z-index: 1604;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);
    svg {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: -5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0 80px; // Ajoute un padding en bas
`;
export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 0.5rem 0;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  text-align: center;
`;

export const InfoIcon = styled.div`
  width: 31px;
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const InfoText = styled.div`
  font-size: 18px;
  font-family: 'Gabarito', sans-serif;
  color: #000;
  text-align: center;
  width: 70%;
`;

export const Section = styled.div`
  width: 95%;
  margin: 1rem auto;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  font-family: 'Gabarito', sans-serif;
  color: #000;
  margin-bottom: 1rem;
  font-weight: 600;
`;


export const SubsectionTitle = styled.h3`
  font-size: 18px;
  font-family: 'Gabarito', sans-serif;
  color: #333;
  margin: 1rem 0 0.5rem;
  font-weight: 500;
`;

export const GrayBandWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;


export const GrayBand = styled.div`
  width: 160%;
  height: 23px;
  border-radius: 25px;
  background: #f2f2f2;
  margin: 1rem 0;
  position: relative;
  left: -30%;
  box-sizing: border-box;

  /* Prevent horizontal scroll */
  @media (max-width: 768px) {
    width: 200%;
    left: -50%;
  }
`;

/* ----------------- DOMAINS MATCHING ----------------- */
export const DomainContainer = styled.div`
  width: 95%;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  border-radius: 25px;
  background: #F7F3F0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
`;


export const DomainItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DomainIcon = styled.div`
  width: 35px;
  height: 35px;
  background: none;
  border-radius: 4px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DomainText = styled.div`
  font-size: ${theme.fontSizes.small};
  color: #000;
  font-weight: 400;
`;


export const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ImportanceSubtitle = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
  margin: 0.5rem 0;
  text-align: center;
  width: 30%;
`;

export const DomainItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
`;

export const LeftDomainContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  max-width: 70%;
`;

export const RightIconsContainer = styled.div`
  display: flex;
  justify-content: center;  // Center horizontally
  align-items: center;      // Center vertically
  gap: 0.25rem;
  width: 30%;               // Match the remaining width
  height: 100%;             // Take full height of parent
`;

export const ImportanceIcons = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${theme.colors.primary};
`;
