import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Poppins:wght@600&display=swap');

  :root {
    --bottom-menu-height: 60px;
    --input-area-height: 50px;
    --header-height: 60px;
    --podium-height: 280px;
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
    --vh: 1vh;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;
    overscroll-behavior: none;
  }

  body {
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.text};
    background-color: ${theme.colors.background};
    line-height: 1.5;
    height: 100%;
    width: 100%;
    overflow-y: auto !important;
    position: relative;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
    /* Prevent text selection */
    -webkit-user-select: none;
    user-select: none;

  }

  #root {
    height: 100%;
    overflow: visible;

    /* Account for bottom menu on mobile */
    padding-bottom: calc(var(--bottom-menu-height) + var(--safe-area-inset-bottom));

    /* Ensure proper height calculation */
    min-height: calc(var(--vh, 1vh) * 100);

    /* Handle mobile navigation gestures */
    touch-action: pan-y pinch-zoom;
  }

  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    margin-bottom: ${theme.spacing.medium};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
    border: none;
    border-radius: 4px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.lightText};

    &:hover {
      opacity: 0.9;
    }

    &:focus {
      outline: none;
    }

    -webkit-tap-highlight-color: transparent;
  }

  /* Scrollbar styling */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.mediumGray} ${theme.colors.lightGray};
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: ${theme.colors.lightGray};
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.mediumGray};
    border-radius: 4px;

    &:hover {
      background-color: ${theme.colors.darkGray};
    }
  }

  /* Mobile specific styles */
  @media (max-width: ${theme.breakpoints.mobile}) {
    body {
      font-size: ${theme.fontSizes.small};

      /* Better handling of bottom navigation */
      padding-bottom: calc(var(--bottom-menu-height) + var(--safe-area-inset-bottom));
    }

    button,
    input[type="button"],
    input[type="submit"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      min-height: 44px; /* Better touch targets */
    }

    /* Prevent elastic scrolling on iOS */
    .scroll-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: var(--bottom-menu-height);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  /* Handle iPhone notch and safe areas */
  @supports (padding: max(0px)) {
    body {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
      padding-bottom: min(0vmin, env(safe-area-inset-bottom));
    }
  }
`;

export default GlobalStyles;