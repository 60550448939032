import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import compassImage from '../../assets/meo_aventurier.png';
import { HiOutlineChatBubbleBottomCenter } from "react-icons/hi2";
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  PrimaryButton,
  ChatBubbleContainer,
  ChatIcon,
  ChatText
} from './styles';
import { ReturnButton } from '../AccountCreation/styles';

const MessagePret = () => {
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Swipe pour trouver la formation qui te correspond ou utilise le Chat pour me poser toutes tes questions ! On y va ?";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handleBack = () => {
    navigate('/message-pret');
  };

  const handleSubmit = () => {
    if (isTyping) return;
    setIsLoading(true);
    try {
        // Navigate to first-login
      navigate('/first-login');
    } catch (error) {
      console.error('Error during navigation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>

        <ChatBubbleContainer>
          <ChatText isVisible={text.length > 0}>
            {text}
          </ChatText>
        </ChatBubbleContainer>

        <LogoImage src={compassImage} alt="Compass Logo" />
        
        <PrimaryButton
          onClick={handleSubmit}
          disabled={isTyping || isLoading}
          style={{
            opacity: (isTyping || isLoading) ? 0.5 : 1,
            cursor: (isTyping || isLoading) ? 'not-allowed' : 'pointer'
          }}
        >
          Suivre Meo dans la jungle !
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default MessagePret;