import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Components
import ChatDiscussion from './components/ChatDiscussion';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottomMenu';
import OrigineCity from './components/OrigineCity';
import LoginPage from './components/LoginPage';
import FirstLoginAccount from './components/FirstLoginAccount';
// import Quiz from './components/Quiz';
import Classement from './components/Classement';
import CardTypeFormation from './components/CardTypeFormation';
import ModifySwipeAnswers from './components/ModifySwipeAnswers';
import FormationsFavoris from './components/FormationsFavoris';
import FicheFormation from './components/FicheFormation';

import Settings from './components/Settings';
import MobileHistoryTab from './components/MobileHistoryTab';
import PresentationFeatures from './components/PresentationFeatures';
import FirstPage from './components/FirstPage';
import SecundPage from './components/SecundPage';
import QuizTypeFormation from './components/QuizTypeFormation';
import TypeBac from './components/TypeBac';
import BacGeneral from './components/BacGeneral';
import GeneralMoyenne from './components/GeneralMoyenne';
import DomaineFormation from './components/DomaineFormation';
import SubDomainesFormation from './components/SubDomainesFormation';
import TypeFormation from './components/TypeFormation';
import ThirdPage from './components/ThirdPage';
import NoUserXP from './components/NoUserXP';
import SourceConnaissance from './components/SourceConnaissance';
import ImportanceOrientation from './components/ImportanceOrientation';
import MessagePret from './components/MessagePret';
import AccountCreation from './components/AccountCreation';
import DownloadApp from './components/DownloadApp';
import GenderSelection from './components/GenderSelection';
import UserTypeSelection from './components/UserTypeSelection';
import LandingPage from './components/LandingPage';
// Contexts and Providers
import { AuthProviderWithNavigate, useAuth } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import { QuizProvider } from './contexts/QuizContext';
import AppLoadingHandler from './contexts/AppLoadingHandler';

// Profile
import Profile from './components/Profile';
import UserTypeFormationSelectionProfile from './components/TypeFormation/index_modify_profile';
import CitySelectionProfile from './components/OrigineCity/index_modify_profile';
import TypeBacProfile from './components/TypeBac/index_modify_profile';
import UserTypeSelectionProfile from './components/UserTypeSelection/index_modify_profile';
import UserDomaineFormationSelectionProfile from './components/TypeFormation/index_modify_profile';
import SubDomainesFormationProfile from './components/SubDomainesFormation/index_modify_profile';
import BacGeneralProfile from './components/BacGeneral/index_modify_profile';

// Styles and Utils
import { restoreAuthState, isAuthenticated as isUserAuthenticated} from './utils/auth'; // Import ajouté ici
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  // Gestion des mises à jour PWA
  useEffect(() => {
    const handleControllerChange = () => window.location.reload();

    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.register('/service-worker.js');

          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                newWorker.postMessage({ type: 'SKIP_WAITING' });
              }
            });
          });

          navigator.serviceWorker.addEventListener('controllerchange', handleControllerChange);
        } catch (error) {
          console.log('Service Worker registration failed:', error);
        }
      }
    };

    registerServiceWorker();

    return () => {
      navigator.serviceWorker?.removeEventListener('controllerchange', handleControllerChange);
    };
  }, []);

  // Restauration de l'authentification après rechargement
  useEffect(() => {
    const handleAuthRestore = async () => {
      await restoreAuthState();
      // if (!isUserAuthenticated() && !location.pathname.includes('/login')) {
      //   navigate('/');
      // }
    };

    handleAuthRestore();
  }, [navigate, location]);





  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);

  // Hooks
  const { user, loading, isAuthenticated } = useAuth();
  const { isMobile } = useDeviceType();

  const chatDiscussionRef = useRef();

  // Utility functions
  const toggleMenu = useCallback(() => {
    setIsSideMenuOpen(prevState => !prevState);
  }, []);

  const toggleMobileHistory = useCallback(() => {
    setIsMobileHistoryOpen(!isMobileHistoryOpen);
  }, [isMobileHistoryOpen]);

  const handleDiscussionSelect = useCallback((discussion) => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.loadDiscussion(discussion.id);
    }
  }, []);

  const handleRestartChat = useCallback(() => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.resetChat();
    }
  }, []);

  // Viewport height effect
  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateViewportHeight();
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });

    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  // Memoized ChatDiscussion component
  const MemoizedChatDiscussion = memo(({ ref, toggleMobileHistory, isSideMenuOpen, isMobile }) => (
    <ChatDiscussion
      ref={ref}
      toggleMobileHistory={toggleMobileHistory}
      isSideMenuOpen={isSideMenuOpen}
      isMobile={isMobile}
    />
  ));

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  const renderMainContent = () => {
    // If authenticated, show the main app
    if (isAuthenticated) {
      return (
        <AuthProviderWithNavigate>
          <ChatProvider>
            <QuizProvider>
              <div className="App">
                <PWAInstallPrompt />
                <SideMenu
                  isOpen={isSideMenuOpen}
                  onToggle={toggleMenu}
                  onDiscussionSelect={handleDiscussionSelect}
                  onRestartChat={handleRestartChat}
                />
                <main className={`content-area ${isMobile ? 'mobile' : 'desktop'} ${isSideMenuOpen ? 'side-menu-open' : ''}`}>
                  <Routes>
                    <Route path="/chat" element={
                      <MemoizedChatDiscussion
                        ref={chatDiscussionRef}
                        toggleMobileHistory={toggleMobileHistory}
                        isSideMenuOpen={isSideMenuOpen}
                        isMobile={isMobile}
                      />
                    } />
                    {/* <Route path="/quiz" element={!isMobile ? <DownloadApp /> : <Quiz />} /> */}
                    <Route path="/classement" element={!isMobile ? <DownloadApp /> : <Classement />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/quiz-type-formation" element={<QuizTypeFormation />} />
                    <Route path="/account-creation" element={<AccountCreation />} />
                    <Route path="/card-type-formation/:id" element={<CardTypeFormation />} />
                    <Route path="/fiche-formation" element={<FicheFormation />} />
                    <Route path="/formation" element={<FicheFormation />} />
                    <Route path="/favoris" element={<FormationsFavoris />} />
                    {/* Route principale du profil */}
                    <Route path="/profile" element={<Profile />} />
                    {/* Routes pour modifier chaque champ */}
                    <Route path="/profile/modify/city" element={<CitySelectionProfile />} />
                    <Route path="/profile/modify/type-formation" element={<UserTypeFormationSelectionProfile />} />
                    <Route path="/profile/modify/subdomaine-formation" element={<SubDomainesFormationProfile />} />
                    <Route path="/profile/modify/domaine-formation" element={<UserDomaineFormationSelectionProfile />} />
                    <Route path="/profile/modify/specialites" element={<BacGeneralProfile />} />
                    <Route path="/profile/modify/type-bac" element={<TypeBacProfile />} />
                    <Route path="/profile/modify/swipe" element={<ModifySwipeAnswers />} />
                    <Route path="/profile/modify/user-type" element={<UserTypeSelectionProfile />} />
                    <Route path="/" element={<Navigate to="/chat" replace />} />
                  </Routes>
                </main>
                {isMobile && (
                  <>
                    <BottomMenu/>
                    {location.pathname === '/chat' && (
                      <MobileHistoryTab
                        isOpen={isMobileHistoryOpen}
                        onClose={toggleMobileHistory}
                      />
                    )}
                  </>
                )}
              </div>
            </QuizProvider>
          </ChatProvider>
        </AuthProviderWithNavigate>
      );
    }

    // Render public routes, onboarding
    return (
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<FirstPage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Onboarding Flow */}
        <Route path="/presentation-meo" element={<SecundPage />} />
        <Route path="/origine-city" element={<OrigineCity />} />
        <Route path="/gender-selection" element={<GenderSelection />} />
        <Route path="/no-user-xp" element={<NoUserXP />} />
        <Route path="/user-type-selection" element={<UserTypeSelection />} />
        <Route path="/type-bac" element={<TypeBac />} />
        <Route path="/bac-general" element={<BacGeneral />} />
        <Route path="/domaine-formation" element={<DomaineFormation />} />
        <Route path="/subdomaine-formation" element={<SubDomainesFormation />} />
        <Route path="/presentation-features" element={<PresentationFeatures />} />
        <Route path="/type-formation" element={<TypeFormation />} />
        <Route path="/importance-orientation" element={<ImportanceOrientation />} />
        <Route path="/message-pret" element={<MessagePret />} />
        <Route path="/just-4-questions" element={<ThirdPage />} />
        <Route path="/general-moyenne" element={<GeneralMoyenne />} />
        <Route path="/source-connaissance" element={<SourceConnaissance />} />

        {/* Account Creation */}
        <Route path="/account-creation" element={<AccountCreation />} />
        <Route path="/first-login" element={<FirstLoginAccount />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <AppLoadingHandler>
          <GlobalStyles />
          {renderMainContent()}
        </AppLoadingHandler>
    </ThemeProvider>
  );
}

export default App;