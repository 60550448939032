import styled, {keyframes} from 'styled-components';
import theme from '../../theme';


export const OptionsScrollContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 60);
  margin-bottom: calc(var(--vh, 1vh) * 20);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: ${theme.spacing.large};
  padding-bottom: ${theme.spacing.large};

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

`;

export const OptionsContainer = styled.div`
  position: relative;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.xlarge};
`;



export const SpeechBubbleText = styled.div`
  position: absolute;
  top: 35%;
  left: 45%;
  transform: translate(-50%, -50%);
  font-family: ${theme.fonts.main};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xxlarge};
  color: ${theme.colors.noir};
  text-align: flex-end;
  width: 80%;
  line-height: 1.4;
`;

// Add a new styled component for the icon image
export const OptionIcon = styled.img`
  width: 20%;
  object-fit: contain;
  transition: transform 0.2s ease;

  ${props => props.isSelected && `
    transform: scale(1.05);
  `}

`;

export const OptionButton = styled.button`
  width: 100%;
  height: calc(var(--vh, 1vh) * 10);
  max-height: 80px;
  min-height: 70px;
  border-radius: 20px;
  background: #FFFFFF;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: ${theme.colors.noir};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.large};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  
  /* Layout for icon + text */
  display: flex;
  align-items: center;
  gap: ${theme.spacing.medium};
  padding: 0 ${theme.spacing.large};

  /* Prevent text selection */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  /* Hover state */
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  /* Active/Pressed state */
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Selected state */
  ${props => props.isSelected && `
    background: ${theme.colors.bleu};
    color: ${theme.colors.bleu_fonce};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    
    &:hover {
      transform: none;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    }
  `}

  /* Adjust for smaller screens */
  @media (max-height: 550px) {
    height: calc(var(--vh, 1vh) * 10);
    min-height: 40px;
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--vh, 1vh) * 3);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-height: 550px) {
    gap: calc(var(--vh, 1vh) * 2);
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.error};
  text-align: center;
  margin: calc(var(--vh, 1vh) * 2) 0;
  font-size: ${theme.fontSizes.small};
  min-height: calc(var(--vh, 1vh) * 3);
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: ${props => props.isLoading ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  ${props => props.isLoading && 'opacity: 1;'}
`;


export const LogoImage = styled.img`
  width: 25%;
  height: 25%;
  object-fit: contain;
  margin-left: 10%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const ChatBubbleContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 7); // Adjust this value to match your logo's position
  left: ${props => props.isMobile ? '38%' : '45%'};
  transform: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: 0.8rem 1.2rem;
  font-family: ${theme.fonts.bubble};
  border-radius: 1rem;
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: 94%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;