import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // <-- Import useNavigate
import { 
  FiltersContainer,
  FilterButton,
  FilterText,
  Counter,
  CounterNumber,
  DropdownArrow,
  Dropdown,
  DropdownItem,
  EstablishmentList,
  EstablishmentCard,
  EstablishmentName,
  EstablishmentDetails,
  LikeIcon,
  PlaceholderLine,
  PlaceholderHeart
} from './styles';
import arrowIcon from '../../assets/back_button_gris.png';
import { FaRegHeart } from 'react-icons/fa';
import apiCall from '../../utils/api';
import coeurVideRougeIcon from '../../assets/coeur_vide_rouge_icon.png';

const DecouvrirFormations = ({ formationData }) => {
  const [showDomainDropdown, setShowDomainDropdown] = useState(false);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());
  const domainRef = useRef(null);
  const cityRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  // For navigation:
  const navigate = useNavigate();

  const mainDomains = formationData?.formation_info?.main_domains || [];
  const cities = useMemo(
    () =>
      (formationData?.formation_info?.cities || [])
        .flatMap(cityStr => cityStr.split(/\s*,\s*/))
        .filter(city => city.length > 0),
    [formationData]
  );

  const unique_types = useMemo(
    () =>
      formationData?.formation_info?.unique_type
        ? [formationData.formation_info.unique_type]
        : [],
    [formationData]
  );

  // Fetch data with abort controller and debounce
  useEffect(() => {
    const fetchData = async () => {
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      try {
        setIsLoading(true);
        const params = new URLSearchParams();

        // Add unique_types instead of links
        unique_types.forEach(ut => {
          params.append('unique_types', ut);
        });

        // Add filters
        params.append('domains', selectedDomains.join('|'));
        params.append('communes', selectedCities.join(','));

        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/quiz/filter/?${params}`,
          'GET',
          null,
          { signal: newAbortController.signal }
        );

        if (response.results) {
          setEstablishments(response.results);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.name !== 'AbortError') {
          console.error('Error fetching establishments:', error);
        }
      }
    };

    const debounceTimer = setTimeout(fetchData, 300);
    return () => clearTimeout(debounceTimer);
  }, [selectedDomains, selectedCities, unique_types]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        domainRef.current &&
        !domainRef.current.contains(event.target) &&
        cityRef.current &&
        !cityRef.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDomainDropdown = () => {
    setOpenDropdown(prev => (prev === 'domain' ? null : 'domain'));
  };

  const toggleCityDropdown = () => {
    setOpenDropdown(prev => (prev === 'city' ? null : 'city'));
  };

  const toggleDomain = (domain, e) => {
    e.stopPropagation();
    setSelectedDomains(prev =>
      prev.includes(domain) ? prev.filter(d => d !== domain) : [...prev, domain]
    );
  };

  const toggleCity = (city, e) => {
    e.stopPropagation();
    setSelectedCities(prev =>
      prev.includes(city) ? prev.filter(c => c !== city) : [...prev, city]
    );
  };

  return (
    <>
      <FiltersContainer>
        <div ref={domainRef}>
          <FilterButton onClick={toggleDomainDropdown}>
            <FilterText>Domaines</FilterText>
            <Counter>
              <CounterNumber>{selectedDomains.length}</CounterNumber>
            </Counter>
            <DropdownArrow src={arrowIcon} alt="arrow" />
            {openDropdown === 'domain' && (
              <Dropdown>
                {mainDomains.map(domain => (
                  <DropdownItem key={domain} onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={selectedDomains.includes(domain)}
                      onChange={(e) => toggleDomain(domain, e)}
                    />
                    {domain}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </FilterButton>
        </div>

        <div ref={cityRef}>
          <FilterButton onClick={toggleCityDropdown}>
            <FilterText>Lieu</FilterText>
            <Counter>
              <CounterNumber>{selectedCities.length}</CounterNumber>
            </Counter>
            <DropdownArrow src={arrowIcon} alt="arrow" />
            {openDropdown === 'city' && (
              <Dropdown>
                {cities.map(city => (
                  <DropdownItem key={city} onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={selectedCities.includes(city)}
                      onChange={(e) => toggleCity(city, e)}
                    />
                    {city}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </FilterButton>
        </div>
      </FiltersContainer>

      <EstablishmentList>
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <EstablishmentCard key={index}>
              <div style={{ width: '100%' }}>
                <PlaceholderLine width="90%" />
                <PlaceholderLine width="60%" />
              </div>
              <LikeIcon>
                <PlaceholderHeart />
              </LikeIcon>
            </EstablishmentCard>
          ))
        ) : (
          establishments.map(est => (
            <EstablishmentCard
              key={est.Link}
              style={{ cursor: 'pointer' }} // Make the card look clickable
              onClick={() => navigate(`/formation?id=${est['Code interne Parcoursup de la formation']}`)}

            >
              <div style={{ width: '100%' }}>
                <EstablishmentName>{est['Nom long de la formation']}</EstablishmentName>
                <EstablishmentDetails>
                  <div>{est["Nom de l'établissement"]}</div>
                </EstablishmentDetails>
              </div>
              <LikeIcon>
                <img src={coeurVideRougeIcon} alt="like" />
              </LikeIcon>
            </EstablishmentCard>
          ))
        )}
      </EstablishmentList>
    </>
  );
};

export default DecouvrirFormations;
