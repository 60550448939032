import styled, { keyframes } from 'styled-components';
import theme from '../../theme';


export const LogoImage = styled.img`
  width: 25%;
  height: 25%;
  margin-bottom: 10%;
  object-fit: contain;
  margin-left: 10%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const ChatBubbleContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 8);
  left: ${props => props.isMobile ? '38%' : '45%'};
  transform: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: ${theme.fonts.bubble};
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: 80%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-bottom: calc(var(--vh, 1vh) * 45);
`;

export const NoIdeaButton = styled.button`
  width: 100%;
  height: calc(var(--vh, 1vh) * 8);
  min-height: 50px;
  border-radius: 15px;
  border: 2px solid ${theme.colors.lightGray};
  margin-top: 10px;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${theme.spacing.medium};

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
    
    &.left-emoji {
      margin-right: 8px;
    }
    
    &.right-emoji {
      margin-left: 8px;
      height: 107%;
    }
  }

  &:hover {
    border-color: ${theme.colors.bleu};
    background-color: ${theme.colors.bleu}10;
  }

  &:focus {
    outline: none;
    border-color: ${theme.colors.bleu};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: calc(var(--vh, 1vh) * 8);
  min-height: 50px;
  border-radius: 15px;
  border: 2px solid ${theme.colors.lightGray};
  padding: 0 ${theme.spacing.large};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};
  background: white;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${theme.colors.bleu};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }

  &::placeholder {
    color: ${theme.colors.lightGray};
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

export const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  max-height: calc(var(--vh, 1vh) * 30);
  overflow-y: auto;
  z-index: 1000;
`;

export const SuggestionItem = styled.div`
  padding: ${theme.spacing.medium};
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};

  &:hover {
    background-color: ${theme.colors.bleu}20;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.lightGray};
  }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: ${props => props.isLoading ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  ${props => props.isLoading && 'opacity: 1;'}
`;