// components/LandingPage/styles.js
// styles.js
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.medium};
  padding-right: ${theme.spacing.large};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 120px; // Space for the logo and bubble
`;


const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 100px;  // Fixed width instead of percentage
  height: auto;  // Maintain aspect ratio
  display: block;
  margin: 4rem auto 2rem auto;  // Added top margin of 4rem
  padding-top: 8rem;  // Additional top padding for extra space
  /* Keep the existing effects */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  animation: ${float} 6s ease-in-out infinite;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
`;

export const Card = styled.div`
  background: ${theme.colors.lightText};
  border-radius: 20px;
  padding: ${theme.spacing.xlarge};
  box-shadow: 0 8px 32px rgba(255, 39, 104, 0.08);
  width: 80%;
  text-align: center;
  margin: 0 auto;
`;



export const Button = styled.button`
  background: ${props => props.primary ? theme.colors.primary : theme.colors.lightText};
  color: theme.colors.primary};
  border: 2px solid ${theme.colors.primary};
  border-radius: 10px;
  padding: ${theme.spacing.medium} ${theme.spacing.xlarge};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  cursor: pointer;
  width: 50%;
  justify-content;

  &:hover {
    background: ${props => props.primary ? theme.colors.secondary : theme.colors.lightText};
  }
`;

export const LinkText = styled.a`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`;

export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || '50%'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled.button`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  width: 250px;  // Fixed width instead of min()
  border-radius: 20px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-in-out;
  padding: ${theme.spacing.medium} ${theme.spacing.large};
  font-family: ${theme.fonts.main};
  font-weight: ${theme.fontWeights.semiBold};
  
  &:hover {
    transform: translateY(1px);
    box-shadow: none;
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }
`;

export const SecondaryButton = styled.button`
  background: ${props => props.background || theme.colors.background};
  color: ${props => props.color || theme.colors.primary};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  width: 250px;  // Fixed width instead of min()
  border-radius: 20px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-in-out;
  padding: ${theme.spacing.medium} ${theme.spacing.large};
  font-family: ${theme.fonts.main};
  font-weight: ${theme.fontWeights.semiBold};
  
  &:hover {
    transform: translateY(1px);
    box-shadow: none;
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }
`;

export const BottomActionsContainer = styled.div`
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 5); // Uses dynamic vh calculation
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: ${theme.spacing.xsmall};
  width: 90%;
  z-index: 1000;
  
  /* Adjust positioning on very short screens */
  @media screen and (max-height: 500px) {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    margin-top: ${theme.spacing.medium};
  }
`;

export const StyledText = styled.span`
  color: ${props => theme.colors.gris};
  font-size: ${props => theme.fontSizes.medium};
  font-weight: ${props => theme.fontWeights.semiBold};
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || theme.colors.noir};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  
  ${props => !props.isMobile && `
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -3px;
      left: 0;
      background-color: currentColor;
      transform: scaleX(0);
      transition: transform 0.15s ease-in-out;
    }

    &:hover::after,
    &:active::after {
      transform: scaleX(1);
    }
  `}
`;

export const Title = styled.h1`
  color: ${props => props.color || theme.colors.noir};
  font-family: "Londrina Solid", sans-serif;
  font-size: ${props => props.isMobile ? '32px' : '40px'};
  font-weight: ${theme.fontWeights.semiBold};
  color: ${theme.colors.primary};
  margin: 0;
  padding: 0;
  line-height: 1.2;
`;

export const Subtitle = styled.h2`
  color: ${theme.colors.gris};
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${theme.fontWeights.medium};
  margin: 0;
  padding: 0;
  line-height: 1.4;

  ${props => props.$isMobile ? `
    font-size: 14px;
  ` : `
    font-size: 16px;
  `}
`;

export const EnhancedContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Makes it fill the viewport height
  margin-bottom: 0; // Ensures no margin at bottom
  overflow: hidden; // Prevents any overflow
`;

export const ColorBlock = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50px;
  transform: rotate(45deg);
  opacity: 0.6;
  z-index: 1;
`;

export const PinkBlock = styled(ColorBlock)`
  background-color: ${theme.colors.primary};
  top: -50px;
  right: -100px;
`;

export const LightPinkBlock = styled(ColorBlock)`
  background-color: ${theme.colors.secondary};
  bottom: -150px;
  left: -100px;
`;

export const VeryLightPinkBlock = styled(ColorBlock)`
  background-color: ${theme.colors.accent};
  top: 40%;
  right: 20%;
`;

export const Section = styled.section`
  min-height: ${props => props.$hero ? 'calc(var(--vh, 1vh) * 100)' : 'auto'};
  padding: ${props => props.$hero ? `${theme.spacing.xxxlarge} 0` : '120px 0'};
  position: relative;
  background-color: ${props => props.$alternate ? theme.colors.background_fonce : 'transparent'};
  display: flex;
  align-items: center;

  & + & {
    // Adds margin between consecutive sections
    margin-top: ${theme.spacing.xxlarge};
  }

`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xlarge};  // Space between buttons
  margin-top: ${theme.spacing.xlarge};  // Space below the text
  width: 100%;  // Full width of parent container
  position: relative;  // This ensures proper stacking context
`;

export const LeftContent = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${theme.spacing.xxlarge};
  
  img {
    width: auto;
    height: 600px; // Fixed height for phone screenshot
    max-height: 80vh; // Ensures it doesn't overflow viewport
    object-fit: contain;
    transition: transform 0.3s ease;
  
    
  }
`;
export const RightContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  background: transparent;
  right: 10%;
  
  img {
    width: 100%;
    max-width: 300px;
    height: auto;
    transition: transform 0.3s ease;

    &.chat-screen {
      max-width: 400px; // Increased size for ChatScreen
    }
  }

`;

export const LandingForestStyles = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start; // Changed to flex-start to allow overflow
  align-items: center;
  perspective: 1000px;
  background: transparent;
  right: -5%; // Increased to push more to the right
  width: 150%; // Doubled width
  overflow: visible; // Ensure overflow is visible
  
  img {
    width: 150%; // Doubled width
    max-width: none;
    height: auto;
    transition: transform 0.3s ease;
    object-fit: cover; // Changed to cover to maintain aspect ratio
    transform: translateX(-20%); // Adjust position to show desired part of image
  }
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${theme.spacing.large} 0;
`;

export const FeatureItem = styled.li`
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.gris};
  margin-bottom: ${theme.spacing.medium};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small};

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    margin-right: ${theme.spacing.small};
  }
`;

export const SectionTitle = styled.h2`
  font-family: "Londrina Solid", sans-serif;
  font-size: ${theme.fontSizes.xxlarge};
  color: ${theme.colors.primary};
  margin: 0;
`;

export const SectionSubtitle = styled.p`
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.gris};
  margin: ${theme.spacing.small} 0;
  line-height: 1.6;
`;

// Add these to your styles.js
export const ChatBubbleContainer = styled.div`
  position: absolute;
  top: 2rem;  // Adjust this value to position relative to logo
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.black};
  padding: ${theme.spacing.medium} ${theme.spacing.large};
  border-radius: ${theme.spacing.medium};
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 215px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  span {
    opacity: ${props => props.$isVisible ? 1 : 0};
    transform: translateY(${props => props.$isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;

export const NavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: min(70px, 8vh);
  background-color: ${theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: space-between; // Changed to space-between
  padding: 0 min(${theme.spacing.xlarge}, 5vw);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

export const NavLogo = styled.h1`
  font-family: "Londrina Solid", sans-serif;
  font-size: ${theme.fontSizes.big});
  color: ${theme.colors.primary};
  margin: 0;
  padding-left: min(7%, 30px);
`;

export const DynamicNavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: min(70px, 8vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 min(${theme.spacing.xlarge}, 5vw);
  z-index: 10;
  transition: all 0.3s ease;
  background-color: ${theme.colors.background};
  box-shadow: ${props => props.$showShadow ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
`;

export const DynamicNavLogo = styled.h1`
  font-family: "Londrina Solid", sans-serif;
  font-size: clamp(30px, 3vw, ${theme.fontSizes.xlarge});
  color: ${theme.colors.primary};
  margin: 0;
  position: ${props => props.$centered ? 'absolute' : 'relative'};
  left: ${props => props.$centered ? '50%' : 'min(7%, 30px)'};
  transform: ${props => props.$centered ? 'translateX(-50%)' : 'none'};
`;

export const PrimaryButtonNav = styled.button`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  width: clamp(120px, 15vw, 170px);
  border-radius: 20px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-in-out;
  padding: ${theme.spacing.medium};
  font-family: ${theme.fonts.main};
  font-weight: ${theme.fontWeights.semiBold};
  font-size: clamp(14px, 1.5vw, ${theme.fontSizes.medium});

  &:hover {
    transform: translateY(1px);
    box-shadow: none;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.medium};
  max-width: 600px;
  margin-top: 100px;
  position: relative;  // Added to establish positioning context
  padding-bottom: calc(var(--vh, 1vh) * 20);  // Add space for buttons
`;

export const MainTitle = styled.h1`
  font-weight: ${theme.fontWeights.bold};
  font-size: 32px;
  font-family: ${theme.fonts.main};
  color: ${theme.colors.primary};
  margin: 0;
  line-height: 1.2;

`;

export const MainSubtitle = styled.p`
  font-size: 18px;
  color: ${theme.colors.gris};
  margin: 16px 0 32px 0;
  line-height: 1.5;
  
`;



export const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding-left: ${theme.spacing.xlarge}; // Reduced padding to decrease height
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: auto;
`;

export const SocialLinksContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.background_fonce};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${theme.spacing.large};
`;

export const SocialLink = styled.a`
  color: #ffffff; // White color for icons
  font-size: 24px;
  margin-left: ${theme.spacing.medium};
  transition: transform 0.3s ease, opacity 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
`;