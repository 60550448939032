import styled from 'styled-components';
import theme from '../../theme';

/**
 * Outer container for the entire card
 * We'll give it a responsive max-width so it scales down on smaller screens.
 */
export const CardContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * Top section with the gradient background shape (390x98).
 * You can either use the exact SVG path or approximate with border-radius.
 * Below, we’re replicating it with a gradient and some border radius.
 */
export const TitlePercentageSection = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100px;
  margin: 0 auto;
  flex-shrink: 0;
  /* The linear-gradient from your Figma specs */
  background: linear-gradient(
    90deg,
    rgba(252, 125, 22, 1) 0%,
    rgba(254, 39, 106, 1) 100%
  );
  border-radius: 25px; /* approximate the path corners */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const TitleText = styled.h3`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.xlarge};
  font-style: normal;
  font-weight: 500;
  margin: 0;
  max-width: 60%;
  flex-shrink: 0;
  text-align: left;
  margin-left: 10%;

  /* Truncate text after three lines with ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;



export const ScoreContainer = styled.div`
  width: 86px;
  height: 74px;
  flex-shrink: 0;
  border-radius: 35px;
  border: 0px solid #000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
`;

/*
  "Match à" text inside the circle
*/
export const ScoreTopText = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.large};
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-bottom: -5px;
`;

/*
  Percentage text inside the circle, bigger and bolder
*/
export const ScoreValue = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.xxxlarge};
  font-weight: 800;
  line-height: 1;
`;


/**
 * Main content area below the gradient.
 * Figma: width: 402px; height: 874px; background: #FFF5F8;
 * We'll keep it fluid (height auto) but keep the background color.
 */
export const ContentSection = styled.div`
  width: 100%;
  margin-top: 1%;
  max-width: 100%;
  background: #F7F3F0;
  display: flex;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  box-sizing: border-box;

`;

/**
 * Row for durée, prix, status
 * We'll distribute them evenly and allow wrapping on small screens.
 */
export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center; /* Or space-between/space-around, depending on your preference */
  align-items: center;
  flex-wrap: wrap;
  gap:3rem; /* Adjust the value to reduce/increase the gap */
  margin: 0.7rem 0;
  margin-top: 0rem;
`;


/**
 * Container for each piece of info (durée, prix, status).
 * color: #000; font-family: Gabarito; font-size: 20px; font-weight: 400;
 */
export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.large};
  font-weight: 400;
  margin: 0.5rem 0;

`;

/**
 * Icons for durée, prix, status
 * width: ~30px, height: ~30px, optionally a slight rotate
 */
export const InfoIconImg = styled.img`
  width: px;
  height: 30px;
  flex-shrink: 0;
  transform: rotate(0.228deg);
`;

/**
 * Domain list (vertical stack)
 */
export const DomainList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 10%;
  margin-top: -0.5rem;
`;

/**
 * Each domain row
 */
export const DomainItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.large};
  font-weight: 400;

`;

export const DomainIcon = styled.img`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  transform: rotate(0.228deg);
`;

/**
 * "En savoir plus" button at the bottom
 * width: 225px; height: 36px; border-radius: 30px; background: #000;
 * The text inside: color: #FFF; font-family: Inter; font-size: 12px; italic; font-weight: 800;
 */
export const EnSavoirPlusButton = styled.button`
  width: 225px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #000;
  border: none;
  cursor: pointer;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;

  &:hover {
    opacity: 0.9;
  }

  /* Use a <span> inside to style the text. */
  span {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: ${theme.fontSizes.medium};
    font-weight: 600;
  }

`;
