// src/components/PopupGlobale/PopupGlobale.js
import React from 'react';
import {
  PopupOverlay,
  PopupContent,
  PopupMessage,
  PopupButtonContainer,
  PopupButton,
  PopupButtonYes
} from './styles';  // Adjust import path as needed

const PopupGlobale = ({ 
  isVisible,
  message,
  onCancel,
  onConfirm,
  cancelText = 'Non',
  confirmText = 'Oui'
}) => {
  if (!isVisible) return null;

  return (
    <PopupOverlay>
      <PopupContent>
        <PopupMessage>{message}</PopupMessage>
        <PopupButtonContainer>
          <PopupButton onClick={onCancel}>{cancelText}</PopupButton>
          <PopupButtonYes onClick={onConfirm}>{confirmText}</PopupButtonYes>
        </PopupButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default PopupGlobale;