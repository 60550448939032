// components/BottomMenu/styles.js
import styled, { css, keyframes } from 'styled-components';
import theme from '../../theme';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1); }
  100% { transform: scale(1); }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
  50% { box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5); }
  100% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
`;

export const BottomMenuContainer = styled.div`
  position: fixed;
  z-index: 1600;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 92px;
  margin-bottom: -15px;
  background: #FFF5F8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -2px 2px 0px rgb(248, 215, 215);
  border-radius: 20px;
  padding: 0 20px;

`;

export const MenuText = styled.span`
  display: block;
  font-size: 13px;
  margin-bottom: 3px;
  font-family: 'Gabarito', sans-serif;
  opacity: ${props => props.active ? 1 : 0.5};
  transition: all 0.3s ease;
  color: ${props => props.active ? '#FF2768' : theme.colors.mediumGray};
  transition: color 0.3s ease;
  text-align: center;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: ${props => props.active ? 1 : 0.5};
  transition: all 0.3s ease;
  color: ${props => props.active ? '#FF2768' : theme.colors.mediumGray};
  position: relative;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    opacity: ${props => props.active ? 1 : 0.8};
  }

  ${props => props.isHighlighted && css`
    opacity: 1;
    z-index: 1601;
  `}

  &:active {
    transform: translateY(0); // Prevent vertical movement on click
  }

  svg {
    vertical-align: middle; // Ensure consistent icon alignment
  }

  ${props => props.hasAnimation && css`
    animation: ${pulseAnimation} 2s infinite;
    
    &::before {
      animation: ${glowAnimation} 2s infinite;
    }
  `}
`;