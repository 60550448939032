import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import compassImage from '../../assets/meo_write_v2.png';

// User type icons
import secondeIcon from '../../assets/seconde-icon.png';
import premiereIcon from '../../assets/premiere-icon.png';
import terminaleIcon from '../../assets/terminale-icon.png';
import etudiantIcon from '../../assets/students-icon.png';
import parentIcon from '../../assets/family-icon.png';
import otherIcon from '../../assets/other-icon.png';

// Formation type icons
import universite_professionnalisante_icon from '../../assets/universite_professionnalisante_icon.png';
import classe_professionnalisante_icon from '../../assets/classe_professionnalisante_icon.png';
import grande_ecole_post_bac_icon from '../../assets/grande_ecole_post_bac_icon.png';
import universite_theorique_icon from '../../assets/universite_theorique_icon.png';
import classe_preparatoire_icon from '../../assets/classe_preparatoire_icon.png';
import dont_know_icon from '../../assets/dont_know_icon.png';

// Bac type icons
import general_icon from '../../assets/general_bac.png';
import tech_icon from '../../assets/tech_bac.png';
import pro_icon from '../../assets/professional_bac.png';

import {
  Container,
  Header,
  LogoImage,
  Title,
  SettingsButton,
  SettingsIcon,
  ContentWrapper,
  ProfileCard,
  UserInfoLabel,
  UserInfoValue,
  ModifyButton,
  CardTitle,
  CardHeader,
  CardContent,
  IconLabelPair,
  CardIcon,
  MessageStatus,
  ConfirmButton,
  ModalButtons,
  CancelButton,
  Input,
  LabelsColumn,
  IconsColumn,
  FormationContainer,
  ModalContent,
  SwipeIconWrapper,
  SwipeText,
  SwipeContainer,
  ModalOverlay,
  NotificationBadge,
} from './styles';
import apiCall from '../../utils/api';

import sciences_icon from '../../assets/sciences_icon.png';
import lettres_sciences_humaines_icon from '../../assets/L_icon.png';
import economie_icon from '../../assets/ES_icon.png';
import arts_culture_icon from '../../assets/arts_culture_icon.png';
import droit_politique_icon from '../../assets/droit_politique_icon.png';
import sante_sport_icon from '../../assets/sante_sport_icon.png';
import { TbSwipe } from "react-icons/tb";
import questions_icon from '../../assets/questions_icon.png';

import artsIcon from '../../assets/arts_culture_icon.png';
import eppcsIcon from '../../assets/sport_icon.png';
import hggspIcon from '../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../assets/hlp_icon.png';
import llcerIcon from '../../assets/llcer_icon.png';
import lcaIcon from '../../assets/lca_icon.png';
import mathIcon from '../../assets/math_icon.png';
import nsiIcon from '../../assets/informatique_icon.png';
import physChimIcon from '../../assets/physics_icon.png';
import svtIcon from '../../assets/svt_icon.png';
import siIcon from '../../assets/engineer_icon.png';
import sesIcon from '../../assets/ses_icon.png';
import bioEcoIcon from '../../assets/bioeco_icon.png';
import ses_icon from '../../assets/ses_icon.png';


import svt_icon from '../../assets/svt_icon.png';
import informatique_icon from '../../assets/informatique_icon.png';
import physics_icon from '../../assets/physics_icon.png';
import engineer_icon from '../../assets/engineer_icon.png';
import media_icon from '../../assets/media_icon.png';
import dev_durable_icon from '../../assets/dev_durable_icon.png';
import tourisme_icon from '../../assets/tourisme_icon.png';
import langues_civilisation_icon from '../../assets/langues_civilisation_icon.png';
import humanité_icon from '../../assets/humanité_icon.png';
import history_icon from '../../assets/history_icon.png';
import archeology_icon from '../../assets/archeology_icon.png';
import psychology_icon from '../../assets/psychology_icon.png';
import politique_icon from '../../assets/politique_icon.png';
import international_icon from '../../assets/international_icon.png';
import sport_icon from '../../assets/sport_icon.png';

const Profile = () => {
  const navigate = useNavigate();
  const { user, loading, error } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPseudo, setNewPseudo] = useState('');
  const [messageStatus, setMessageStatus] = useState({ text: '', success: false });
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');


  const getFormationLabel = (type) => {
    const formationLabels = {
      'classe_preparatoire': 'Classe préparatoire',
      'universite_theorique': 'Université théorique',
      'grande_ecole_post_bac': 'Ecole post-bac',
      'classe_professionnalisante': 'Classe professionnalisante',
      'universite_professionnalisante': 'Université professionnalisante',
      'dont_know': 'Je ne suis pas sûr'
    };
    return formationLabels[type] || type;
  };

  const getBacLabel = (type) => {
    const bacLabels = {
      'general': 'Général',
      'technologique': 'Technologique',
      'professionnel': 'Professionnel'
    };
    return bacLabels[type] || type;
  };

  const getUserTypeLabel = (type) => {
    const userLabels = {
      'seconde': 'Seconde',
      'premiere': 'Première',
      'terminale': 'Terminale',
      'etudiant': 'Étudiant',
      'parent': 'Parent',
      'autre': 'Autre'
    };
    return userLabels[type] || type;
  };


  const getIconForType = (type, category) => {
    if (!type) return null;

    const iconMap = {
      formation: {
        'classe_preparatoire': classe_preparatoire_icon,
        'universite_theorique': universite_theorique_icon,
        'grande_ecole_post_bac': grande_ecole_post_bac_icon,
        'classe_professionnalisante': classe_professionnalisante_icon,
        'universite_professionnalisante': universite_professionnalisante_icon,
        'dont_know': dont_know_icon
      },
      bac: {
        'general': general_icon,
        'technologique': tech_icon,
        'professionnel': pro_icon
      },
      user: {
        'seconde': secondeIcon,
        'premiere': premiereIcon,
        'terminale': terminaleIcon,
        'etudiant': etudiantIcon,
        'parent': parentIcon,
        'autre': otherIcon
      },
      domain: {
        'sciences': sciences_icon,
        'lettres': lettres_sciences_humaines_icon,
        'eco': economie_icon,
        'art': arts_culture_icon,
        'sport': sante_sport_icon,
        'droit': droit_politique_icon,
      },
      speciality: {
        'arts': artsIcon,
        'eppcs': eppcsIcon,
        'hggsp': hggspIcon,
        'hlp': hlpIcon,
        'llcer': llcerIcon,
        'lca': lcaIcon,
        'math': mathIcon,
        'nsi': nsiIcon,
        'physchim': physChimIcon,
        'svt': svtIcon,
        'si': siIcon,
        'ses': sesIcon,
        'bioeco': bioEcoIcon
      }
    };
    return iconMap[category]?.[type];
  };

  const getSpecialityLabel = (type) => {
    const specialityLabels = {
      'arts': 'Arts',
      'eppcs': 'Éducation physique, pratiques et cultures sportives',
      'hggsp': 'Histoire-géographie, géopolitique et sciences politiques',
      'hlp': 'Humanités, littérature et philosophie',
      'llcer': 'Langues, littératures et cultures étrangères et régionales',
      'lca': "Littérature, langues et cultures de l'antiquité",
      'math': 'Mathématiques',
      'nsi': 'Numérique et sciences informatiques',
      'physchim': 'Physique-Chimie',
      'svt': 'Sciences de la vie et de la Terre',
      'si': "Sciences de l'ingénieur",
      'ses': 'Sciences économiques et sociales',
      'bioeco': 'Biologie-Ecologie'
    };
    return specialityLabels[type] || type;
  };

  const handleModify = (field) => {
    console.log('Field:', field);

    switch(field) {
      case 'pseudo':
        setNewPseudo(user.pseudo || '');
        setIsModalOpen(true);
        break;
      case 'type_formation':
        const formationTypes = normalizeFormationTypes(user.type_formation);
        if (formationTypes.length > 0) {
          navigate('/profile/modify/type-formation');
        } else {
          navigate('/quiz-type-formation', { state: { from: '/profile/modify/type-formation' } });
        }
        break;
      case 'domain':
        navigate('/profile/modify/subdomaine-formation');
        break;
      case 'type_bac':
        navigate('/profile/modify/type-bac');
        break;
      case 'user_type':
        navigate('/profile/modify/user-type');
        break;
      case 'email':
        setNewEmail(user.email || '');
        setIsEmailModalOpen(true);
        break;
      case 'specialities':
        navigate('/profile/modify/specialites');
        break;
      case 'user_city':
        navigate('/profile/modify/city');
        break;
      case 'swipe':
          navigate('/profile/modify/swipe');
          break;
      default:
        break;
    }
  };


  const handleUpdateEmail = async () => {
    if (!newEmail) {
      setMessageStatus({ text: 'Veuillez entrer un email', success: false });
      return;
    }

    try {
      // Note: You'll need to implement the actual email update endpoint
      // This endpoint would be different from the update_user_field endpoint
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/email/update/`, 'PATCH', {
        email: newEmail
      });

      user.email = newEmail;

      setIsEmailModalOpen(false);
      setNewEmail('');
      setMessageStatus({ text: 'Email mis à jour avec succès !', success: true });
    } catch (error) {
      console.error('Update error:', error);
      setMessageStatus({
        text: 'Erreur lors de la mise à jour de l\'email',
        success: false
      });
    }
  };

  const handleUpdatePseudo = async () => {
    if (!newPseudo) {
      setMessageStatus({ text: 'Veuillez entrer un pseudo', success: false });
      return;
    }

    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
        pseudo: newPseudo
      });

      user.pseudo = newPseudo;

      setIsModalOpen(false);
      setNewPseudo('');
      setMessageStatus({ text: 'Pseudo mis à jour avec succès !', success: true });
    } catch (error) {
      console.error('Update error:', error);
      setMessageStatus({
        text: 'Erreur lors de la mise à jour du pseudo',
        success: false
      });
    }
  };

  const handleSettingsClick = () => {
    navigate('/settings', { state: { from: '/chat' } });


  };

  const normalizeFormationTypes = (formationData) => {
    if (!formationData) return [];

    // If it's a string but looks like JSON array, try to parse it
    if (typeof formationData === 'string' && formationData.startsWith('[')) {
      try {
        return JSON.parse(formationData);
      } catch (e) {
        console.error('Error parsing formation data:', e);
        return [formationData];
      }
    }

    // If it's a regular string, make it an array
    if (typeof formationData === 'string') {
      return [formationData];
    }

    // If it's already an array, return it
    if (Array.isArray(formationData)) {
      return formationData;
    }

    return [];
  };

  const renderDomainContent = () => {
    if (!user.sub_domaine_formation) return null;
    const subDomains = parseSubDomains(user.sub_domaine_formation);
    if (!subDomains.length) return null;

    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {subDomains.map((subDomain, index) => {
          const details = getSubDomainDetails(subDomain);
          return (
            <IconLabelPair key={index}>
              <CardIcon>
                <img
                  src={details.icon}
                  alt={details.label}
                  className="w-full h-full object-contain"
                />
              </CardIcon>
              <UserInfoValue>{details.label}</UserInfoValue>
            </IconLabelPair>
          );
        })}
      </div>
    );
  };

  const renderSpecialitiesContent = () => {
    if (!user.specialites?.selected?.length) return null;
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {user.specialites.selected.map((speciality, index) => (
          <IconLabelPair key={index}>
            <CardIcon>
              <img
                src={getIconForType(speciality, 'speciality')}
                alt={getSpecialityLabel(speciality)}
                className="w-full h-full object-contain"
              />
            </CardIcon>
            <UserInfoValue>{getSpecialityLabel(speciality)}</UserInfoValue>
          </IconLabelPair>
        ))}
      </div>
    );
  };



const parseSubDomains = (value) => {
  // If it's already an array, return it
  if (Array.isArray(value)) {
    return value;
  }

  // If it's a string that looks like JSON array, try to parse it
  if (typeof value === 'string' && value.startsWith('[')) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error('Error parsing JSON string:', e);
    }
  }

  // If it's a comma-separated string, split it
  if (typeof value === 'string' && value.includes(',')) {
    return value.split(',').map(item => item.trim());
  }

  // If it's a single string value, wrap it in array
  if (typeof value === 'string') {
    return [value];
  }

  // Default to empty array if none of the above work
  return [];
};

const getSubDomainDetails = (subDomain) => {
  console.log('Getting details for subdomain:', subDomain); // Debug
  const subDomainDetails = {
    'Arts et Culture': arts_culture_icon,
    'Communication et Information': media_icon,
    'Sciences Interdisciplinaires': sciences_icon,
    'Lettres et Sciences Humaines': lettres_sciences_humaines_icon,
    'Humanités': humanité_icon,
    'Histoire': history_icon,
    'Langues et Civilisations': langues_civilisation_icon,
    'Sports et Activités Physiques': sport_icon,
    'Santé et Sciences Biomédicales': sante_sport_icon,
    'Sciences de la Vie et de la Terre': svt_icon,
    'Développement Durable': dev_durable_icon,
    'Sciences Politiques et Sociales': politique_icon,
    'Études Internationales': international_icon,
    'Sciences Économiques et Commerciales': ses_icon,
    'Archéologie': archeology_icon,
    'Sciences Mathématiques et Informatiques': informatique_icon,
    'Sciences Physiques et Chimiques': physics_icon,
    "Sciences de l'Ingénieur": engineer_icon,
    'Psychologie': psychology_icon,
    'Théologie': sciences_icon,
    'Tourisme': tourisme_icon
  };
  
  return subDomainDetails[subDomain] || { label: subDomain, icon: sciences_icon };
};

const getSubDomainLabel = (subDomain) => {
  return getSubDomainDetails(subDomain).label;
};

const renderDomainIcons = (subDomainData) => {
  const subDomains = parseSubDomains(subDomainData);
  console.log('Parsed subDomains:', subDomains); // Debug

  if (!subDomains.length) return null;

  return (
    <div className="flex gap-2">
      {subDomains.map((subDomain, index) => {
        const details = getSubDomainDetails(subDomain);
        console.log('Details for', subDomain, ':', details); // Debug

        return (
          <CardIcon key={index} className="w-8 h-8">
            <img
              src={details.icon}
              alt={details.label}
              className="w-full h-full object-contain"
              onError={(e) => console.error('Failed to load icon:', e.target.src)}
            />
          </CardIcon>
        );
      })}
    </div>
  );
};

const renderDomainLabels = (subDomainData) => {
  const subDomains = parseSubDomains(subDomainData);
  return subDomains.map(subDomain => getSubDomainLabel(subDomain)).join(', ') || 'Non spécifié';
};

  if (loading) {
    return (
      <Container>
        <ContentWrapper>
          <MessageStatus>Chargement...</MessageStatus>
        </ContentWrapper>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ContentWrapper>
          <MessageStatus error>{error}</MessageStatus>
        </ContentWrapper>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container>
        <ContentWrapper>
          <MessageStatus>Aucun utilisateur connecté.</MessageStatus>
        </ContentWrapper>
      </Container>
    );
  }

  return (
    <Container>
      {/* Settings button at top-right */}
      <SettingsButton onClick={handleSettingsClick}>
        <SettingsIcon />
      </SettingsButton>

      {/* Header with Compass Logo */}
      <Header>
        <LogoImage src={compassImage} alt="Logo" />
      </Header>

      <ContentWrapper>
        {/* 1. TYPE DE FORMATION */}
        <ProfileCard 
  variant="formation"
  onClick={() => handleModify('swipe')}
>
  <CardHeader>
   <CardTitle>Swipe</CardTitle>
  </CardHeader>
  <CardContent>
   <CardIcon>
     <img 
       src={questions_icon} 
       alt="Voir mes réponses" 
       style={{ width: '40px', height: '40px' }}
     />
   </CardIcon>
   <UserInfoValue>Voir mes réponses</UserInfoValue>
  </CardContent>
</ProfileCard>


        {/* 2. SPÉCIALITÉS (only if bac === 'general') */}
        {user.type_bac === 'general' && (
          <ProfileCard
            variant="specialities"
            onClick={() => handleModify('specialities')}
          >
            <CardHeader>
              <CardTitle>Spécialités</CardTitle>
            </CardHeader>
            <CardContent>
              {renderSpecialitiesContent()}
            </CardContent>
          </ProfileCard>
        )}

        {/* 3. DOMAINES */}
        <ProfileCard
          variant="domain"
          onClick={() => handleModify('domain')}
        >
          <CardHeader>
            <CardTitle>Domaines</CardTitle>
          </CardHeader>
          <CardContent>
            {renderDomainContent()}
          </CardContent>
        </ProfileCard>

        {/* 4. TYPE DE BAC */}
        <ProfileCard
          variant="bac"
          onClick={() => handleModify('type_bac')}
        >
          <CardHeader>
            <CardTitle>Type de bac</CardTitle>
          </CardHeader>
          <CardContent>
            {user.type_bac && (
              <CardIcon>
                <img
                  src={getIconForType(user.type_bac, 'bac')}
                  alt={getBacLabel(user.type_bac)}
                />
              </CardIcon>
            )}
            <UserInfoValue>
              {user.type_bac ? getBacLabel(user.type_bac) : 'Non spécifié'}
            </UserInfoValue>
          </CardContent>
        </ProfileCard>

        {/* 5. TYPE D'UTILISATEUR */}
        <ProfileCard
          variant="user"
          onClick={() => handleModify('user_type')}
        >
          <CardHeader>
            <CardTitle>Type d'utilisateur</CardTitle>
          </CardHeader>
          <CardContent>
            {user.user_type && (
              <CardIcon>
                <img
                  src={getIconForType(user.user_type, 'user')}
                  alt={getUserTypeLabel(user.user_type)}
                />
              </CardIcon>
            )}
            <UserInfoValue>
              {user.user_type ? getUserTypeLabel(user.user_type) : 'Non spécifié'}
            </UserInfoValue>
          </CardContent>
        </ProfileCard>

        {/* 6. INFORMATIONS PERSONNELLES (multiple fields => each row clickable) */}
        <ProfileCard variant="info">
          <CardHeader>
            <CardTitle>Informations personnelles</CardTitle>
</CardHeader>
          <CardContent>
            <div className="flex flex-col w-full gap-4">
              {/* Pseudo field */}
              <div
                className="flex items-center w-full cursor-pointer"
                onClick={() => handleModify('pseudo')}
              >
                <UserInfoLabel>Pseudo</UserInfoLabel>
                <div className="flex items-center justify-between flex-1">
                  <UserInfoValue>{user.pseudo || 'Non spécifié'}</UserInfoValue>
                  <ModifyButton>
                    <FaEdit />
                  </ModifyButton>
                </div>
              </div>

              {/* Email field */}
              <div
                className="flex items-center w-full cursor-pointer"
                onClick={() => handleModify('email')}
              >
                <UserInfoLabel>Email</UserInfoLabel>
                <div className="flex items-center justify-between flex-1">
                  <UserInfoValue className="email-blur">
                    {user.email || 'Non spécifié'}
                  </UserInfoValue>
                  <ModifyButton>
                    <FaEdit />
                  </ModifyButton>
                </div>
              </div>

              {/* City field */}
              <div
                className="flex items-center w-full cursor-pointer"
                onClick={() => handleModify('user_city')}
              >
                <UserInfoLabel>Ville</UserInfoLabel>
                <div className="flex items-center justify-between flex-1">
                  <UserInfoValue>{user.user_city || 'Non spécifié'}</UserInfoValue>
                  <ModifyButton>
                    <FaEdit />
                  </ModifyButton>
                </div>
              </div>
            </div>
          </CardContent>
        </ProfileCard>
      </ContentWrapper>

      {/* -- MODALS BELOW (unchanged) -- */}
      <ModalOverlay isOpen={isModalOpen}>
        <ModalContent>
          <h2>Modifie ton pseudo</h2>
          <Input
            type="text"
            value={newPseudo}
            onChange={(e) => setNewPseudo(e.target.value)}
            placeholder="Nouveau pseudo"
          />
          <ModalButtons>
            <CancelButton onClick={() => setIsModalOpen(false)}>
              Annuler
            </CancelButton>
            <ConfirmButton onClick={handleUpdatePseudo}>
              Confirmer
            </ConfirmButton>
          </ModalButtons>
        </ModalContent>
      </ModalOverlay>

      <ModalOverlay isOpen={isEmailModalOpen}>
        <ModalContent>
          <h2>Modifie ton email</h2>
          <Input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Nouveau email"
          />
          <ModalButtons>
            <CancelButton onClick={() => setIsEmailModalOpen(false)}>
              Annuler
            </CancelButton>
            <ConfirmButton onClick={handleUpdateEmail}>
              Confirmer
            </ConfirmButton>
          </ModalButtons>
        </ModalContent>
      </ModalOverlay>
    </Container>
  );
};

export default Profile;