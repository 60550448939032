import apiCall from '../../utils/api';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import {
  PrimaryButton
} from '../SecundPage/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';
import secondeIcon from '../../assets/seconde-icon.png';
import premiereIcon from '../../assets/premiere-icon.png';
import terminaleIcon from '../../assets/terminale-icon.png';
import etudiantIcon from '../../assets/students-icon.png';
import parentIcon from '../../assets/family-icon.png';
import otherIcon from '../../assets/other-icon.png';
import useDeviceType from '../useDeviceType';

const UserTypeSelectionProfile = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "En quelle classe es-tu ?";
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const typeOptions = [
    {
      value: 'seconde',
      label: 'Seconde',
      icon: secondeIcon,
      disabled: false
    },
    {
      value: 'premiere',
      label: 'Première',
      icon: premiereIcon,
      disabled: false
    },
    {
      value: 'terminale',
      label: 'Terminale',
      icon: terminaleIcon,
      disabled: false
    },
    {
      value: 'etudiant',
      label: 'Étudiant - bientôt disponible',
      icon: etudiantIcon,
      disabled: true
    },
    {
      value: 'parent',
      label: "Parent - bientôt disponible",
      icon: parentIcon,
      disabled: true
    },
    {
      value: 'autre',
      label: 'Autre - bientôt disponible',
      icon: otherIcon,
      disabled: true
    }
  ];

  const handleOptionClick = (option) => {
    if (!option.disabled) {
      setSelectedType(option.value);
    }
  };

  const handleSubmit = async () => {
    if (!selectedType || isTyping) return;
    setIsLoading(true);
    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
        user_type: selectedType
      });
      // localStorage.setItem('type_bac', selectedType);
      // Add conditional navigation based on selectedType
      
      navigate('/profile');
      
    } catch (error) {
      console.error('Error saving user type to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleBack = () => {
    navigate('/profile');
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>

        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper style={{
                    opacity: isTyping ? 0 : 1,
                    transition: 'opacity 0.3s ease',
                    pointerEvents: isTyping ? 'none' : 'auto'
                  }}>
                    {typeOptions.map((option) => (
                      <OptionButton
                        key={option.value}
                        $isSelected={selectedType === option.value}
                        $disabled={option.disabled}
                        onClick={() => handleOptionClick(option)}
                        type="button"
                        aria-pressed={selectedType === option.value}
                        aria-disabled={option.disabled}
                      >
                        <OptionIcon
                          src={option.icon}
                          alt=""
                          $isSelected={selectedType === option.value}
                          style={{ opacity: option.disabled ? 0.5 : 1 }}
                        />
                        {option.label}
                      </OptionButton>
                    ))}
                  </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || !selectedType}
          type="button"
          style={{
            opacity: (isTyping || !selectedType) ? 0.5 : 1,
            cursor: (isTyping || !selectedType) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Modifier'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default UserTypeSelectionProfile;