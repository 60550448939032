// src/utils/frontendLogger.js

import axios from 'axios';

// Replace with your actual frontend log endpoint
const FRONTEND_LOG_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/auth/frontend-log/`;

/**
 * Sends a log message to the backend logging endpoint.
 *
 * @param {string} level - The severity level of the log (e.g., DEBUG, INFO, WARNING, ERROR, CRITICAL).
 * @param {string} message - The log message.
 * @param {object} [context={}] - Additional context or data related to the log.
 */
export const sendFrontendLog = async (level, message, context = {}) => {
  try {
    await axios.post(FRONTEND_LOG_ENDPOINT, {
      level,
      message,
      context,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Failed to send frontend log:', error);
    // Optionally, handle logging failures (e.g., retry logic, local storage)
  }
};
