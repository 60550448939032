
import React from 'react';
import { MenuButton as StyledMenuButton } from './styles';
export * from "./styles";

const MenuButton = ({ onClick, children, ...props }) => {
  return (
    <StyledMenuButton onClick={onClick} {...props}>
      {children}
    </StyledMenuButton>
  );
};

export { MenuButton };