import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import {
  PrimaryButton
} from '../SecundPage/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';
import sciences_icon from '../../assets/sciences_icon.png';
import lettres_sciences_humaines_icon from '../../assets/L_icon.png';
import economie_icon from '../../assets/ES_icon.png';
import arts_culture_icon from '../../assets/arts_culture_icon.png';
import droit_politique_icon from '../../assets/droit_politique_icon.png';
import sante_sport_icon from '../../assets/sante_sport_icon.png';

import dont_know from '../../assets/dont_know_icon.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';


const DomaineFormation = () => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "As-tu une idée des domaines qui t'intéresse ?";
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const typeOptions = [
    {
      value: 'theorique_sciences',
      label: 'Sciences',
      icon: sciences_icon
    },
    {
      value: 'theorique_arts_cultures',
      label: 'Arts & Culture',
      icon: arts_culture_icon
    },
    {
      value: 'theorique_economie',
      label: 'Economie',
      icon: economie_icon
    },
    {
      value: 'theorique_lettres_sciences_humaines',
      label: 'Lettres & Sciences Humaines',
      icon: lettres_sciences_humaines_icon
    },
    {
      value: 'theorique_droit_politique',
      label: 'Droit & Politique',
      icon: droit_politique_icon
    },
    {
      value: 'theorique_sante_sport',
      label: 'Santé & Sport',
      icon: sante_sport_icon
    }
  ];

  // Modify the handleSubmit function
const handleSubmit = () => {
  if (selectedTypes.length === 0 || isTyping) return;
  setIsLoading(true);
  try {
    localStorage.setItem('domaine_formation', JSON.stringify(selectedTypes));
    navigate('/subdomaine-formation');
  } catch (error) {
    console.error('Error saving user types to localStorage:', error);
  } finally {
    setIsLoading(false);
  }
};


// Create a new function to handle multiple selections
const handleSelection = (value) => {
  setSelectedTypes(prev => {
    if (prev.includes(value)) {
      // If value is already selected, remove it
      return prev.filter(type => type !== value);
    } else {
      // If value is not selected, add it
      // Special handling for "no_type" option
      if (value === 'no_type') {
        // If selecting "no_type", clear all other selections
        return ['no_type'];
      } else {
        // If selecting any other option, remove "no_type" if it exists
        const newTypes = prev.filter(type => type !== 'no_type');
        return [...newTypes, value];
      }
    }
  });
};

  const handleBack = () => {
    navigate('/user-type-selection');
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      <ProgressBar 
        startPercent={
          (() => {
            try {
              const typeBac = localStorage.getItem('type_bac');
              return (typeBac === 'technologique' || typeBac === 'professionnel') ? 30 : 55;
            } catch (error) {
              console.error('Error accessing localStorage:', error);
              return 55; // Default value if localStorage is not accessible
            }
          })()
        } 
        endPercent={65} 
      />

        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {typeOptions.map((option) => (
              <OptionButton
                key={option.value}
                $isSelected={selectedTypes.includes(option.value)}
                onClick={() => handleSelection(option.value)}
                type="button"
                aria-pressed={selectedTypes.includes(option.value)}
              >
                <OptionIcon
                  src={option.icon}
                  alt=""
                  $isSelected={selectedTypes.includes(option.value)}
                />
                {option.label}
              </OptionButton>
            ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || selectedTypes.length === 0}
          type="button"
          style={{
            opacity: (isTyping || selectedTypes.length === 0) ? 0.5 : 1,
            cursor: (isTyping || selectedTypes.length === 0) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default DomaineFormation;