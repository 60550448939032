import React, { useState, useEffect, useContext } from 'react';
import { ThumbsUp } from 'lucide-react';
import { ThemeContext } from 'styled-components';
import apiCall from '../../utils/api';
import {
  FeatureContainer,
  ErrorMessage,
  FeatureList,
  FeatureCard,
  FeatureDescription,
  VoteButton,
  LoadingContainer,
  VoteCount,
  FeatureTitle
} from './styles';

const FeatureVoting = () => {
  const [features, setFeatures] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [votingInProgress, setVotingInProgress] = useState(false);
  const theme = useContext(ThemeContext);

  const fetchFeatures = async () => {
    try {
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/feature-voting/features/`,
        'GET'
      );
      setFeatures(response.features);
    } catch (err) {
      setError('Erreur lors du chargement des fonctionnalités');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  const handleVote = async (featureId) => {
    if (votingInProgress) return;

    setVotingInProgress(true);
    setError('');

    try {
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/feature-voting/features/vote/`,
        'POST',
        { feature_id: featureId }
      );

      // Immediately update the UI with the new state
      setFeatures(features.map(feature =>
        feature.id === featureId
          ? {
              ...feature,
              vote_count: response.feature.vote_count,
              has_voted: !feature.has_voted // Toggle the vote state
            }
          : feature
      ));

    } catch (err) {
      setError('Erreur lors du vote');
    } finally {
      setVotingInProgress(false);
    }
  };

  if (loading) {
    return <LoadingContainer>Chargement...</LoadingContainer>;
  }

  return (
    <FeatureContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <FeatureList>
        {features.map((feature) => (
          <FeatureCard key={feature.id}>
            <div>
              <FeatureTitle>{feature.name}</FeatureTitle>
            </div>
            <VoteButton
              onClick={() => handleVote(feature.id)}
              disabled={votingInProgress || feature.is_implemented}
              hasVoted={feature.has_voted}
              isImplemented={feature.is_implemented}
              title={feature.has_voted ? "Cliquez pour retirer votre vote" : "Cliquez pour voter"}
            >
              {feature.is_implemented ? (
                'Implémentée'
              ) : (
                <>
                  <ThumbsUp
                    size={16}
                    color={feature.has_voted ? theme.colors.vert : theme.colors.gris}
                  />
                  <VoteCount>{feature.vote_count}</VoteCount>
                </>
              )}
            </VoteButton>
          </FeatureCard>
        ))}
      </FeatureList>
    </FeatureContainer>
  );
};

export default FeatureVoting;