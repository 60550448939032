import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSpring, animated, config, to } from '@react-spring/web';
import { useNavigate, useLocation } from 'react-router-dom';
import Meo from '../../assets/meo_quiz.png';
import {
  QuizContainer,
  BackgroundBall,
  CardWrapper,
  QuestionCard,
  QuestionText,
  StyledIcon,
  AnswerOptionsContainer,
  AnswerButton,
  QuizMessage,
  StyledThumbsUp,
  StyledThumbsDown,
  LogoImage,
  QuizTypeIndicator,
  ProgressContainer,
  QuizNumber,
  ProgressBar,
  Progress,
  ResultButton,
  StyledRanking,
  QuizCompletionMessage,
  BrandContainer,
  BrandImage,
  BrandSubtitle,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  CategoryContainer,
  CategoryIcon,
  categoryIcons,
  CategoryText,
} from './styles';

import {
  MdOutlineSwipeUp,
  MdOutlineSwipeDown,
  MdOutlineSwipeLeft,
  MdOutlineSwipeRight
} from 'react-icons/md';

import { Clock, BookOpen, Briefcase, Globe, Brain, Calendar, Target } from 'lucide-react';

import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';
import { useQuiz } from '../../contexts/QuizContext';
import questionIcon from '../../assets/doubt.png';
import heartIcon from '../../assets/jadore.png';



const SWIPE_THRESHOLD = 80;
const ANSWER_MAPPING = {
  1: 'Non',
  2: 'Je ne sais pas',
  3: 'Oui',
  4: "J'adore"
};


const QuizTypeFormation = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const [showDirectionArrows, setShowDirectionArrows] = useState(true);
  const [clickedButton, setClickedButton] = useState(null);
  const clickTimeoutRef = useRef(null);
  const hasSwipedRef = useRef(false);
  const location = useLocation();
  const fromState = location.state?.from;

  const {
    currentQuestion,
    setCurrentQuestion,
    quizProgress,
    setQuizProgress,
    setQuizResult,
    isQuizInitialized,
    setIsQuizInitialized,
    setQuizId,
    quizCompleted,
    setQuizCompleted,
  } = useQuiz();

  // Calculate progress percentage
  const progressPercent = quizProgress.quiz1_total > 0
    ? (quizProgress.quiz1_progress / quizProgress.quiz1_total) * 100
    : 0;

  const [{ x, y, rotate }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    config: { tension: 300, friction: 20 }
  }));

  const cardRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });
  // Juste avant ou après `const [clickedButton, setClickedButton] = useState(null);`
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [finalSwipedButton, setFinalSwipedButton] = useState(null);

  const fetchNextQuestion = useCallback(async () => {
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/question-type-formation/`, 'GET');

      if (response.message === 'No more questions available') {
        setQuizCompleted(true);
        return;
      }

      setCurrentQuestion({
        id: response.question_id,
        text: response.question_text,
        type: response.question_type,
        category: response.question_category  // Add this line
      });
      setQuizProgress(response.progress);
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  }, [setCurrentQuestion, setQuizCompleted, setQuizProgress]);

  const startQuiz = useCallback(async () => {
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/start/`, 'POST');
      setQuizId(response.quiz_id);
      setQuizProgress(response.progress);
      await fetchNextQuestion();
      setIsQuizInitialized(true);
    } catch (error) {
      console.error('Error starting quiz:', error);
    }
  }, [setQuizId, setIsQuizInitialized, setQuizProgress, fetchNextQuestion]);


  const animateCard = (direction) => {
    const distance = window.innerWidth + 200;
    const verticalDistance = window.innerHeight + 200;

    switch (direction) {
      case 'up':
        api.start({
          y: -verticalDistance,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'down':
        api.start({
          y: verticalDistance,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'left':
        api.start({
          x: -distance,
          rotate: -45,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'right':
        api.start({
          x: distance,
          rotate: 45,
          config: { tension: 200, friction: 20 }
        });
        break;
      default:
        api.start({
          x: 0,
          y: 0,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY };
  };

  const handleTouchMove = (e) => {
    if (!cardRef.current) return;

    const touch = e.touches[0];
    const currentX = touch.clientX;
    const currentY = touch.clientY;

    // On calcule deltaX, deltaY pour déplacer/rotater la carte comme avant
    const deltaX = currentX - touchStartRef.current.x;
    const deltaY = currentY - touchStartRef.current.y;
    const rotation = deltaX / 15;

    // Animation de la carte
    api.start({
      x: deltaX,
      y: deltaY,
      rotate: rotation,
      immediate: true
    });

    // Calcul de la fraction d'écran où se situe le doigt
    const fractionX = currentX / window.innerWidth;   // entre 0 et 1
    const fractionY = currentY / window.innerHeight;  // entre 0 et 1

    let direction = null;

    // Tiers gauche
    if (fractionX < 0.38) {
      direction = 'left';
    }
    // Tiers droit
    else if (fractionX > 0.62) {
      direction = 'right';
    }
    // Tiers haut
    else if (fractionY < 0.38) {
      direction = 'up';
    }
    // Tiers bas
    else if (fractionY > 0.62) {
      direction = 'down';
    }

    // S’il n’est dans aucun de ces cas, direction reste null = pas de highlight

    setSwipeDirection(direction);
  };



  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const deltaX = touch.clientX - touchStartRef.current.x;
    const deltaY = touch.clientY - touchStartRef.current.y;

    if (Math.abs(deltaX) > SWIPE_THRESHOLD || Math.abs(deltaY) > SWIPE_THRESHOLD) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 0) {
          api.start({
            x: window.innerWidth + 200,
            y: 0,
            rotate: 45,
            config: { tension: 200, friction: 20 }
          });
          handleAnswer(3, 'right');
          setFinalSwipedButton('right');
        } else {
          api.start({
            x: -window.innerWidth - 200,
            y: 0,
            rotate: -45,
            config: { tension: 200, friction: 20 }
          });
          handleAnswer(1, 'left');
          setFinalSwipedButton('left');
        }
      } else {
        if (deltaY > 0) {
          api.start({
            x: 0,
            y: window.innerHeight + 200,
            rotate: 0,
            config: { tension: 200, friction: 20 }
          });
          handleAnswer(2, 'down');
          setFinalSwipedButton('down');
        } else {
          api.start({
            x: 0,
            y: -window.innerHeight - 200,
            rotate: 0,
            config: { tension: 200, friction: 20 }
          });
          handleAnswer(4, 'up');
          setFinalSwipedButton('up');
        }
      }
      setTimeout(() => {
        setFinalSwipedButton(null);
      }, 600);
    } else {
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        config: { tension: 200, friction: 20 }
      });
    }
    setSwipeDirection(null);

  };

  const DirectionArrows = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
      <>
        <ArrowUp>
          <div className="flex flex-col items-center">
            <StyledIcon src={heartIcon} alt="Heart" fill="#FF2768" className="mb-2" />
            <MdOutlineSwipeUp size={32} />
          </div>
        </ArrowUp>
        <ArrowDown>
          <div className="flex flex-col items-center">
            <MdOutlineSwipeDown size={32} />
            <StyledIcon src={questionIcon} alt="Question" fill="yellow" className="mt-2" />
          </div>
        </ArrowDown>
        <ArrowLeft>
          <div className="flex items-center">
            <StyledThumbsDown fill="red" className="mr-2" />
            <MdOutlineSwipeLeft size={32} />
          </div>
        </ArrowLeft>
        <ArrowRight>
          <div className="flex items-center">
            <MdOutlineSwipeRight size={32} />
            <StyledThumbsUp fill="#0BDB22" className="ml-2" />
          </div>
        </ArrowRight>
      </>
    );
  };

  const handleAnswer = async (answer, direction, buttonId) => {
    if (!currentQuestion) return;

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    setClickedButton(buttonId);

    clickTimeoutRef.current = setTimeout(() => {
      setClickedButton(null);
      clickTimeoutRef.current = null;
    }, 1000);

    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/answer/`, 'POST', {
        question_id: currentQuestion.id,
        question_type: currentQuestion.type,
        answer: answer
      });

      animateCard(direction);
      await new Promise(resolve => setTimeout(resolve, 300));
      setQuizProgress(response.progress);

      if (!hasSwipedRef.current) {
        setShowDirectionArrows(false);
        hasSwipedRef.current = true;
      }

      if (response.rankings) {
        setQuizResult(response.rankings);
      }

      if (response.progress.quiz1_progress > response.progress.quiz1_total) {
        setQuizCompleted(true);
        navigate('/classement');
      } else {
        await fetchNextQuestion();
        api.start({
          x: 0,
          y: 0,
          rotate: 0,
          immediate: true
        });
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        config: config.wobbly
      });

      setClickedButton(null);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
      }
    }
  };

  const resetQuiz = useCallback(async () => {
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`, 'POST');
      setQuizId(response.quiz_id);
      setQuizProgress(response.progress);
      setQuizCompleted(false);
      setIsQuizInitialized(false);
      await fetchNextQuestion();
      setIsQuizInitialized(true);
    } catch (error) {
      console.error('Error resetting quiz:', error);
    }
  }, [setQuizId, setQuizProgress, setQuizCompleted, setIsQuizInitialized, fetchNextQuestion]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log("From state:", fromState);
      console.log("Path:", window.location.pathname);

      if (fromState === '/profile/modify/swipe') {
        resetQuiz();
      } else if (!isQuizInitialized) {
        startQuiz();
      }
    }
  }, [isAuthenticated, fromState, resetQuiz, startQuiz]);

  useEffect(() => {
    if (isAuthenticated) {
      if (fromState === '/profile/modify/swipe') {
        // Clear the location state immediately after resetting the quiz
        resetQuiz().then(() => {
          // Replace the current history state with a clean one
          navigate(location.pathname, { replace: true, state: {} });
        });
      } else if (!isQuizInitialized) {
        startQuiz();
      }
    }
  }, [isAuthenticated, fromState, resetQuiz, startQuiz]);

  useEffect(() => {
    return () => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  if (!isAuthenticated) {
    return <QuizMessage>Connecte-toi pour accéder au quiz !</QuizMessage>;
  }

  if (!currentQuestion && !quizCompleted) {
    return <QuizMessage>Chargement de la question...</QuizMessage>;
  }


  if (
    quizProgress.quiz1_progress === quizProgress.quiz1_total) {
    console.log("Navigation state:", location.state); // For debugging
    console.log("quizProgress:", quizProgress); 
    if (location.state?.from === '/profile/modify/swipe' ||
      quizProgress.quiz1_total === 0
    ) {
      console.log("location.state?.from :", location.state?.from)
      const resetQuiz = async () => {
        try {
          const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`, 'POST');
          setQuizId(response.quiz_id);
          setQuizProgress(response.progress);
          setQuizCompleted(false); // You can keep this flag if needed
          setIsQuizInitialized(false);
          await fetchNextQuestion();
          setIsQuizInitialized(true);
        } catch (error) {
          console.error('Error resetting quiz:', error);
        }
      };
      resetQuiz();
      return null;
    }

    navigate('/classement');
    return null;
  }


  return (
    <QuizContainer isMobile={isMobile}>

      <BrandContainer>
        <BrandImage src={Meo} alt="Meoria Logo" />
        <BrandSubtitle>
          Swipe pour accéder à tes premiers résultats !
        </BrandSubtitle>
      </BrandContainer>

      <BackgroundBall isMobile={isMobile}/>

      <QuizTypeIndicator>
        <ProgressContainer>
          <QuizNumber>Type de Formation</QuizNumber>
          <ProgressBar>
            <Progress
              $progress={progressPercent}
              $isQuiz1={true}
            />
          </ProgressBar>
        </ProgressContainer>
      </QuizTypeIndicator>

      <CardWrapper isMobile={isMobile}>
        <animated.div
          ref={cardRef}
          style={{
            touchAction: 'none',
            transform: to(
              [x, y, rotate],
              (x, y, r) => `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`
            )
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
      <QuestionCard isMobile={isMobile} category={currentQuestion.category}>
        <DirectionArrows isVisible={showDirectionArrows} />
        <CategoryContainer>
          <CategoryIcon category={currentQuestion.category}>
            {React.createElement(categoryIcons[currentQuestion.category] || categoryIcons['Charge de travail'])}
          </CategoryIcon>
          <CategoryText category={currentQuestion.category}>
            {currentQuestion.category}
          </CategoryText>
        </CategoryContainer>
        <QuestionText>{currentQuestion.text}</QuestionText>
      </QuestionCard>
        </animated.div>
      </CardWrapper>

      <AnswerOptionsContainer isMobile={isMobile}>
      <AnswerButton
        clicked={clickedButton === 'down'}
        highlight={swipeDirection === 'down'}  // <--- Ajout
        finalSwiped={finalSwipedButton === 'down'}
        onClick={() => handleAnswer(2, 'down', 'down')}
        aria-label={ANSWER_MAPPING[2]}
      >
        <StyledIcon src={questionIcon} alt="Question" fill="yellow"/>
      </AnswerButton>

      <AnswerButton
        clicked={clickedButton === 'left'}
        highlight={swipeDirection === 'left'}  // <--- Ajout
        finalSwiped={finalSwipedButton === 'left'}
        onClick={() => handleAnswer(1, 'left', 'left')}
        aria-label={ANSWER_MAPPING[1]}
      >
        <StyledThumbsDown fill="red"/>
      </AnswerButton>

      <AnswerButton
        clicked={clickedButton === 'right'}
        highlight={swipeDirection === 'right'}  // <--- Ajout
        finalSwiped={finalSwipedButton === 'right'}
        onClick={() => handleAnswer(3, 'right', 'right')}
        aria-label={ANSWER_MAPPING[3]}
      >
        <StyledThumbsUp fill="#0BDB22" />
      </AnswerButton>

      <AnswerButton
        clicked={clickedButton === 'up'}
        highlight={swipeDirection === 'up'}  // <--- Ajout
        finalSwiped={finalSwipedButton === 'up'}
        onClick={() => handleAnswer(4, 'up', 'up')}
        aria-label={ANSWER_MAPPING[4]}
      >
        <StyledIcon src={heartIcon} alt="Heart" fill="#FF2768" />
      </AnswerButton>

      </AnswerOptionsContainer>
    </QuizContainer>
  );
};

export default QuizTypeFormation;