// Share_styles.js

// Theme colors and variables
export const theme = {
  colors: {
    primary: '#007FFF',       // Bright blue
    secondary: '#6c757d',     // Gray
    accent: '#FFD700',        // Gold
    background: '#ffffff',     // White
    background_fonce: '#f8f9fa', // Light gray
    text_primary: '#1a1a1a',  // Almost black
    text_secondary: '#4a4a4a', // Dark gray
    text_light: '#6c757d',    // Medium gray
    border: '#e9ecef',        // Light border color
  },
  fonts: {
    primary: 'Arial, sans-serif',
    heading: 'Arial, sans-serif',
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, 0.1)',
    medium: '0 4px 6px rgba(0, 0, 0, 0.1)',
    large: '0 8px 16px rgba(0, 0, 0, 0.1)',
  }
};

// Share button styles
export const shareButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '10px 20px',
  backgroundColor: theme.colors.primary,
  color: theme.colors.background,
  border: 'none',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: '600',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  boxShadow: theme.shadows.small,
  ':hover': {
    backgroundColor: '#0056b3', // Darker shade of primary
    transform: 'translateY(-1px)',
    boxShadow: theme.shadows.medium,
  },
  ':active': {
    transform: 'translateY(0)',
    boxShadow: theme.shadows.small,
  },
};

// Container styles for the generated image
export const imageContainerStyle = {
  backgroundColor: theme.colors.background,
  borderRadius: '16px',
  padding: '40px',
  boxShadow: theme.shadows.large,
};

// Medal colors for rankings
export const medalColors = {
  gold: '#FFD700',
  silver: '#C0C0C0',
  bronze: '#CD7F32',
};

// Text styles for different elements
export const textStyles = {
  title: {
    fontSize: '64px',
    fontWeight: 'bold',
    color: theme.colors.text_primary,
    textAlign: 'center',
    marginBottom: '40px',
  },
  schoolName: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: theme.colors.text_primary,
    marginBottom: '8px',
  },
  formation: {
    fontSize: '24px',
    color: theme.colors.text_secondary,
    marginBottom: '4px',
  },
  city: {
    fontSize: '24px',
    color: theme.colors.text_light,
  },
  footer: {
    fontSize: '28px',
    color: theme.colors.text_light,
    textAlign: 'center',
    marginTop: '40px',
  },
};

// Canvas-specific styles for the image generation
export const canvasStyles = {
  dimensions: {
    width: 1200,
    height: 1500,
  },
  padding: {
    top: 50,
    bottom: 100,
    horizontal: 100,
  },
  spacing: {
    betweenSchools: 320,
    headerToContent: 400,
  },
  containers: {
    school: {
      height: 280,
      radius: 16,
      shadow: {
        color: 'rgba(0, 0, 0, 0.1)',
        blur: 10,
        offsetY: 4,
      },
    },
    rankCircle: {
      radius: 30,
      offset: {
        x: 60,
        y: 60,
      },
    },
  },
};

// Gradient styles for backgrounds
export const gradients = {
  background: {
    startColor: theme.colors.background,
    endColor: theme.colors.background_fonce,
  },
  button: {
    startColor: theme.colors.primary,
    endColor: '#0056b3',
  },
};

// Animation styles
export const animations = {
  transition: 'all 0.2s ease-in-out',
  hover: {
    transform: 'translateY(-1px)',
    shadow: theme.shadows.medium,
  },
  active: {
    transform: 'translateY(0)',
    shadow: theme.shadows.small,
  },
};

// Media query breakpoints
export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
  wide: '1200px',
};

// Responsive styles
export const responsive = {
  mobile: `@media (max-width: ${breakpoints.mobile})`,
  tablet: `@media (max-width: ${breakpoints.tablet})`,
  desktop: `@media (max-width: ${breakpoints.desktop})`,
  wide: `@media (max-width: ${breakpoints.wide})`,
};

// Export default styles object
export default {
  theme,
  shareButtonStyle,
  imageContainerStyle,
  medalColors,
  textStyles,
  canvasStyles,
  gradients,
  animations,
  breakpoints,
  responsive,
};