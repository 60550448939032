import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  HeaderWrapper,
  HeaderSvg,
  HeaderContent,
  HeaderTopRow,
  ReturnButton,
  Subtitle,
  HeaderBottomRow,
  FormationName,
  SchoolName,
  InfoWrapper,
  InfoRow,
  InfoItem,
  InfoIcon,
  InfoText,
  DomainContainer,
  DomainItem,
  DomainIcon,
  DomainText,
  Section,
  SectionTitle,
  SectionText,
  SubsectionTitle,
  ShareIcon,
  LikeIcon,
  IconsContainer,
  CityContainer,
  CityText,
  TitleContainer,
  ActionButton,
  ButtonIcon,
  ButtonsContainer,
  SchoolLocationRow,
  ButtonIconContainer,
  ButtonText,
  GrayBand,
  GrayBandWrapper,
  SectionTitleWrapper,
  ImportanceIcons,
  ImportanceSubtitle,
  DomainItemWrapper,
  LeftDomainContent,
  RightIconsContainer,
  FloatingHeart,

} from './styles';

import { IoIosArrowBack } from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import apiCall from '../../utils/api';
// Domain icons
import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import agroalimentaire_icon from '../../assets/agroalimentaire_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/nsi_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';
import durationIcon from '../../assets/duree.png';
import priceIcon from '../../assets/prix.png';
import statutIcon from '../../assets/statut.png';
import { MdOutlineShare } from "react-icons/md";
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import localisation_icon from '../../assets/localisation_icon.png';
import parcoursup_icon from '../../assets/out.png'; // Add this PNG to assets
import school_icon from '../../assets/out.png';
import plusIcon from '../../assets/plus_icon.png';
import { LeftContent } from '../LandingPage/styles';

const domainIconMap = {
  'Arts du Spectacle': arts_spectacle_icon,
  'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
  'Communication Visuelle - Dessin - Animation 3D': dessin_icon,
  'Design': design_specific_icon,
  "Marché de l'Art - Culture - Patrimoine": culture_patimoine_icon,
  'Mode - Textile': mode_textile_icon,
  'Luxe': luxe_specific_icon,
  'Artisanat': artisanat_specific_icon,

  'Sport - Management Sportif': sport_management_icon,
  'Enseignement': teaching_specific_icon,
  'Santé (Médical-Paramédical)': health_medical_icon,
  'Travailler avec des Enfants': working_with_children_icon,
  'ESport - Gaming': esport_specific_icon,
  'Tourisme - Loisirs': tourisme_specific_icon,

  'Droit - Justice - Sciences Politiques': droit_justice_icon,
  'Fonction Publique': fonction_publique_specific_icon,
  'Humanitaire': humanitaire_specific_icon,
  'Journalisme': journalisme_specific_icon,
  'Communication': communication_specific_icon,
  'Social': social_specific_icon,
  'Sécurité - Armée - Défense': security_army_icon,
  "Entrepreneuriat - Création d'Entreprise": entrepreneurship_icon,

  "Édition - Métiers du Livre": book_industry_icon,
  'Psychologie - Sociologie': psychology_sociology_icon,
  'Langues étrangères - Traduction': langues_translation_icon,
  'Commerce - Distribution - Management': commerce_management_icon,
  'Hôtellerie - Restauration': hospitality_icon,
  'Transport - Logistique': transport_icon,

  'Mathématiques - Sciences Physiques': math_physics_icon,
  'Finance': finance_specific_icon,
  'Banque - Assurance': banque_assurance_icon,
  'Comptabilité - Gestion - Audit': accounting_audit_icon,
  'Informatique - Numérique': informatics_icon,
  'Cybersécurité': cybersecurity_specific_icon,
  'Le Nucléaire': nuclear_specific_icon,
  'Internet - Web - Multimédia': internet_web_icon,
  'Jeu Vidéo': gaming_specific_icon,
  'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,

  'Chimie - Biologie': chemistry_biology_icon,
  'Énergie': energy_specific_icon,
  'Industrie - Qualité': industry_quality_icon,
  'Environnement - Développement Durable': environment_icon,
  'Métiers Animaliers': animals_specific_icon,
  'Agriculture': agriculture_specific_icon,
  'Agroalimentaire': agroalimentaire_icon,
  'Métiers de la Mer': marine_specific_icon,
  'Métiers du Vin': wine_industry_icon,

  'Architecture - Urbanisme - Paysagisme': architecture_icon,
  'Automobile - Moto': automobile_icon,
  'Bâtiment et travaux publics (BTP)': btp_icon,
  'Électronique': electronics_icon,
  'Aéronautique et Aérospatial': aeronautics_icon,

  'Ressources Humaines': resources_human_icon,
  'Publicité - Marketing': advertising_icon,
  'Immobilier': real_estate_icon,
  'Métiers de Bouche': culinary_specific_icon,
};

const handleShare = () => {
  navigator.clipboard.writeText(window.location.href);
  alert('Link copied to clipboard');
};

const FicheFormation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formation, setFormation] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  // In your FicheFormation component
  const [animateLike, setAnimateLike] = useState(false);
  const [animateShare, setAnimateShare] = useState(false);
  const lastClickTimeRef = useRef(0);
  const DOUBLE_CLICK_THRESHOLD = 300;
  const [clickPosition, setClickPosition] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  // Extraction de l'ID depuis l'URL
  const searchParams = new URLSearchParams(location.search);
  const idFormation = searchParams.get('id');

  const handleHeaderClick = (event) => {
    if (event.target.closest('[data-exclude="true"]')) return;
  
    const now = Date.now();
    if (now - lastClickTimeRef.current < DOUBLE_CLICK_THRESHOLD) {
      const rect = event.currentTarget.getBoundingClientRect();
      setClickPosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        key: Date.now() // Unique key for each animation
      });
      
      if (!isFavorite) {
        setAnimateLike(true);
        handleLike();
        setTimeout(() => setAnimateLike(false), 500);
      }
    }
    lastClickTimeRef.current = now;
  };

  useEffect(() => {
    const fetchFormation = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/ficheFormation/?id=${idFormation}`;
        const response = await apiCall(url, 'GET');
        setFormation(response);
        setLoading(false);
      } catch (err) {
        console.error('Erreur:', err);
        setError(true);
        setLoading(false);
      }
    };

    if (idFormation) fetchFormation();
    else {
      setError(true);
      setLoading(false);
    }
  }, [idFormation]);

  useEffect(() => {
    const checkFavorite = async () => {
      try {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/check_favoris_formation/${idFormation}/`,
          'GET'
        );
        setIsFavorite(response.is_favorite);
      } catch (error) {
        console.error('Error checking favorite:', error);
      }
    };

    if (idFormation) checkFavorite();
  }, [idFormation]);


  const handleLike = async () => {
    try {
      if (isFavorite) {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`,
          'DELETE',
          { id: idFormation }
        );
        setIsFavorite(!isFavorite);
      } else {

        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_formation/`,
          'POST',
          { id: idFormation, nom:nom_formation, etablissement:nom_ecole, matchPercentage:"SOON" } // required_fields = ['nom', 'etablissement', 'matchPercentage', 'id']
        );
        setIsFavorite(!isFavorite);
      }
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLinkClick = () => {
    if (formation.link) window.open(formation.link, '_blank');
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur de chargement</div>;

  const {
    nom_ecole,
    nom_formation,
    ville,
    site,
    duree,
    cout,
    public_prive,
    domaines_enseignés_principaux = [],
    domaines_enseignés_secondaires = [],
    poursuite_etudes = [],
    débouchés_professionnels = [],
  } = formation;
  console.log("POURSUITE ETUDES : ");
  console.log(typeof(poursuite_etudes));
  return (
    <Container>
      {/* ----------------- FIXED HEADER (Gradient) ----------------- */}
      <HeaderWrapper onClick={handleHeaderClick}>
        {clickPosition && (
          <FloatingHeart
            key={clickPosition.key} // Use unique key to force new animation
            style={{
              left: `${clickPosition.x}px`,
              top: `${clickPosition.y}px`,
            }}
            onAnimationEnd={() => setClickPosition(null)}
          >
            <FaHeart />
          </FloatingHeart>
        )}
  <HeaderSvg
  xmlns="http://www.w3.org/2000/svg"
  width="379"
  height="168"
  viewBox="0 0 379 168"
  fill="none"
  preserveAspectRatio="none"
>
  <path
    d="M0 25C0 11.1929 11.1929 0 25 0H354C367.807 0 379 11.1929 379 25V143C379 156.807 367.807 168 354 168H25C11.1929 168 0 156.807 0 143V25Z"
    fill="url(#paint0_linear_287_452)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_287_452"
      x1="0"
      y1="84"
      x2="379"
      y2="84"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#CC00FF" />
      <stop offset="1" stopColor="#0F04E3" />
    </linearGradient>
  </defs>
</HeaderSvg>

        <HeaderContent>
        <HeaderTopRow>
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    <ReturnButton onClick={(e) => {
      e.stopPropagation();
      handleBack();
    }}>
      <IoIosArrowBack />
    </ReturnButton>
    <Subtitle>Formation</Subtitle>
  </div>
  <IconsContainer>
              <ShareIcon $animate={animateShare} onClick={(e) => {
                setAnimateShare(true);
                e.stopPropagation();
                handleShare();
                setTimeout(() => setAnimateShare(false), 300);  // Reset after animation
              }}>
                <MdOutlineShare />
              </ShareIcon>
              <LikeIcon
                  data-exclude="true"
                  $animate={animateLike}
                  $isFavorite={isFavorite}
                  onClick={handleLike}
                >
                  {isFavorite ? <FaHeart /> : <FaRegHeart />}
                </LikeIcon>
            </IconsContainer>
          </HeaderTopRow>

          
          

          <TitleContainer>
  <FormationName title={nom_formation}>
    {nom_formation}
  </FormationName>
  <SchoolName>{nom_ecole}</SchoolName>
  <ButtonsContainer>
    <CityContainer>
      <ButtonIcon src={localisation_icon} alt="Location" />
      <CityText>{ville}</CityText>
    </CityContainer>
    
    {formation.site && (
      <ActionButton href={formation.site} target="_blank">
        <ButtonText>Site de l'école</ButtonText>
        <ButtonIconContainer>
          <ButtonIcon src={school_icon} alt="School" />
        </ButtonIconContainer>
      </ActionButton>
    )}
              {formation.link && (
                <ActionButton href={formation.link} target="_blank">
                  <ButtonText>Parcoursup</ButtonText>
                  <ButtonIconContainer>
                    <ButtonIcon src={parcoursup_icon} alt="Parcoursup" />
                  </ButtonIconContainer>
                </ActionButton>
              )}
            </ButtonsContainer>
            </TitleContainer>
        </HeaderContent>
      </HeaderWrapper>

            <GrayBandWrapper>
        <GrayBand />
      </GrayBandWrapper>
      

      <InfoWrapper>
        <InfoRow>
          <InfoItem>
            <InfoIcon>
              <img src={require('../../assets/duree.png')} alt="durée" />
            </InfoIcon>
            <InfoText>{duree}</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <img src={require('../../assets/prix.png')} alt="prix" />
            </InfoIcon>
            <InfoText>{cout} €/an</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <img src={require('../../assets/statut.png')} alt="statut" />
            </InfoIcon>
            <InfoText>{public_prive}</InfoText>
          </InfoItem>
        </InfoRow>

        {/* Replace the existing DomainContainer section with this */}
<DomainContainer>
  <SectionTitleWrapper>
    <SectionTitle>Domaines enseignés</SectionTitle>
    <ImportanceSubtitle>Poids dans la formation</ImportanceSubtitle>
  </SectionTitleWrapper>

  {[...domaines_enseignés_principaux.map(d => ({ domain: d, type: 'principal' })),
    ...domaines_enseignés_secondaires.map(d => ({ domain: d, type: 'secondaire' }))].map(({ domain, type }, idx) => (
    <DomainItem key={idx}>
      <DomainItemWrapper>
        <LeftDomainContent>
          <DomainIcon>
            <img src={domainIconMap[domain]} alt={domain} />
          </DomainIcon>
          <DomainText>{domain}</DomainText>
        </LeftDomainContent>
        
        <RightIconsContainer>
          {Array.from({ length: type === 'principal' ? 2 : 1 }).map((_, i) => (
            <img
              key={i}
              src={plusIcon}  // Make sure to import this at the top
              alt="Importance"
              style={{ width: '16px', height: '16px' }}
            />
          ))}
        </RightIconsContainer>
      </DomainItemWrapper>
    </DomainItem>
  ))}
</DomainContainer>

        {/* Replace the existing débouchés DomainContainer with these two containers */}
<DomainContainer>
  <SectionTitle>Poursuite d'études</SectionTitle>
  {Object.values(poursuite_etudes || {}).map((value, idx) => (
    <SectionText key={idx}>{value}</SectionText>
  ))}
</DomainContainer>

<DomainContainer>
  <SectionTitle>Débouchés Professionnels</SectionTitle>
  {Object.values(débouchés_professionnels || {}).map((value, idx) => (
    <SectionText key={idx}>{value}</SectionText>
  ))}
</DomainContainer>
      </InfoWrapper>
    </Container>
  );
};

export default FicheFormation;