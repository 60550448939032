import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

/* 
  Container to hold the entire "Classement" page
*/
export const Container = styled.div`
  width: 95%;
  margin-left: 2.5%;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

/*
  ContentWrapper can still be used for limiting max-width, etc.
*/
export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/*
  PodiumHeader is the section that includes the top image and possibly the share button
*/
export const PodiumHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/*
  A container for the "Meo" trophy or top area image.
  We'll switch to a DIV with a background-image instead of an <img>.
*/
export const TopImage = styled.div`
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: none;
  margin-top: -2rem;
`;

/*
  Share button position (top-right)
*/
export const ShareButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 12;
`;

/*
  Scrollable area for the list of rankings
*/
export const ScrollableRankings = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  margin-top: -1rem;
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 95%;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 2.5%;
`;

/*
  Main Ranking Item box:
  1) 360px wide, 94px tall
  2) border-radius: 25px
  3) background: #FFF
  4) box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25)
*/
export const RankingItem = styled.div`
  width: 100%;
  height: 94px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  padding: 0 20px; /* Some horizontal padding */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
`;

/*
  For the top 3, we show a trophy icon (30x30).
  It's just a div with a background image in JS code.
*/
export const TrophyIcon = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  margin-right: 10px;
`;

/*
  For the ranks > 3, we show a rank number
  w/ color #6E6E6E, font-size: 21px, etc.
*/
export const RankNumber = styled.span`
  width: 30px; /* Enough to hold 2 digits comfortably */
  text-align: center;
  color: #6e6e6e;
  font-family: 'Gabarito', sans-serif; /* or your chosen fallback */
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-right: 10px;
  flex-shrink: 0;
`;


export const FormationName = styled.div`
  width: 193px;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
  font-style: normal;
  font-weight: 400;

  display: -webkit-box; /* Enables truncation for multiple lines */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical; /* Specifies the orientation of the box */
  overflow: hidden; /* Ensures the overflowing text is clipped */
  text-overflow: ellipsis; /* Adds "..." when the text overflows */
`;



/*
  Score container (on the right side):
  86px wide, 74px tall, border-radius: 35px
  background is a gradient that we set inline
*/
export const ScoreContainer = styled.div`
  width: 86px;
  height: 74px;
  flex-shrink: 0;
  border-radius: 35px;
  border: 0px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

/*
  "Match à" text inside the circle
*/
export const ScoreTopText = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.large};
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-bottom: -5px;
`;

/*
  Percentage text inside the circle, bigger and bolder
*/
export const ScoreValue = styled.div`
  color: #FFF;
  font-family: 'Hind', sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

/* 
  PlaceholderItem while loading 
  - You can style to match the new design placeholders
*/

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export const PlaceholderLine = styled.div`
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${props => props.width || '70%'};
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const PlaceholderPercent = styled.div`
  width: 24px;
  height: 24px;
  background: #f0f0f0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

/*
  Modal styles remain similar to your previous approach
*/
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 1602;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const SlidingModal = styled.div`
  background: ${theme.colors.background};
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  animation: slideUp 0.3s ease;

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;
