import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import {
  PrimaryButton
} from '../SecundPage/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';
import tiktokIcon from '../../assets/tiktok-icon.png';
import instagramIcon from '../../assets/instagram-icon.png';
import schoolIcon from '../../assets/school-icon.png';
import wordOfMouthIcon from '../../assets/word-of-mouth-icon.png';
import radioIcon from '../../assets/radio-icon.png';
import tvIcon from '../../assets/tv-icon.png';
import otherIcon from '../../assets/other-icon.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';

const SourceConnaissance = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Où as-tu entendu parler de Meoria ?";
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const typeOptions = [
    {
      value: 'tiktok',
      label: 'Tiktok',
      icon: tiktokIcon
    },
    {
      value: 'instagram',
      label: 'Instagram',
      icon: instagramIcon
    },
    {
      value: 'lycee',
      label: 'Lycee',
      icon: schoolIcon
    },
    {
      value: 'bouche_a_oreille',
      label: 'Bouche à oreille',
      icon: wordOfMouthIcon
    },
    {
      value: 'radio',
      label: 'Radio',
      icon: radioIcon
    },
    {
      value: 'tv',
      label: 'TV',
      icon: tvIcon
    },
    {
      value: 'autre',
      label: 'Autre',
      icon: otherIcon
    }
  ];

  const handleSubmit = () => {
    if (!selectedType || isTyping) return;
    setIsLoading(true);
    try {
      localStorage.setItem('source_connaissance', selectedType);
      navigate('/message-pret');
    } catch (error) {
      console.error('Error saving user type to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/importance-orientation");
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      <ProgressBar startPercent={95} endPercent={100} />
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {typeOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  isSelected={selectedType === option.value}
                  onClick={() => setSelectedType(option.value)}
                  type="button"
                  aria-pressed={selectedType === option.value}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    isSelected={selectedType === option.value}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || !selectedType}
          type="button"
          style={{
            opacity: (isTyping || !selectedType) ? 0.5 : 1,
            cursor: (isTyping || !selectedType) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default SourceConnaissance;