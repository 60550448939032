// index.js
import React, { useEffect, useState } from 'react';
import {
  ProgressBarContainer,
  ProgressBarFill,
  StepIndicator,
  StepText
} from './styles';

const ProgressBar = ({ 
  startPercent = 0,    // Where this section begins
  endPercent = 100,    // Where this section ends
  totalSteps = 4       // Total number of steps (for display only)
}) => {
  const [width, setWidth] = useState(startPercent);

  useEffect(() => {
    // Animate to the end percentage
    setTimeout(() => {
      setWidth(endPercent);
    }, 100);
  }, [startPercent, endPercent]);

  // Calculate which step we're on based on the percentage ranges
  const currentStep = Math.ceil((endPercent / 100) * totalSteps);

  return (
    <ProgressBarContainer>
      <ProgressBarFill width={width} />
      <StepIndicator>
        <StepText>Étape {currentStep}/{totalSteps}</StepText>
      </StepIndicator>
    </ProgressBarContainer>
  );
};

export default ProgressBar;