import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './index.css';
import App from './App';
import { AuthProviderWithNavigate } from './contexts/AuthContext';

// Define your theme object
const theme = {
  colors: {
    primary: 'var(--primary-color)',
    secondary: 'var(--secondary-color)',
    background: 'var(--background-color)',
    text: 'var(--text-color)',
    lightText: 'var(--light-text-color)',
    lightGray: 'var(--light-gray-color)',
    veryLightGray: 'var(--very-light-gray-color)',
    mediumGray: 'var(--medium-gray-color)',
  }
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Router>
    <ThemeProvider theme={theme}>
        <AuthProviderWithNavigate>
          <App />
        </AuthProviderWithNavigate>
    </ThemeProvider>
  </Router>
);

//serviceWorkerRegistration.register();