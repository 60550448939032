import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DecouvrirFormations from '../DecouvrirFormations'; // <-- import your component
import {
  Container,
  HeaderWrapper,
  HeaderSvg,
  HeaderContent,
  HeaderTopRow,
  ReturnButton,
  IconsContainer,
  ShareIcon,
  LikeIcon,
  HeaderBottomRow,
  FormationName,
  InfoWrapper,
  InfoRow,
  InfoItem,
  InfoIcon,
  InfoText,
  DomainContainer,
  DomainItem,
  DomainIcon,
  DomainText,
  Section,
  SectionTitle,
  PlusMinusContainer,
  PlusMinusItem,
  PlusMinusIcon,
  PlusMinusText,
  ScoreContainer,
  ScoreTopText,
  ScoreValue,
  VoirPlusLink,
  DomainItemWrapper,
  ImportanceSubtitle,
  SectionTitleWrapper,
  LeftDomainContent,
  RightIconsContainer,
  ButtonsRow,             // <--- Newly added styled components
  TabButton,
  GrayBand,
  Subtitle,
  GrayBandWrapper,
  FloatingHeart,
} from './styles';
import { FaHeart } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { FaRegHeart , FaCheck } from 'react-icons/fa';
import { MdOutlineShare } from "react-icons/md";
import { FaExclamation } from "react-icons/fa6";
import ShareButtonTypeFormation from '../ShareCardTypeFormation';

import durationIcon from '../../assets/duree.png';
import priceIcon from '../../assets/prix.png';
import statutIcon from '../../assets/statut.png';

// Domain icons
import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import agroalimentaire_icon from '../../assets/agroalimentaire_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/nsi_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';
import plusIcon from '../../assets/plus_icon.png';

import apiCall from '../../utils/api';


// Map each domain label to its icon
const domainIconMap = {
  'Arts du Spectacle': arts_spectacle_icon,
  'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
  'Communication Visuelle - Dessin - Animation 3D': dessin_icon,
  'Design': design_specific_icon,
  "Marché de l'Art - Culture - Patrimoine": culture_patimoine_icon,
  'Mode - Textile': mode_textile_icon,
  'Luxe': luxe_specific_icon,
  'Artisanat': artisanat_specific_icon,

  'Sport - Management Sportif': sport_management_icon,
  'Enseignement': teaching_specific_icon,
  'Santé (Médical-Paramédical)': health_medical_icon,
  'Travailler avec des Enfants': working_with_children_icon,
  'ESport - Gaming': esport_specific_icon,
  'Tourisme - Loisirs': tourisme_specific_icon,

  'Droit - Justice - Sciences Politiques': droit_justice_icon,
  'Fonction Publique': fonction_publique_specific_icon,
  'Humanitaire': humanitaire_specific_icon,
  'Journalisme': journalisme_specific_icon,
  'Communication': communication_specific_icon,
  'Social': social_specific_icon,
  'Sécurité - Armée - Défense': security_army_icon,
  "Entrepreneuriat - Création d'Entreprise": entrepreneurship_icon,

  "Édition - Métiers du Livre": book_industry_icon,
  'Psychologie - Sociologie': psychology_sociology_icon,
  'Langues Étrangères - Traduction': langues_translation_icon,
  'Commerce - Distribution - Management': commerce_management_icon,
  'Hôtellerie - Restauration': hospitality_icon,
  'Transport - Logistique': transport_icon,

  'Mathématiques - Sciences Physiques': math_physics_icon,
  'Finance': finance_specific_icon,
  'Banque - Assurance': banque_assurance_icon,
  'Comptabilité - Gestion - Audit': accounting_audit_icon,
  'Informatique - Numérique': informatics_icon,
  'Cybersécurité': cybersecurity_specific_icon,
  'Le Nucléaire': nuclear_specific_icon,
  'Internet - Web - Multimédia': internet_web_icon,
  'Jeu Vidéo': gaming_specific_icon,
  'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,

  'Chimie - Biologie': chemistry_biology_icon,
  'Énergie': energy_specific_icon,
  'Industrie - Qualité': industry_quality_icon,
  'Environnement - Développement Durable': environment_icon,
  'Métiers Animaliers': animals_specific_icon,
  'Agriculture': agriculture_specific_icon,
  'Agroalimentaire': agroalimentaire_icon,
  'Métiers de la Mer': marine_specific_icon,
  'Métiers du Vin': wine_industry_icon,

  'Architecture - Urbanisme - Paysagisme': architecture_icon,
  'Automobile - Moto': automobile_icon,
  'Bâtiment et Travaux Publics (BTP)': btp_icon,
  'Électronique': electronics_icon,
  'Aéronautique et Aérospatial': aeronautics_icon,

  'Ressources Humaines': resources_human_icon,
  'Publicité - Marketing': advertising_icon,
  'Immobilier': real_estate_icon,
  'Métiers de Bouche': culinary_specific_icon,
};


const CardTypeFormation = () => {
  const navigate = useNavigate();
  const { id: urlSafeId } = useParams();
  console.log("urlSafeId ::", urlSafeId);
  const formationId = urlSafeId;
  const [formationData, setFormationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animateLike, setAnimateLike] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showAllPlus, setShowAllPlus] = useState(false);
  const [activeTab, setActiveTab] = useState('type');
  const [showAllMinus, setShowAllMinus] = useState(false);
  const lastClickTimeRef = useRef(0);
  const DOUBLE_CLICK_THRESHOLD = 300;
  const [clickPosition, setClickPosition] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  const handleHeaderClick = (event) => {
    if (event.target.closest('[data-exclude="true"]')) return;
  
    const now = Date.now();
    if (now - lastClickTimeRef.current < DOUBLE_CLICK_THRESHOLD) {
      const rect = event.currentTarget.getBoundingClientRect();
      setClickPosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        key: Date.now() // Unique key for each animation
      });
      
      if (!isFavorite) {
        setAnimateLike(true);
        handleLike();
        setTimeout(() => setAnimateLike(false), 500);
      }
    }
    lastClickTimeRef.current = now;
  };

  useEffect(() => {
    const fetchFormationData = async () => {
      try {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/quiz/ficheTypeFormation/?id=${formationId}`,
          'GET'
        );
        if (response.error) {
          throw new Error(response.error);
        }
        setFormationData(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFormationData();
  }, [formationId]);

  useEffect(() => {
    const checkFavorite = async () => {
      // You might need formationName defined for this call
      // but you can't do that until formationData is loaded.
      // So do a guard:
      if (!formationData?.formation_info) return;

      const formationName = formationData?.formation_info?.unique_type;
      try {
        // Use the type formation check endpoint
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/check_favoris_type_formation/?nom=${encodeURIComponent(formationName)}`,
          'GET'
        );
        setIsFavorite(response.is_favoris);
      } catch (error) {
        console.error('Error checking favorite:', error);
      }
    };
    
    checkFavorite();
  }, [formationData]);

  
  
  if (loading) return <div>Loading...</div>;
if (error) return <div>Error: {error.message}</div>;
if (!formationData) return <div>No data found</div>;



  // Helper to format a price interval string (e.g., "9500.0-176000.0") into "9 500€ - 176 000€".
  const formatPriceRange = (prixInterval) => {
    if (!prixInterval || typeof prixInterval !== 'string') {
      return 'Prix inconnu';
    }
    const parts = prixInterval.split('-');
    
    // Handle single price
    if (parts.length === 1) {
      const price = parseFloat(parts[0]);
      if (isNaN(price)) {
        return 'Prix inconnu';
      }
      return `${price.toLocaleString('fr-FR')}`;
    }
    
    // Handle interval
    if (parts.length === 2) {
      const [lower, upper] = parts;
      const lowerNum = parseFloat(lower);
      const upperNum = parseFloat(upper);
      if (isNaN(lowerNum) || isNaN(upperNum)) {
        return 'Prix inconnu';
      }
      const lowerFormatted = lowerNum.toLocaleString('fr-FR');
      const upperFormatted = upperNum.toLocaleString('fr-FR');
      return `${lowerFormatted}€ - ${upperFormatted}€`;
    }
  
    // Handle invalid formats (e.g., more than one hyphen)
    return 'Prix inconnu';
  };

  console.log("formationData ::", formationData);

  // ✅ Safe access with optional chaining
  const formationInfo = formationData?.formation_info || {};
  console.log("formationData?.formation_info ::", formationData?.formation_info);
  const formationName = formationInfo?.unique_type || "Erreur";
  const duree = formationInfo?.duration || "Erreur";
  const coutNormal = formationInfo?.pricing?.normal || "Erreur";
  const formattedCout = formatPriceRange(coutNormal);
  const statut = formationInfo.status;
  const matchingPercentage = formationData.total_score ? `${Math.round(formationData.total_score)}%` : `?%`;

  // Domains
  const mainDomains = formationData?.matched_domains?.main || [];
  const secondaryDomains = formationData?.matched_domains?.secondary || [];
  const allDomains = [
    ...mainDomains.map(domain => ({ domain, type: 'principal' })),
    ...secondaryDomains.map(domain => ({ domain, type: 'secondaire' }))
  ];

  const getUniqueQuestions = (array) => {
    // Ensure input is an array
    if (!Array.isArray(array)) return [];

    const seen = new Set();
    return array.filter(item => {
      const question = item.question;
      if (seen.has(question)) return false;
      seen.add(question);
      return true;
    });
  };
  
  const positiveData = formationData.positive_questions || [];
  const uniquePlus = getUniqueQuestions(positiveData).filter(item => item.answer_score > 0);
  const displayedPlus = showAllPlus ? uniquePlus : uniquePlus.slice(0, 4);
  const hasPlus = uniquePlus.length > 0;
  const hasMorePlus = uniquePlus.length > 4 && !showAllPlus;

  const negativeData = formationData.negative_questions || [];
  const uniqueMinus = getUniqueQuestions(negativeData).filter(item => item.answer_score < 0);
  const displayedMinus = showAllMinus ? uniqueMinus : uniqueMinus.slice(0, 4);
  const hasMinus = uniqueMinus.length > 0;
  const hasMoreMinus = uniqueMinus.length > 4 && !showAllMinus;

  // -------------- Handlers --------------
  const handleShare = () => {
    alert('Share button clicked.');
  };


  const handleLike = async () => {
    try {
      if (isFavorite) {
        // Remove from favorites
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_type_formation/`,
          'DELETE',
          { nom: formationName }
        );
      } else {
        // Add to favorites
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_type_formation/`,
          'POST',
          { 
            nom: formationName, 
            matchPercentage: matchingPercentage.replace('%', '') 
          }
        );
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };

  const handleLinkClick = (path) => {
    if (path && path !== '#') {
      navigate(path);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {/* ----------------- FIXED HEADER (Gradient) ----------------- */}
      <HeaderWrapper onClick={handleHeaderClick}>
  {clickPosition && (
    <FloatingHeart
      key={clickPosition.key} // Use unique key to force new animation
      style={{
        left: `${clickPosition.x}px`,
        top: `${clickPosition.y}px`,
      }}
      onAnimationEnd={() => setClickPosition(null)}
    >
      <FaHeart />
    </FloatingHeart>
  )}
        <HeaderSvg
          xmlns="http://www.w3.org/2000/svg"
          width="379"
          height="168"
          viewBox="0 0 379 168"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 25C0 11.1929 11.1929 0 25 0H354C367.807 0 379 11.1929 379 25V143C379 156.807 367.807 168 354 168H25C11.1929 168 0 156.807 0 143V25Z"
            fill="url(#paint0_linear_176_161)"
            fillOpacity="1"
          />
          <defs>
            <linearGradient
              id="paint0_linear_176_161"
              x1="0"
              y1="84"
              x2="379"
              y2="84"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FC7D16" />
              <stop offset="1" stopColor="#FE276A" />
            </linearGradient>
          </defs>
        </HeaderSvg>

        <HeaderContent>
          {/* Top row: return button left, icons right */}
          <HeaderTopRow>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <ReturnButton onClick={(e) => {
                e.stopPropagation();
                handleBack();
              }}>
                <IoIosArrowBack />
              </ReturnButton>
              <Subtitle>Type de formation</Subtitle>
            </div>
            <IconsContainer>
            <ShareButtonTypeFormation formationData={formationData} />
  <LikeIcon
    data-exclude="true"
    $animate={animateLike}
    $isFavorite={isFavorite}
    onClick={handleLike}
  >
    {isFavorite ? <FaHeart /> : <FaRegHeart />}
  </LikeIcon>
</IconsContainer>
          </HeaderTopRow>

          {/* Bottom row: formation name (left), percentage (right) */}
          <HeaderBottomRow>
            <FormationName>{formationName}</FormationName>
            <ScoreContainer>
              <ScoreTopText>Match à</ScoreTopText>
              <ScoreValue>{matchingPercentage}</ScoreValue>
            </ScoreContainer>
          </HeaderBottomRow>
        </HeaderContent>

      </HeaderWrapper>

      {/* ----------------- TABS ROW (Below Header) ----------------- */}
      <ButtonsRow>
        <TabButton
          active={activeTab === 'type'}
          onClick={() => setActiveTab('type')}
        >
          Type de formation
        </TabButton>

        <TabButton
          active={activeTab === 'decouvrir'}
          onClick={() => setActiveTab('decouvrir')}
        >
          Découvrir
        </TabButton>
      </ButtonsRow>

      {/* ----------------- GRAY BAND BELOW THE TWO BUTTONS ----------------- */}
      <GrayBandWrapper>
  <GrayBand />
</GrayBandWrapper>

      {/**
       * (1) If activeTab === 'decouvrir', we show <DecouvrirFormations />
       * You can swap these if you want a typical “tab switch.”
       * Or keep both displayed if you want.
       */}
      {activeTab === 'decouvrir' && (
        <div style={{ padding: '1rem', maxWidth: '379px', margin: '0 auto' }}>
          <DecouvrirFormations formationData={formationData} />
        </div>
      )}

      {/* Only show the “Type de formation” content if we’re on that tab. */}
      {activeTab === 'type' && (
        <>
          {/* ----------------- INFO WRAPPER ----------------- */}
          <InfoWrapper>
          <InfoRow>
          <InfoItem>
            <InfoIcon>
              <img src={durationIcon} alt="duration" />
            </InfoIcon>
            <InfoText>{duree} ans</InfoText>
          </InfoItem>
          
          <InfoItem>
            <InfoIcon>
              <img src={priceIcon} alt="price" />
            </InfoIcon>
            <InfoText>{formattedCout} €/an</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <img src={statutIcon} alt="status" />
            </InfoIcon>
            <InfoText>{statut}</InfoText>
          </InfoItem>
        </InfoRow>

            <DomainContainer>
              <SectionTitleWrapper>
                <SectionTitle>Tes domaines qui matchent</SectionTitle>
                <ImportanceSubtitle>Poids dans la formation</ImportanceSubtitle>
              </SectionTitleWrapper>

              {allDomains.length> 0 ? (
                allDomains.map(({ domain, type }, idx) => {
                  const icon = domainIconMap[domain];
                  return (
                    <DomainItem key={idx}>
                      <DomainItemWrapper>
                        <LeftDomainContent>
                          {icon && (
                            <DomainIcon>
                              <img src={icon} alt={domain} />
                            </DomainIcon>
                          )}
                          <DomainText>{domain}</DomainText>
                        </LeftDomainContent>
                        <RightIconsContainer>
                          {Array.from({ length: type === 'principal' ? 2 : 1 }).map((_, i) => (
                            <img
                              key={i}
                              src={plusIcon}
                              alt="Importance"
                              style={{ width: '16px', height: '16px' }}
                            />
                          ))}
                        </RightIconsContainer>
                      </DomainItemWrapper>
                    </DomainItem>
                  );
                })
              ) : (
                <DomainItem>
                  <DomainText>Aucun domaines matchés.</DomainText>
                </DomainItem>
              )}
            </DomainContainer>
          </InfoWrapper>

          <DomainContainer>
            <Section>
              <SectionTitle>Pourquoi cette formation TE correspond</SectionTitle>

              {hasPlus && (
                <PlusMinusContainer>
                  {displayedPlus.map((point, idx) => (
                    <PlusMinusItem key={idx}>
                      <PlusMinusIcon positive>
                        <FaCheck />
                      </PlusMinusIcon>
                      <PlusMinusText>{point.mot || 'non disponible'}</PlusMinusText>
                    </PlusMinusItem>
                  ))}

                  {(hasMorePlus || showAllPlus) && (
                    <VoirPlusLink onClick={() => setShowAllPlus(!showAllPlus)}>
                      {showAllPlus ? 'Voir moins' : 'Voir plus...'}
                    </VoirPlusLink>
                  )}
                </PlusMinusContainer>
              )}

              {hasMinus && <SectionTitle>mais...</SectionTitle>}

              {hasMinus && (
                <PlusMinusContainer>
                  {displayedMinus.map((point, idx) => (
                    <PlusMinusItem key={idx}>
                      <PlusMinusIcon>
                        <FaExclamation />
                      </PlusMinusIcon>
                      <PlusMinusText>{point.mot || 'non disponible'}</PlusMinusText>
                    </PlusMinusItem>
                  ))}

                  {(hasMoreMinus || showAllMinus) && (
                    <VoirPlusLink onClick={() => setShowAllMinus(!showAllMinus)}>
                      {showAllMinus ? 'Voir moins' : 'Voir plus...'}
                    </VoirPlusLink>
                  )}
                </PlusMinusContainer>
              )}
            </Section>
          </DomainContainer>

        </>
      )}
    </Container>
  );
};

export default CardTypeFormation;