import styled, { keyframes } from 'styled-components';
import { FaHeart } from 'react-icons/fa';

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export const ScrollableRankings = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  margin-top: -1rem;
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 95%;
  align-items: center;
  margin-top: 3%;
  margin-bottom: 5%;
  margin-left: 2.5%;
`;

export const RankingItem = styled.div`
  width: 100%;
  height: 94px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
`;

export const LikeButton = styled(FaHeart)`
  color: ${props => props.liked ? '#ff0000' : '#e0e0e0'};
  font-size: 24px;
  margin-right: 15px;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #ff0000;
  }
`;

export const FormationName = styled.div`
  width: 193px;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ScoreContainer = styled.div`
  width: 86px;
  height: 74px;
  flex-shrink: 0;
  border-radius: 35px;
  border: 0px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const ScoreTopText = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: -5px;
`;

export const ScoreValue = styled.div`
  color: #FFF;
  font-family: 'Hind', sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

export const SeeMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'GeologicaRoman', sans-serif;
  font-size: 1rem;
  font-style: italic;
  cursor: pointer;
  width: 100%;
  text-align: right;
  padding: 10px 20px;
  margin-top: -10px;

`;

export const HeaderImage = styled.img`
  width: 180px;
  height: 15%;
  margin-top: -10%;
  object-fit: cover;
  margin: 10px auto;
`;


export const SectionTitle = styled.h2`
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  color: #000;
  text-align: left;
  margin: 20px 20px 0;
`;

export const NoFavorites = styled.div`
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 1.2rem;
  color: #666;
  margin-top: 30px;
  padding: 20px;
`;


export const PlaceholderLine = styled.div`
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${props => props.width || '70%'};
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const PlaceholderPercent = styled.div`
  width: 24px;
  height: 24px;
  background: #f0f0f0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;
