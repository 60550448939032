import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import apiCall from '../../utils/api';
import PopupGlobale from '../PopupGlobale';
import {
  HeaderImage,
  ScrollableRankings,
  RankingList,
  RankingItem,
  LikeButton,
  FormationName,
  ScoreContainer,
  ScoreTopText,
  ScoreValue,
  SeeMoreButton,
  SectionTitle,
  NoFavorites,
  PlaceholderLine,
  PlaceholderPercent
} from './styles';
import mes_voeux from '../../assets/mes_voeux_icon.png';

const CardFavoris = () => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState({ formations: [], typesFormations: [] });
  const [showAllFormations, setShowAllFormations] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({ id: null, isFormation: true });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFavorites = async () => {
      setIsLoading(true);
      try {
        const data = await getFavorites();
        setFavorites(data || { formations: [], typesFormations: [] });
      } finally {
        setIsLoading(false);
      }
    };
    fetchFavorites();
  }, []);

  const getGradient = (percentage) => {
    const hue = 120 * (percentage / 100);
    return `linear-gradient(90deg, hsl(${hue}, 70%, 60%), hsl(${hue}, 70%, 30%))`;
  };

  const handleRemoveClick = (id, isFormation) => {
    setItemToDelete({ id, isFormation });
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const endpoint = itemToDelete.isFormation 
        ? `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`
        : `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_type_formation/`;
      
      await apiCall(endpoint, 'DELETE', itemToDelete.isFormation ? { id: itemToDelete.id } : { nom: itemToDelete.id });

      setFavorites(prev => ({
        formations: itemToDelete.isFormation ? prev.formations.filter(f => f.id !== itemToDelete.id) : prev.formations,
        typesFormations: !itemToDelete.isFormation ? prev.typesFormations.filter(t => t.id !== itemToDelete.id) : prev.typesFormations
      }));
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
    setShowDeletePopup(false);
  };

  const getGradientForScore = (score) => {
    const numericScore = Number(score);
    const clampedScore = Math.max(0, Math.min(100, numericScore));
    const baseColor = getColorBand(clampedScore);
    const deeperColor = darkenColor(baseColor, 0.2);
    
    // Reverse gradient to go from darker (left) to lighter (right)
    return `linear-gradient(90deg, ${deeperColor} 0%, ${baseColor} 100%)`;
  };

    // Darken a hex color by the given amount (0.1 = 10% darker).
const darkenColor = (hexColor, amount) => {
    // Remove leading "#" if present
    let color = hexColor.replace('#', '');
  
    // Parse the 3 or 6 digits
    if (color.length === 3) {
      // Expand shorthand #abc → #aabbcc
      color = color
        .split('')
        .map((c) => c + c)
        .join('');
    }
  
    // Convert to decimal r, g, b
    let r = parseInt(color.slice(0, 2), 16);
    let g = parseInt(color.slice(2, 4), 16);
    let b = parseInt(color.slice(4, 6), 16);
  
    // Darken each channel
    r = Math.floor(r * (1 - amount));
    g = Math.floor(g * (1 - amount));
    b = Math.floor(b * (1 - amount));
  
    // Convert back to hex and return
    const toHex = (val) => {
      const h = val.toString(16);
      return h.length === 1 ? '0' + h : h;
    };
  
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };
  
  const getColorBand = (score) => {
    if (score <= 20) return '#FF503E';      // Red
    if (score <= 40) return '#F4932C';      // Orange
    if (score <= 60) return '#FFFC3F';      // Yellow
    if (score <= 80) return '#4CE856';      // Light Green
    return '#20D428';                      // Green (81–100)
  };
  
  

  const renderItem = (item, isFormation) => {
    const urlSafeId = isFormation ? item.id : item.nom.replace(/ /g, '_');
    
    return (
      <RankingItem 
        key={item.id} 
        onClick={() => {
          if (!isFormation) {
            const formationType = item.nom;
            if (!formationType) {
              alert('Formation type missing');
              return;
            }
            navigate(`/card-type-formation/${urlSafeId}`);
          } else {
            navigate(`/formation?id=${item.id}`);
          }
        }}
      >
        <LikeButton 
          liked={true} 
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveClick(isFormation ? item.id : item.nom, isFormation);
          }}
        />
        <FormationName>
          {item.nom}
          {isFormation && item.etablissement && (
            <div style={{ fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.43)' }}>
              {item.etablissement}
            </div>
          )}
        </FormationName>
        {!isFormation && (
        <ScoreContainer style={{ background: getGradientForScore(item.total_score) }}>
          <ScoreValue>{item.matchPercentage}%</ScoreValue>
        </ScoreContainer>
      )}
    </RankingItem>
  );
  };

  return (
    <div style={{ backgroundColor: '#fff5f8', minHeight: '110vh' }}>
      <HeaderImage src={mes_voeux} alt="Mes voeux" />

      <PopupGlobale
        isVisible={showDeletePopup}
        message="Voulez-vous vraiment supprimer ce favori ?"
        onCancel={() => setShowDeletePopup(false)}
        onConfirm={handleDeleteConfirm}
        cancelText="Annuler"
        confirmText="Confirmer"
      />

      <ScrollableRankings>
      {isLoading ? (
  <>
    <SectionTitle>Mes types de formations</SectionTitle>
    <RankingList>
      {[0, 1].map((index) => (
        <RankingItem key={`type-placeholder-${index}`}>
                  <PlaceholderPercent as="div" style={{ marginRight: '15px' }} />
                  <FormationName>
                    <PlaceholderLine width="70%" />
                    <PlaceholderLine width="50%" />
                  </FormationName>
                  <ScoreContainer gradient="linear-gradient(90deg, #f0f0f0, #e0e0e0)">
                    <ScoreTopText>
                      <PlaceholderLine width="60%" style={{ height: '12px', margin: '0 auto' }} />
                    </ScoreTopText>
                    <ScoreValue>
                      <PlaceholderLine width="40%" style={{ height: '24px', margin: '0 auto' }} />
                    </ScoreValue>
                  </ScoreContainer>
                </RankingItem>
              ))}
            </RankingList>

            <SectionTitle>Mes formations favorites</SectionTitle>
    <RankingList>
      {[0, 1].map((index) => (
        <RankingItem key={`formation-placeholder-${index}`}>
                  <PlaceholderPercent as="div" style={{ marginRight: '15px' }} />
                  <FormationName>
                    <PlaceholderLine width="70%" />
                    <PlaceholderLine width="50%" />
                  </FormationName>
                  <ScoreContainer gradient="linear-gradient(90deg, #f0f0f0, #e0e0e0)">
                    <ScoreTopText>
                      <PlaceholderLine width="60%" style={{ height: '12px', margin: '0 auto' }} />
                    </ScoreTopText>
                    <ScoreValue>
                      <PlaceholderLine width="40%" style={{ height: '24px', margin: '0 auto' }} />
                    </ScoreValue>
                  </ScoreContainer>
                </RankingItem>
              ))}
            </RankingList>
          </>
        ) : favorites.typesFormations.length === 0 && favorites.formations.length === 0 ? (
          <NoFavorites>Pas de favoris encore</NoFavorites>
        ) : (
          <>
            {/* Existing favorites rendering */}
            <SectionTitle>Mes types de formations</SectionTitle>
            <RankingList>
              {favorites.typesFormations.slice(0, showAllTypes ? undefined : 2).map(item => renderItem(item, false))}
            </RankingList>
            {favorites.typesFormations.length > 2 && (
              <SeeMoreButton onClick={() => setShowAllTypes(!showAllTypes)}>
                Voir {showAllTypes ? 'moins' : 'plus...'}
              </SeeMoreButton>
            )}

            <SectionTitle>Mes formations favorites</SectionTitle>
            <RankingList>
              {favorites.formations.slice(0, showAllFormations ? undefined : 2).map(item => renderItem(item, true))}
            </RankingList>
            {favorites.formations.length > 2 && (
              <SeeMoreButton onClick={() => setShowAllFormations(!showAllFormations)}>
                Voir {showAllFormations ? 'moins' : 'plus'}...
              </SeeMoreButton>
            )}
          </>
        )}
      </ScrollableRankings>
    </div>
  );
};

const getFavorites = async () => {
  try {
    return await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/favorites/`, 'GET');
  } catch (error) {
    console.error('Error fetching favorites:', error);
    return { formations: [], typesFormations: [] };
  }
};

export default CardFavoris;