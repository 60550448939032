import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import { PrimaryButton } from '../SecundPage/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';

import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import teaching_civilisation_icon from '../../assets/teacher_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/cybersecurity_specific_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';

import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';

// ─────────────────────────────────────────────────────────────────────────────
// 1) Update specialitySubdomainMap to store objects directly (label + icon).
// ─────────────────────────────────────────────────────────────────────────────
const specialitySubdomainMap = {
  arts: [
    { label: 'Arts du Spectacle', icon: arts_spectacle_icon },
    { label: 'Audiovisuel - Cinéma - Photographie', icon: audiovisuel_icon },
    { label: 'Communication Visuelle - Dessin - Animation 3D', icon: dessin_icon },
    { label: 'Design', icon: design_specific_icon },
    { label: "Marché de l'Art - Culture - Patrimoine", icon: culture_patimoine_icon },
    { label: 'Mode - Textile', icon: mode_textile_icon },
    { label: 'Luxe', icon: luxe_specific_icon },
    { label: 'Artisanat', icon: artisanat_specific_icon },
  ],
  eppcs: [
    { label: 'Sport - Management Sportif', icon: sport_management_icon },
    { label: 'Enseignement', icon: teaching_specific_icon },
    { label: 'Santé (Médical-Paramédical)', icon: health_medical_icon },
    { label: 'Travailler avec des Enfants', icon: working_with_children_icon },
    { label: 'ESport - Gaming', icon: esport_specific_icon },
    { label: 'Tourisme - Loisirs', icon: tourisme_specific_icon },
  ],
  hggsp: [
    { label: 'Droit - Justice - Sciences Politiques', icon: droit_justice_icon },
    { label: 'Fonction Publique', icon: fonction_publique_specific_icon },
    { label: 'Humanitaire', icon: humanitaire_specific_icon },
    { label: 'Journalisme', icon: journalisme_specific_icon },
    { label: 'Communication', icon: communication_specific_icon },
    { label: 'Social', icon: social_specific_icon },
    { label: 'Sécurité - Armée - Défense', icon: security_army_icon },
    { label: "Entrepreneuriat - Création d'Entreprise", icon: entrepreneurship_icon },
  ],
  hlp: [
    { label: 'Édition - Métiers du Livre', icon: book_industry_icon },
    { label: 'Psychologie - Sociologie', icon: psychology_sociology_icon },
    { label: 'Communication', icon: communication_specific_icon },
    { label: 'Journalisme', icon: journalisme_specific_icon },
    { label: 'Enseignement', icon: teaching_civilisation_icon },
    { label: 'Social', icon: social_specific_icon },
    { label: "Marché de l'Art - Culture - Patrimoine", icon: culture_patimoine_icon },
  ],
  llcer: [
    { label: 'Langues Étrangères - Traduction', icon: langues_translation_icon },
    { label: 'Tourisme - Loisirs', icon: tourisme_specific_icon },
    { label: 'Commerce - Distribution - Management', icon: commerce_management_icon },
    { label: 'Communication', icon: communication_specific_icon },
    { label: 'Enseignement', icon: teaching_civilisation_icon },
    { label: 'Hôtellerie - Restauration', icon: hospitality_icon },
    { label: 'Transport - Logistique', icon: transport_icon },
  ],
  lca: [
    { label: 'Enseignement', icon: teaching_civilisation_icon },
    { label: "Marché de l'Art - Culture - Patrimoine", icon: culture_patimoine_icon },
    { label: 'Édition - Métiers du Livre', icon: book_industry_icon },
    { label: 'Communication', icon: communication_specific_icon },
    { label: 'Droit - Justice - Sciences Politiques', icon: droit_justice_icon },
  ],
  math: [
    { label: 'Mathématiques - Sciences Physiques', icon: math_physics_icon },
    { label: 'Finance', icon: finance_specific_icon },
    { label: 'Banque - Assurance', icon: banque_assurance_icon },
    { label: 'Comptabilité - Gestion - Audit', icon: accounting_audit_icon },
    { label: 'Informatique - Numérique', icon: informatics_icon },
    { label: 'Cybersécurité', icon: cybersecurity_specific_icon },
    { label: 'Enseignement', icon: teaching_civilisation_icon },
    { label: 'Le Nucléaire', icon: nuclear_specific_icon },
  ],
  nsi: [
    { label: 'Informatique - Numérique', icon: informatics_icon },
    { label: 'Internet - Web - Multimédia', icon: internet_web_icon },
    { label: 'Cybersécurité', icon: cybersecurity_specific_icon },
    { label: 'Jeu Vidéo', icon: gaming_specific_icon },
    { label: 'ESport - Gaming', icon: esport_specific_icon },
    { label: 'Communication', icon: communication_specific_icon },
    { label: 'Métiers et Formations de la Blockchain & du Métavers', icon: blockchain_metaverse_icon },
    { label: 'Communication Visuelle - Dessin - Animation 3D', icon: dessin_icon },
  ],
  physchim: [
    { label: 'Chimie - Biologie', icon: chemistry_biology_icon },
    { label: 'Énergie', icon: energy_specific_icon },
    { label: 'Industrie - Qualité', icon: industry_quality_icon },
    { label: 'Le Nucléaire', icon: nuclear_specific_icon },
    { label: 'Mathématiques - Sciences Physiques', icon: math_physics_icon },
    { label: 'Enseignement', icon: teaching_civilisation_icon },
    { label: 'Environnement - Développement Durable', icon: environment_icon },
    { label: 'Sport - Management Sportif', icon: sport_management_icon },
  ],
  svt: [
    { label: 'Santé (Médical-Paramédical)', icon: health_medical_icon },
    { label: 'Chimie - Biologie', icon: chemistry_biology_icon },
    { label: 'Environnement - Développement Durable', icon: environment_icon },
    { label: 'Métiers Animaliers', icon: animals_specific_icon },
    { label: 'Agriculture', icon: agriculture_specific_icon },
    { label: 'Agroalimentaire', icon: chemistry_biology_icon },
    { label: 'Métiers de la Mer', icon: marine_specific_icon },
    { label: 'Métiers du Vin', icon: wine_industry_icon },
    { label: 'Sport - Management Sportif', icon: sport_management_icon },
  ],
  si: [
    { label: 'Architecture - Urbanisme - Paysagisme', icon: architecture_icon },
    { label: 'Automobile - Moto', icon: automobile_icon },
    { label: 'Bâtiment et Travaux Publics (BTP)', icon: btp_icon },
    { label: 'Électronique', icon: electronics_icon },
    { label: 'Aéronautique et Aérospatial', icon: aeronautics_icon },
    { label: 'Énergie', icon: energy_specific_icon },
    { label: 'Industrie - Qualité', icon: industry_quality_icon },
    { label: 'Transport - Logistique', icon: transport_icon },
  ],
  ses: [
    { label: 'Commerce - Distribution - Management', icon: commerce_management_icon },
    { label: 'Finance', icon: finance_specific_icon },
    { label: 'Banque - Assurance', icon: banque_assurance_icon },
    { label: 'Comptabilité - Gestion - Audit', icon: accounting_audit_icon },
    { label: 'Droit - Justice - Sciences Politiques', icon: droit_justice_icon },
    { label: 'Ressources Humaines', icon: resources_human_icon },
    { label: 'Publicité - Marketing', icon: advertising_icon },
    { label: 'Immobilier', icon: real_estate_icon },
    { label: 'Sport - Management Sportif', icon: sport_management_icon },
  ],
  bioeco: [
    { label: 'Agriculture', icon: agriculture_specific_icon },
    { label: 'Agroalimentaire', icon: chemistry_biology_icon },
    { label: 'Environnement - Développement Durable', icon: environment_icon },
    { label: 'Métiers Animaliers', icon: animals_specific_icon },
    { label: 'Métiers de Bouche', icon: culinary_specific_icon },
    { label: 'Métiers du Vin', icon: wine_industry_icon },
    { label: 'Métiers de la Mer', icon: marine_specific_icon },
    { label: 'Chimie - Biologie', icon: chemistry_biology_icon },
  ],
};


const SubDomainesFormation = () => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState([]); // Will hold the *labels* selected
  const [availableOptions, setAvailableOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Choisis les sous-domaines qui t'intéressent";
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    // Retrieve which general specialties were chosen
    try {
      const storedSpecialities = JSON.parse(localStorage.getItem('specialites') || '{}');
      const selectedSpecialities = storedSpecialities.selected || [];

      // Collect all subdomain objects (label + icon) across chosen specialties
      const uniqueSubdomains = new Set();
      selectedSpecialities.forEach((speciality) => {
        const subdomains = specialitySubdomainMap[speciality] || [];
        subdomains.forEach((subdomainObj) => {
          // We JSON-stringify to ensure uniqueness in the Set
          uniqueSubdomains.add(JSON.stringify(subdomainObj));
        });
      });

      // Convert the set back to array of objects
      const options = Array.from(uniqueSubdomains).map((item) => JSON.parse(item));
      setAvailableOptions(options);
    } catch (error) {
      console.error('Error loading specialities from localStorage:', error);
    }

    return () => clearInterval(typingInterval);
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  // Because each option is now an object with { label, icon },
  // we store only the `label` in `selectedTypes`.
  // ─────────────────────────────────────────────────────────────────────────────
  const handleSelection = (label) => {
    setSelectedTypes((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const handleSubmit = () => {
    if (selectedTypes.length === 0 || isTyping) return;
    setIsLoading(true);
    try {
      // Store only the array of labels (subdomains) in localStorage
      localStorage.setItem('sub_domaine_formation', JSON.stringify(selectedTypes));
      navigate('/origine-city');
    } catch (error) {
      console.error('Error saving sub-domains to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/bac-general');
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      
      <ContentWrapper>
        <ProgressBar startPercent={65} endPercent={75} />
        <LogoImage src={compassImage} alt="Compass Logo" />
        
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper
              style={{
                opacity: isTyping ? 0 : 1,
                transition: 'opacity 0.3s ease',
                pointerEvents: isTyping ? 'none' : 'auto',
              }}
            >
              {availableOptions.map((option) => (
                <OptionButton
                  key={option.label}
                  $isSelected={selectedTypes.includes(option.label)}
                  onClick={() => handleSelection(option.label)}
                  type="button"
                  aria-pressed={selectedTypes.includes(option.label)}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedTypes.includes(option.label)}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || selectedTypes.length === 0}
          type="button"
          style={{
            opacity: (isTyping || selectedTypes.length === 0) ? 0.5 : 1,
            cursor: (isTyping || selectedTypes.length === 0) ? 'not-allowed' : 'pointer',
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default SubDomainesFormation;
