// components/BottomMenu/index.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomMenuContainer, MenuButton, MenuText } from './styles';
import { PiFiles, PiFilesFill } from "react-icons/pi";
import { IoTrophySharp } from "react-icons/io5";
import { GoTrophy } from "react-icons/go";
import { MdOutlineAccountCircle, MdAccountCircle } from "react-icons/md";
import { useTheme } from 'styled-components';
import useDeviceType from '../useDeviceType';
import { RiChat3Line, RiChat3Fill  } from "react-icons/ri";

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const getIconColor = (pathCheck) => {
    if (Array.isArray(pathCheck)) {
      return pathCheck.includes(location.pathname)
        ? theme.colors.primary
        : theme.colors.mediumGray;
    }
    return location.pathname === pathCheck
      ? theme.colors.primary
      : theme.colors.mediumGray;
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;
  const isRankingActive =
    location.pathname === '/classement' || location.pathname === '/quiz-type-formation';

  if (!isMobile) return null;

  return (
    <BottomMenuContainer>
      {/* VOEUX BUTTON */}
      <MenuButton
        id="voeux-button"
        onClick={() => handleNavigation('/favoris')}
        active={isActive('/favoris')}
      >
        <div>
          {isActive('/favoris') ? (
            <PiFilesFill size={30} color={getIconColor('/favoris')} />
          ) : (
            <PiFiles size={30} color={getIconColor('/favoris')} />
          )}
          <MenuText active={isActive('/favoris')}>Voeux</MenuText>
        </div>
      </MenuButton>

      {/* CLASSEMENT BUTTON */}
      <MenuButton
        id="ranking-button"
        onClick={() => handleNavigation('/classement')}
        active={isRankingActive}
      >
        <div>
          {isRankingActive ? (
            <IoTrophySharp size={30} color={getIconColor(['/classement', '/quiz-type-formation'])} />
          ) : (
            <GoTrophy size={30} color={getIconColor(['/classement', '/quiz-type-formation'])} />
          )}
          <MenuText active={isRankingActive}>Classement</MenuText>
        </div>
      </MenuButton>

      {/* CHAT BUTTON */}
      <MenuButton
        id="chat-button"
        onClick={() => handleNavigation('/chat')}
        active={isActive('/chat')}
      >
        <div>
          {isActive('/chat') ? (
            <RiChat3Fill size={30} color={getIconColor('/chat')} />
          ) : (
            <RiChat3Line size={30} color={getIconColor('/chat')} />
          )}
          <MenuText active={isActive('/chat')}>Chat</MenuText>
        </div>
      </MenuButton>

      {/* PROFILE BUTTON */}
      <MenuButton
        id="profile-button"
        onClick={() => handleNavigation('/profile')}
        active={isActive('/profile')}
      >
        <div>
          {isActive('/profile') ? (
            <MdAccountCircle size={30} color={getIconColor('/profile')} />
          ) : (
            <MdOutlineAccountCircle size={30} color={getIconColor('/profile')} />
          )}
          <MenuText active={isActive('/profile')}>Profil</MenuText>
        </div>
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;