
// styles.js
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
`;

export const ChatBubbleContainer = styled.div`
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  font-family: ${theme.fonts.bubble};
  color: ${theme.colors.black};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 280px; // Fixed width
  height: 150px; // Fixed height
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  // For the text animation only
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px; // Adjusted to account for border width
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray}; // Changed to grey
  }

  // Additional pseudo-element for the transparent tail
  &:before {
    content: '';
    position: absolute;
    bottom: -9px; // Slightly higher than the grey border
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 120px; // Space for the logo and bubble
`;


export const LogoImage = styled.img`
  width: 40%;
  height: 40%;
  object-fit: contain;
  margin-left: 30%;
  margin-bottom: 30%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;



export const Card = styled.div`
  background: ${theme.colors.lightText};
  border-radius: 20px;
  padding: ${theme.spacing.xlarge};
  box-shadow: 0 8px 32px rgba(255, 39, 104, 0.08);
  width: 80%;
  text-align: center;
  margin: 0 auto;
`;



export const Button = styled.button`
  background: ${props => props.primary ? theme.colors.primary : theme.colors.lightText};
  color: theme.colors.primary};
  border: 2px solid ${theme.colors.primary};
  border-radius: 10px;
  padding: ${theme.spacing.medium} ${theme.spacing.xlarge};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  cursor: pointer;
  width: 50%;
  justify-content;

  &:hover {
    background: ${props => props.primary ? theme.colors.secondary : theme.colors.lightText};
  }
`;

export const LinkText = styled.a`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`;

export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || '50%'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;


export const PrimaryButton = styled(BaseButton)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 11); /* 11vh from bottom */
  left: 50%;
  width: min(90%, 400px);
  border-radius: 20px;
  transform: translateX(-50%);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5); /* Smaller spread but darker shadow */
  transition: all 0.15s ease-in-out;
  
  &:hover {
    transform: translateX(-50%) translateY(3px); /* Matches shadow height */
    box-shadow: none;
    background: ${props => props.background || theme.colors.primary};
  }
  
  &:active {
    transform: translateX(-50%) translateY(3px);
    box-shadow: none;
  }
`;

export const BottomActionsContainer = styled.div`
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 6); /* 6vh from bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: baseline;
  justify-content: center; /* This keeps horizontal centering */
  gap: ${theme.spacing.xsmall};
  width: 90%;
  z-index: 1000;
`;

export const StyledText = styled.span`
  color: ${props => theme.colors.gris};
  font-size: ${props => theme.fontSizes.medium};
  font-weight: ${props => theme.fontWeights.semiBold};
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || theme.colors.noir};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.15s ease-in-out;
  
  &:hover {
    transform: scale(0.95); /* Subtle dezoom effect */
    color: ${props => props.color || theme.colors.noir}; /* Keeps original color */
  }
  
  &:active {
    transform: scale(0.95); /* Maintains same dezoom as hover */
  }
`;

export const TextContainer = styled.div`
  position: fixed;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: ${props => theme.spacing.medium};
`;

export const Title = styled.h1`
  color: ${props => props.color || theme.colors.noir};
  font-family: ${props => theme.fonts.main};
  font-weight: ${props => theme.fontWeights.semiBold};
  margin: 0;
  padding: 0;
  line-height: 1.2;
`;

export const Subtitle = styled.h2`
  color: ${props => theme.colors.gris};
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => theme.fontWeights.medium};
  margin: 0;
  padding: 0;
  line-height: 1.4;

  ${props => props.isMobile ? `
    font-size: 14px;
  ` : `
    font-size: 16px;
  `}
`;
