import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Existing imports...
import durationIcon from '../../assets/duree.png';
import priceIcon from '../../assets/prix.png';

// 1) Import your domain icon map and any icons it references

// Local imports
import {
  CardContainer,
  TitlePercentageSection,
  TitleText,
  InfoIconImg,
  InfoItem,
  InfoRow,
  DomainList,
  DomainItem,
  ContentSection,
  DomainIcon,
  EnSavoirPlusButton,
  ScoreContainer,
  ScoreTopText,
  ScoreValue,
} from './styles';

// Domain icons
import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import agroalimentaire_icon from '../../assets/agroalimentaire_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/nsi_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';


// Map each domain label to its icon
const domainIconMap = {
  'Arts du Spectacle': arts_spectacle_icon,
  'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
  'Communication Visuelle - Dessin - Animation 3D': dessin_icon,
  'Design': design_specific_icon,
  "Marché de l'Art - Culture - Patrimoine": culture_patimoine_icon,
  'Mode - Textile': mode_textile_icon,
  'Luxe': luxe_specific_icon,
  'Artisanat': artisanat_specific_icon,

  'Sport - Management Sportif': sport_management_icon,
  'Enseignement': teaching_specific_icon,
  'Santé (Médical-Paramédical)': health_medical_icon,
  'Travailler avec des Enfants': working_with_children_icon,
  'ESport - Gaming': esport_specific_icon,
  'Tourisme - Loisirs': tourisme_specific_icon,

  'Droit - Justice - Sciences Politiques': droit_justice_icon,
  'Fonction Publique': fonction_publique_specific_icon,
  'Humanitaire': humanitaire_specific_icon,
  'Journalisme': journalisme_specific_icon,
  'Communication': communication_specific_icon,
  'Social': social_specific_icon,
  'Sécurité - Armée - Défense': security_army_icon,
  "Entrepreneuriat - Création d'Entreprise": entrepreneurship_icon,

  "Édition - Métiers du Livre": book_industry_icon,
  'Psychologie - Sociologie': psychology_sociology_icon,
  'Langues Étrangères - Traduction': langues_translation_icon,
  'Commerce - Distribution - Management': commerce_management_icon,
  'Hôtellerie - Restauration': hospitality_icon,
  'Transport - Logistique': transport_icon,

  'Mathématiques - Sciences Physiques': math_physics_icon,
  'Finance': finance_specific_icon,
  'Banque - Assurance': banque_assurance_icon,
  'Comptabilité - Gestion - Audit': accounting_audit_icon,
  'Informatique - Numérique': informatics_icon,
  'Cybersécurité': cybersecurity_specific_icon,
  'Le Nucléaire': nuclear_specific_icon,
  'Internet - Web - Multimédia': internet_web_icon,
  'Jeu Vidéo': gaming_specific_icon,
  'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,

  'Chimie - Biologie': chemistry_biology_icon,
  'Énergie': energy_specific_icon,
  'Industrie - Qualité': industry_quality_icon,
  'Environnement - Développement Durable': environment_icon,
  'Métiers Animaliers': animals_specific_icon,
  'Agriculture': agriculture_specific_icon,
  'Agroalimentaire': agroalimentaire_icon,
  'Métiers de la Mer': marine_specific_icon,
  'Métiers du Vin': wine_industry_icon,

  'Architecture - Urbanisme - Paysagisme': architecture_icon,
  'Automobile - Moto': automobile_icon,
  'Bâtiment et Travaux Publics (BTP)': btp_icon,
  'Électronique': electronics_icon,
  'Aéronautique et Aérospatial': aeronautics_icon,

  'Ressources Humaines': resources_human_icon,
  'Publicité - Marketing': advertising_icon,
  'Immobilier': real_estate_icon,
  'Métiers de Bouche': culinary_specific_icon,
};


function getDomainIcon(domain) {
  // 2) Return the icon if found, or a fallback if not
  return domainIconMap[domain] ?? priceIcon; // Replace `priceIcon` with a default or fallback icon
}

const LittleCardTypeFormation = ({ ranking }) => {
  const navigate = useNavigate();

  const formationType = ranking?.unique_type || 'Type inconnu';
  const score = ranking?.total_score || 0;
  const duree = ranking?.durée_interval || 'X années';
  const prixFormatted = ranking?.prix_normal_interval;
  const status = ranking?.status || 'Public';
  const urlSafeId = formationType.replace(/[ ']/g, '_');

  // Collect up to two domains if available
  const domainsToShow = [];
  if (ranking?.matched_domains?.main?.[0]) {
    domainsToShow.push(ranking.matched_domains.main[0]);
  }
  if (ranking?.matched_domains?.secondary?.[0]) {
    domainsToShow.push(ranking.matched_domains.secondary[0]);
  }

  return (
    <CardContainer>
      <TitlePercentageSection>
        <TitleText>{formationType}</TitleText>
        <ScoreContainer>
          <ScoreTopText>Match à</ScoreTopText>
          <ScoreValue>{score}%</ScoreValue>
        </ScoreContainer>
      </TitlePercentageSection>

      <ContentSection>
        <InfoRow>
          <InfoItem>
            <InfoIconImg src={durationIcon} alt="Durée" />
            <span>{duree} ans</span>
          </InfoItem>
          <InfoItem>
            <InfoIconImg src={priceIcon} alt="Prix" />
            <span>{prixFormatted} €/an</span>
          </InfoItem>
        </InfoRow>

        {/* 3) Use getDomainIcon(domain) for each domain */}
        <DomainList>
          {domainsToShow.map((domain, index) => (
            <DomainItem key={index}>
              <DomainIcon src={getDomainIcon(domain)} alt={`Icon for ${domain}`} />
              <span>{domain}</span>
            </DomainItem>
          ))}
        </DomainList>

        <EnSavoirPlusButton
        onClick={() => {
          if (!urlSafeId) {
            alert('Formation ID missing');
            return;
          }
          navigate(`/card-type-formation/${urlSafeId}`);
        }}
      >
        <span>En savoir plus</span>
      </EnSavoirPlusButton>
      </ContentSection>
    </CardContainer>
  );
};

LittleCardTypeFormation.propTypes = {
  ranking: PropTypes.shape({
    unique_type: PropTypes.string,
    total_score: PropTypes.number,
    durée_interval: PropTypes.string,
    prix_normal_interval: PropTypes.string,
    status: PropTypes.string,
    matched_domains: PropTypes.shape({
      main: PropTypes.arrayOf(PropTypes.string),
      secondary: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

export default LittleCardTypeFormation;