import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineReload } from 'react-icons/ai';
import apiCall from '../../utils/api';
import { useNavigate } from 'react-router-dom';

import questionIcon from '../../assets/doubt.png';  // "I don't know"
import heartIcon from '../../assets/jadore.png';    // "I love"
import MeoReponses from '../../assets/meo_mes_reponses.png';
import PopupGlobale from '../PopupGlobale';

// Styled components & theme
import {
  Container,
  ScrollableContent,
  Header,
  LogoImage,
  ReturnButtonWrapper,
  RestartButton,
  RestartButtonWrapper,
  QuestionCard,
  CardTop,
  CardBottom,
  CategoryText,
  CategoryIconWrapper,
  QuestionText,
  AnswerEmoji,
  ExpandedPanel,
  AnswerOptionsContainer,
  AnswerIconButton,
  RedCrossIcon,
  GreenHeartIcon,
  PopupOverlay,
  PopupContent,
  PopupMessage,
  PopupButtonContainer,
  PopupButton,
  PopupButtonYes
} from './styles';

import { ReturnButton } from '../sharedStyles/styles';
import { categoryIcons } from './styles';

const ANSWER_ORDER = [2, 1, 3, 4];
const answerIconMap = {
  1: { isImage: false, icon: RedCrossIcon }, 
  2: { isImage: true,  icon: questionIcon },
  3: { isImage: false, icon: GreenHeartIcon },
  4: { isImage: true,  icon: heartIcon },
};

const QuestionManager = () => {
  const [questionsData, setQuestionsData] = useState([]);
  const [expandedQuestionId, setExpandedQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // For showing/hiding the Restart confirmation popup
  const [showRestartPopup, setShowRestartPopup] = useState(false);

  const navigate = useNavigate();

  // Fetch questions on mount
  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/get-quiz1-questions-and-answers/`;
        const data = await apiCall(url, 'GET');
        setQuestionsData(data);
      } catch (error) {
        console.error('Error fetching quiz1 questions:', error);
        setErrorMessage('Une erreur est survenue lors du chargement des questions.');
      } finally {
        setLoading(false);
      }
    };
    fetchQuestions();
  }, []);

  // Toggle expanded panel
  const handleCardClick = (questionId) => {
    setExpandedQuestionId((prev) => (prev === questionId ? null : questionId));
  };

  // Update user answer
  const handleAnswerUpdate = async (question, newAnswer) => {
    try {
      setLoading(true);
      setErrorMessage('');

      const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/answer/`;
      const body = {
        question_id: question.question_id,
        question_type: question.question_type,
        answer: newAnswer,
      };

      await apiCall(url, 'POST', body);

      // Update local state with new answer
      setQuestionsData((prev) =>
        prev.map((q) => {
          if (q.question_id === question.question_id && q.question_type === question.question_type) {
            return { ...q, user_answer: newAnswer };
          }
          return q;
        })
      );
      // Close expanded
      setExpandedQuestionId(null);
    } catch (error) {
      console.error('Error updating answer:', error);
      setErrorMessage('Une erreur est survenue lors de la mise à jour de la réponse.');
    } finally {
      setLoading(false);
    }
  };

  // Go back
  const handleBack = () => {
    navigate('/profile');
  };

  // Show the stylized popup
  const handleRestartClick = () => {
    setShowRestartPopup(true);
  };

  // Cancel restart => hide popup
  const handleCancelRestart = () => {
    setShowRestartPopup(false);
  };

  // Confirm restart => call the reset quiz endpoint
  const handleConfirmRestart = async () => {
    try {
      setLoading(true);
      setErrorMessage('');

      const resetUrl = `${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`;
      await apiCall(resetUrl, 'POST'); 

      // Hide popup once done
      setShowRestartPopup(false);

      // Navigate to /quiz-type-formation and pass the "from" state
      navigate('/quiz-type-formation', { state: { from: '/profile/modify/swipe' } });

    } catch (error) {
      console.error('Error resetting quiz:', error);
      setErrorMessage('Une erreur est survenue lors de la réinitialisation du quiz.');
    } finally {
      setLoading(false);
    }
  };

  const renderAnswerIcon = (answerValue) => {
    const iconData = answerIconMap[answerValue];
    if (!iconData) return null;
    if (iconData.isImage) {
      return <img src={iconData.icon} alt="answer-icon" />;
    } else {
      const IconComponent = iconData.icon;
      return <IconComponent />;
    }
  };

  return (
    <Container>
      {/* Return Button (top-left) */}
      <ReturnButtonWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>
      </ReturnButtonWrapper>

      {/* Restart Button (top-right) */}
      <RestartButtonWrapper>
        <RestartButton onClick={handleRestartClick}>
          <AiOutlineReload />
        </RestartButton>
      </RestartButtonWrapper>

      <Header>
        <LogoImage src={MeoReponses} alt="Logo" />
      </Header>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}

      <ScrollableContent>
        {!loading && questionsData && questionsData.length > 0 && (
          questionsData.map((question) => {
            const isExpanded = expandedQuestionId === question.question_id;
            const CategoryIcon = categoryIcons[question.category];
            const currentAnswerIcon = renderAnswerIcon(question.user_answer);

            return (
              <QuestionCard
                key={`${question.question_type}-${question.question_id}`}
                onClick={() => handleCardClick(question.question_id)}
              >
                <CardTop category={question.category}>
                  {CategoryIcon && (
                    <CategoryIconWrapper>
                      <CategoryIcon />
                    </CategoryIconWrapper>
                  )}
                  <CategoryText>{question.category}</CategoryText>
                </CardTop>

                <CardBottom category={question.category}>
                  <QuestionText>{question.text}</QuestionText>
                  <AnswerEmoji>{currentAnswerIcon}</AnswerEmoji>
                </CardBottom>

                {isExpanded && (
                  <ExpandedPanel>
                    <AnswerOptionsContainer>
                      {ANSWER_ORDER.map((val) => {
                        const iconData = answerIconMap[val];
                        if (!iconData) return null;

                        return (
                          <AnswerIconButton
                            key={val}
                            onClick={(e) => {
                              e.stopPropagation(); // prevent closing card
                              handleAnswerUpdate(question, val);
                            }}
                          >
                            {iconData.isImage ? (
                              <img src={iconData.icon} alt={`answer-${val}`} />
                            ) : (
                              <iconData.icon />
                            )}
                          </AnswerIconButton>
                        );
                      })}
                    </AnswerOptionsContainer>
                  </ExpandedPanel>
                )}
              </QuestionCard>
            );
          })
        )}
      </ScrollableContent>

      {/* Our custom stylized popup modal */}
      {showRestartPopup && (
        <PopupGlobale
        isVisible={showRestartPopup}
        message="Es-tu sûr(e) de vouloir réinitialiser tes swipes ?"
        onCancel={handleCancelRestart}
        onConfirm={handleConfirmRestart}
      />
      )}
    </Container>
  );
};

export default QuestionManager;
