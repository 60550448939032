import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Container,
  ContentWrapper,
  PodiumHeader,
  TopImage,
  ScrollableRankings,
  RankingList,
  RankingItem,
  TrophyIcon,
  RankNumber,
  FormationName,
  ScoreContainer,
  ScoreTopText,
  ScoreValue,
  PlaceholderLine,
  PlaceholderPercent,
  ShareButtonContainer,
  ModalOverlay,
  SlidingModal,
} from './styles';

import LittleCardTypeFormation from '../LittleCardTypeFormation';
import apiCall from '../../utils/api';
import ShareTopSchools from './ShareTopSchools';

// Example trophy image for top 3 ranks (adapt the path as needed)
import TrophyGold from '../../assets/top1_icon.png';
import TrophySilver from '../../assets/top2_icon.png';
import TrophyBronze from '../../assets/top3_icon.png';

// Example background image for the top area
import MeoBackground from '../../assets/mon_classement_icon.png';

const Classement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [rankings, setRankings] = useState({});
  const [selectedRanking, setSelectedRanking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  
  const getGradientForScore = (score) => {
    const numericScore = Number(score);
    const clampedScore = Math.max(0, Math.min(100, numericScore));
    const baseColor = getColorBand(clampedScore);
    const deeperColor = darkenColor(baseColor, 0.2);
    
    // Reverse gradient to go from darker (left) to lighter (right)
    return `linear-gradient(90deg, ${deeperColor} 0%, ${baseColor} 100%)`;
  };

  // Helper function to interpolate between two colors
  const interpolateColor = (color1, color2, weight) => {
    const r = Math.round(color1.r + (color2.r - color1.r) * weight);
    const g = Math.round(color1.g + (color2.g - color1.g) * weight);
    const b = Math.round(color1.b + (color2.b - color1.b) * weight);
    return { r, g, b };
  };



  // Darken a hex color by the given amount (0.1 = 10% darker).
  const darkenColor = (hexColor, amount) => {
    // Remove leading "#" if present
    let color = hexColor.replace('#', '');

    // Parse the 3 or 6 digits
    if (color.length === 3) {
      // Expand shorthand #abc → #aabbcc
      color = color
        .split('')
        .map((c) => c + c)
        .join('');
    }

    // Convert to decimal r, g, b
    let r = parseInt(color.slice(0, 2), 16);
    let g = parseInt(color.slice(2, 4), 16);
    let b = parseInt(color.slice(4, 6), 16);

    // Darken each channel
    r = Math.floor(r * (1 - amount));
    g = Math.floor(g * (1 - amount));
    b = Math.floor(b * (1 - amount));

    // Convert back to hex and return
    const toHex = (val) => {
      const h = val.toString(16);
      return h.length === 1 ? '0' + h : h;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const getColorBand = (score) => {
    if (score <= 20) return '#FF503E';      // Red
    if (score <= 40) return '#F4932C';      // Orange
    if (score <= 60) return '#FFFC3F';      // Yellow
    if (score <= 80) return '#4CE856';      // Light Green
    return '#20D428';                      // Green (81–100)
  };



  useEffect(() => {
    const checkUserAndFetch = async () => {
      // First check context user
      let currentUser = user;
      
      // If null, check localStorage
      if (!currentUser) {
        try {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            currentUser = JSON.parse(storedUser);
          }
        } catch (e) {
          console.error('Error parsing user from localStorage:', e);
        }
      }

      // Final check if we have a user
      if (!currentUser) {
        console.log('User not found');
        navigate('/quiz-type-formation');
        return;
      }

      // Now check type_formation
      if (!currentUser.type_formation || currentUser.type_formation.length === 0) {
        console.log('User has no type_formation');
        navigate('/quiz-type-formation');
        return;
      }

      // Only fetch rankings if all checks pass
      try {
        setIsLoading(true);
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/quiz/results/`,
          'GET'
        );

        if (!response.rankings || Object.keys(response.rankings).length === 0) {
          console.log('No rankings available');
          navigate('/quiz-type-formation');
        } else {
          setRankings(response.rankings);
        }
      } catch (error) {
        console.error('Error fetching rankings:', error);
        navigate('/quiz-type-formation');
      } finally {
        setIsLoading(false);
      }
    };

    checkUserAndFetch();
  }, [user, navigate]);

  const openModal = (ranking) => {
    setSelectedRanking(ranking);
  };

  const closeModal = () => {
    setSelectedRanking(null);
  };

  // True if there is at least one key in `rankings`
  const hasRankings = Object.keys(rankings).length > 0;

  const renderTrophyIcon = (rank) => {
    switch (rank) {
      case 1:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyGold})` }} />;
      case 2:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophySilver})` }} />;
      case 3:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyBronze})` }} />;
      default:
        return null;
    }
  };

  const top30Rankings = Object.entries(rankings).slice(0, 30);

  return (
    <Container>
      <ContentWrapper>
        <PodiumHeader>

          {/* Top Image (Meo) as a styled div with a background */}
          <TopImage style={{ backgroundImage: `url(${MeoBackground})` }} />
        </PodiumHeader>

        <ScrollableRankings>
        <RankingList>
            {isLoading ? (
              // New placeholder structure
              Array.from({ length: 5 }).map((_, index) => (
                <RankingItem key={index}>
                  {/* Rank placeholder */}
                  <PlaceholderLine width="30px" style={{ 
                    height: '21px', 
                    marginRight: '10px',
                    borderRadius: '4px'
                  }} />
                  
                  {/* Formation name placeholders */}
                  <div style={{ flex: 1 }}>
                    <PlaceholderLine width="70%" style={{ marginBottom: '8px' }} />
                    <PlaceholderLine width="50%" />
                  </div>
                  
                  {/* Score placeholder */}
                  <div style={{ 
                    width: '86px',
                    height: '74px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto'
                  }}>
                    <PlaceholderPercent style={{ 
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%'
                    }} />
                  </div>
                </RankingItem>
              ))
            ) : (
              top30Rankings.map(([key, item]) => {
                const rank = parseInt(key.replace('top', ''), 10);
                return (
                  <RankingItem
                    key={key}
                    onClick={() => openModal(item)}
                    rank={rank}
                  >
                    {rank <= 3 ? (
                      renderTrophyIcon(rank)
                    ) : (
                      <RankNumber>{rank}</RankNumber>
                    )}
                    <FormationName>{item.unique_type}</FormationName>
                    <ScoreContainer style={{ background: getGradientForScore(item.total_score) }}>
                    <ScoreValue>{item.total_score}%</ScoreValue>
                  </ScoreContainer>
                  </RankingItem>
                );
              })
            )}
          </RankingList>
        </ScrollableRankings>
      </ContentWrapper>

      {selectedRanking && (
        <ModalOverlay onClick={closeModal}>
          <SlidingModal onClick={(e) => e.stopPropagation()}>
            <LittleCardTypeFormation
              ranking={selectedRanking}
              onClose={closeModal}
            />
          </SlidingModal>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default Classement;
