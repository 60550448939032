import styled from 'styled-components';
import theme from '../../theme';

export const GradeContainer = styled.div`
  position: relative;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${theme.spacing.large};
  height: calc(var(--vh, 1vh) * 60);
  margin-bottom: calc(var(--vh, 1vh) * 15);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${theme.spacing.large} 0;

  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.medium};
  background: #FFFFFF;
  border-radius: 24px; // More rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.small};
`;

export const SliderLabel = styled.label`
  color: ${theme.colors.noir};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: ${theme.spacing.small};
`;

export const CustomSlider = styled.input`
  width: 100%;
  height: 4px; // Thinner track
  background: ${theme.colors.lightGray};
  border-radius: 8px; // More rounded track
  outline: none;
  -webkit-appearance: none;
  position: relative;
  margin: 12px 0; // Add margin to compensate for smaller height
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; // Slightly smaller thumb
    height: 20px; // Slightly smaller thumb
    background: ${theme.colors.bleu_clair};
    border: 2px solid ${theme.colors.bleu_fonce};
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: -8px; // Centers the thumb on the thinner track

    &:hover {
      transform: scale(1.1);
      background: ${theme.colors.bleu_fonce};
    }
  }

  &::-moz-range-thumb {
    width: 20px; // Slightly smaller thumb
    height: 20px; // Slightly smaller thumb
    background: ${theme.colors.bleu_clair};
    border: 2px solid ${theme.colors.bleu_fonce};
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: -8px; // Centers the thumb on the thinner track

    &:hover {
      transform: scale(1.1);
      background: ${theme.colors.bleu_fonce};
    }
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      ${theme.colors.bleu_clair} var(--progress),
      ${theme.colors.lightGray} var(--progress)
    );
    border-radius: 8px; // More rounded track
    height: 4px; // Match the slider height
  }

  &::-moz-range-track {
    background: linear-gradient(
      to right,
      ${theme.colors.bleu_clair} var(--progress),
      ${theme.colors.lightGray} var(--progress)
    );
    border-radius: 8px; // More rounded track
    height: 4px; // Match the slider height
  }
`;

export const GradeValue = styled.div`
  color: ${theme.colors.bleu_fonce};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.large};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  margin-top: ${theme.spacing.small};
  transition: color 0.2s ease;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: ${props => props.isLoading ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  ${props => props.isLoading && 'opacity: 1;'}
`;

export const LogoImage = styled.img`
  width: 25%;
  height: 25%;
  object-fit: contain;
  margin-left: 10%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
  }
`;

export const ChatBubbleContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 8);
  left: ${props => props.isMobile ? '38%' : '45%'};
  transform: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: ${theme.fonts.bubble};
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: 80%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;